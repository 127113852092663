import React, { useEffect, useState } from 'react';

import { InputLabel } from '@mui/material';

import TreeViewBootstrapDropDown from '../dropdowns/TreeViewBootstrapDropDown';
import { makeStyles } from 'tss-react/mui';

import { colors } from '../../palette';
import { isEmpty } from 'lodash';
import InfoIcon from '../icons/InfoIcon';
import { useMaterialInfo } from '../../hooks/useMaterialInfo';
import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';

const useStyles = makeStyles()(() => ({
  inputLabel: {
    fontSize: 16,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  inputLabelRowDisplayFixWidth: {
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
}));

function MaterialCategoriesInputField(props) {
  const { classes } = useStyles();

  const {
    technology,
    visible = false,
    materialCategoryOptions = [],
    onSelect,
    value,
    rowDisplay = false,
    bootstrapStyle = true,
    required = false,
  } = props;

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [{ description, datasheetUrl }, { setParams }] = useMaterialInfo();

  useEffect(() => {
    if (
      isEmpty(technology) ||
      isEmpty(value) ||
      isEmpty(selectedCategory) ||
      technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
    ) {
      return;
    }
    const params = {
      technology,
      category: selectedCategory,
      material: value,
    };
    setParams(params);
  }, [technology, value, selectedCategory]);

  useEffect(() => {
    if (
      isEmpty(technology) ||
      isEmpty(value) ||
      isEmpty(materialCategoryOptions)
    ) {
      return;
    }
    for (const treeItem of materialCategoryOptions) {
      const obj = treeItem.options.find((o) => o === value);
      if (obj) {
        setSelectedCategory(treeItem.category);
        break;
      }
    }
  }, [technology, value, materialCategoryOptions]);

  if (!visible) {
    return null;
  }

  const renderDataSheetUrl = () => {
    return (
      <div
        style={{
          marginLeft: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        }}
      >
        <a
          style={{
            fontSize: 'small',
            fontStyle: 'italic',
            whiteSpace: 'nowrap',
            width: 'auto',
          }}
          href={datasheetUrl}
          target='_blank'
        >
          {value} Datasheet
        </a>
      </div>
    );
  };

  const renderTooltipIcon = () => {
    return (
      <div
        style={{
          marginLeft: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InfoIcon toolTipText={description} />
      </div>
    );
  };

  if (bootstrapStyle) {
    return (
      <div className={rowDisplay ? classes.rowDisplay : classes.container}>
        <div
          className={rowDisplay ? classes.inputLabelRowDisplayFixWidth : null}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 7,
          }}
        >
          <InputLabel
            className={
              rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel
            }
          >
            Material
          </InputLabel>
          {!rowDisplay && datasheetUrl && renderDataSheetUrl()}
          {!rowDisplay && description && renderTooltipIcon()}
        </div>
        <TreeViewBootstrapDropDown
          options={materialCategoryOptions}
          onSelect={(value) => onSelect(value)}
          value={value}
          small={rowDisplay}
          required={required}
        />
        {rowDisplay && datasheetUrl && renderDataSheetUrl()}
        {rowDisplay && datasheetUrl && renderTooltipIcon()}
      </div>
    );
  }

  return (
    <div
      style={{
        marginTop: '0.5rem',
      }}
    >
      <TreeViewBootstrapDropDown
        options={materialCategoryOptions}
        onSelect={(value) => onSelect(value)}
        value={value || ''}
        bootstrapStyle={false}
        required={required}
      />
    </div>
  );
}

export default MaterialCategoriesInputField;
