import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { colors } from '../palette';

import { logoutUser } from '../actions';

import { makeStyles } from 'tss-react/mui';
import { Badge, Button, Tooltip } from '@mui/material';

import {
  AssignmentOutlined as AssignmentOutlinedIcon,
  ContactSupportOutlined as ContactSupportOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon,
  FileUpload,
  ImageSearch as ImageSearchIcon,
  MonetizationOn as MonetizationOnIcon,
} from '@mui/icons-material';

// import CountrySelectorPopup from "../components/popups/CountrySelectorPopup";
import ProfileMenuItem from './ProfileMenuItem';

import { getUnAcknowledgedQuotationsCountAction } from '../actions/poAcknowledge';

import ResponsiveSidebar from './ResponsiveSidebar';

import { getESignFileForSupplier } from '../apis/onboardingApi';

const DEFAULT_DRAWER_WIDTH = 270;

// Style component
const useStyles = makeStyles()({
  top: {
    marginBottom: 38,
    '&:hover': {
      background: colors.bgLightGrey,
    },
  },
  middle: {
    margin: '0 40px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 40px',
    paddingBottom: 20,
  },
  icon: {
    display: 'flex',
    marginRight: 15,
    color: colors.fontLightGrey,
  },
  navlinks: {
    display: 'flex',
    flexDirection: 'column',
  },
  listText: {
    textTransform: 'capitalize',
    fontSize: '16px',
    letterSpacing: '0.05em',
    color: colors.fontLightGrey,
  },
  link: {
    textDecoration: 'none',
    margin: '9px 0',
    display: 'flex',
    alignItems: 'center',
  },
  logoutBtn: {
    padding: 0,
    margin: '9px 0',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  sidebarTitle: {
    color: colors.tertiaryBlue,
    textTransform: 'uppercase',
    fontWeight: '600',
    margin: '9px 0',
    letterSpacing: '0.08em',
  },
  highlight: {
    color: 'white',
  },
  highlightMenu: {
    backgroundColor: colors.sideBarMenuHighlight,
    borderRadius: '8px',
    paddingLeft: '0.2rem',
    boxSizing: 'border-box',
  },
});

export function SupplierSidebar(props) {
  const { classes } = useStyles();

  const dispatch = useDispatch();

  const { onLogOut, name, profilePic, user, poUnAcknowledgedCount } = props;

  useEffect(() => {
    if (isEmpty(user)) {
      return;
    }
    const { userID } = user;
    dispatch(getUnAcknowledgedQuotationsCountAction(userID));
  }, [user]);

  const { data: eSignPartnershipAgreementInfo = {} } = useQuery(
    ['getESignFileForSupplier', user.userID],
    () =>
      getESignFileForSupplier({
        supplierID: user.userID,
        docType: 'Partnership Agreement',
      })
  );

  // Add tabs for supplier
  const renderListMenuItems = () => (
    <div className={classes.navlinks}>
      <div className={classes.sidebarTitle}>General</div>
      <Link to='/' className={classes.link}>
        <div className={classes.icon}>
          <ImageSearchIcon />
        </div>
        <div className={classes.listText}>Browse Parts</div>
      </Link>
      <Link to='/orders' className={classes.link}>
        <div className={classes.icon}>
          <AssignmentOutlinedIcon />
        </div>
        <div className={classes.listText} data-cy='my-quotes-sidebar'>
          My Quotes
        </div>
      </Link>
      <Tooltip
        title={
          poUnAcknowledgedCount > 1
            ? `Please view and acknowledge Purchase Orders`
            : poUnAcknowledgedCount === 1
              ? `Please view and acknowledge Purchase Order`
              : ''
        }
      >
        <Badge badgeContent={poUnAcknowledgedCount} color='error'>
          <Link to='/accepted-orders' className={classes.link}>
            <div className={classes.icon}>
              <MonetizationOnIcon />
            </div>
            <div className={classes.listText} data-cy='accepted-quotes-sidebar'>
              Accepted Quotes
            </div>
          </Link>
        </Badge>
      </Tooltip>
      {eSignPartnershipAgreementInfo && (
        <Link to='/partnership-agreement' className={classes.link}>
          <div className={classes.icon}>
            <FileUpload />
          </div>
          <div
            className={classes.listText}
            data-cy='partnership-agreement-sidebar'
          >
            Partnership Agreement
          </div>
        </Link>
      )}
      <div className={classes.sidebarTitle} style={{ marginTop: 50 }}>
        Support
      </div>
      <Link to='/supplier-faq' className={classes.link}>
        <div className={classes.icon}>
          <ContactSupportOutlinedIcon />
        </div>
        <div className={classes.listText}>FAQ</div>
      </Link>
    </div>
  );

  return (
    <ResponsiveSidebar
      drawerWidth={DEFAULT_DRAWER_WIDTH}
      Content={props.children}
    >
      <div
        style={{
          maxWidth: DEFAULT_DRAWER_WIDTH,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div className={classes.top}>
          <ProfileMenuItem name={name} profilePic={profilePic} />
        </div>
        <div className={classes.middle} style={{ flexGrow: 1 }}>
          {renderListMenuItems()}
        </div>
        <div className={classes.bottom}>
          <Button
            onClick={onLogOut}
            className={classes.logoutBtn}
            style={{ backgroundColor: 'transparent' }}
          >
            <div className={classes.icon}>
              <ExitToAppOutlinedIcon />
            </div>
            <div className={classes.listText}>Logout</div>
          </Button>
        </div>
      </div>
    </ResponsiveSidebar>
  );
}

// Import states to use in component
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
    name: state.editProfile.name
      ? state.editProfile.name
      : state.auth.user.name,
    profilePic: state.editProfile.profilePic
      ? state.editProfile.profilePic
      : state.auth.user.profilePic,
    user: state.auth.user,
    poUnAcknowledgedCount: state.poAcknowledge.poUnAcknowledgedCount,
  };
}

// Import functions to use in component
function matchDispatchToProps(dispatch) {
  return {
    onLogOut: () => dispatch(logoutUser()),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(SupplierSidebar);
