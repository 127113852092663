import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const convert2DImage = async (parameters, options = {}) => {
  const { timeout = 30000 } = options;
  const requestOptions = {
    method: 'POST',
    headers: getAuthorizedHeader(),
    cache: 'no-cache',
    body: JSON.stringify({ parameters }),
  };
  const url = `${BACKEND_SERVICE_URL}/images/convert`;
  const controller = new window.AbortController();
  const timeoutID = setTimeout(() => {
    controller.abort();
    throw new Error('Timeout');
  }, timeout);
  const response = await fetch(url, {
    ...requestOptions,
    signal: controller.signal,
  });
  clearTimeout(timeoutID);
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
};

export const generateItemImage = async (itemId) => {
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  const url = `${BACKEND_SERVICE_URL}/items/${itemId}/generate-image`;
  return fetch(url, requestOptions).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};
