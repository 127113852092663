import React from 'react';

import { Button } from '@mui/material';

import actionButtonStyles from './ActionButtonStyles';
import ActionButtonBadge from './ActionButtonBadge';

// import { dateTzSingapore } from '../../../utils/dateTimeUtils';

// ------------------------------------------------------------------------------

const MCTActionButton = ({
  onClick,
  actionButtonStyle,
  actionButtonItems,
  actionButtonContent,
  showBadgeCounter = true,
}) => {
  const actionButtonsStyles = actionButtonStyles();

  return actionButtonItems?.length > 0 ? (
    <ActionButtonBadge
      showBadgeCounter={showBadgeCounter}
      badgeContent={actionButtonItems?.length}
    >
      <Button
        className={actionButtonsStyles[actionButtonStyle]}
        variant='contained'
        onClick={onClick}
      >
        {actionButtonContent}
      </Button>
    </ActionButtonBadge>
  ) : null;
};

function withActionButton(content, style) {
  return function DefaultMCTActionButton(props) {
    const { acceptedItems, onClick } = props;
    return (
      <MCTActionButton
        onClick={onClick}
        actionButtonStyle={style}
        actionButtonItems={acceptedItems}
        actionButtonContent={content}
      />
    );
  };
}

// export const MCTIssueDOButton = withActionButton('Issue DO', 'issueDoButton');
export const MCTItemCollectedButton = withActionButton(
  'Collect Items',
  'itemCollectedButton'
);
// export const MCTShipItemsButton = withActionButton('Ship Item', 'shipItemButton');
// export const MCTInvoiceButton = withActionButton('Invoice', 'invoiceButton');
//
// export function MCTCompleteButtonWithText(props) {
//   const { rowData, showBadgeCounter } = props;
//
//   const invoicedItems = rowData.acceptedItems.filter((part) => part.invoiced);
//   const hasCompleted = invoicedItems.length || rowData.isInvoiced;
//   const completedDate =
//     rowData.invoiceClickedDate && dateTzSingapore(rowData.invoiceClickedDate);
//   const completedBy = `${rowData.invoiceClickedByName} (${rowData.invoiceClickedBy})`;
//   const completedButton = (
//     <MCTActionButton
//       onClick={() => { }}
//       actionButtonStyle='completedButton'
//       actionButtonItems={invoicedItems}
//       actionButtonContent='Completed'
//       showBadgeCounter={showBadgeCounter}
//     />
//   );
//
//   if (!hasCompleted) {
//     return null;
//   }
//
//   return (
//     <div
//       style={{
//         wordBreak: 'break-word',
//         whiteSpace: 'normal',
//         lineHeight: 'normal',
//       }}
//     >
//       {completedButton}
//       {rowData.invoiceClickedBy && (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           <div
//             style={{
//               fontWeight: 'bold',
//               fontSize: '0.75rem',
//             }}
//           >
//             {completedDate}
//           </div>
//           <span
//             style={{
//               fontWeight: 'bold',
//               fontSize: '0.75rem',
//             }}
//           >
//             {completedBy}
//           </span>
//         </div>
//       )}
//     </div>
//   );
// }

export default MCTActionButton;
