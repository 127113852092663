export const PROJECT_TYPE = {
  PPE: 'PPE',
  RFQ: 'RFQ',
  MIX_PPE_RFQ: 'MIX_PPE_RFQ',
  MIX_TECHNOLOGIES: 'MIX_TECHNOLOGIES',
};

export const ITEM_QUOTE_TYPE = {
  PPE: 'PPE',
  RFQ: 'RFQ',
};

export const QC_NO_QC_REPORT = 'No QC Report';
export const QC_STANDARD_QC_REPORT = 'Standard QC Report';
export const QC_FORMAL_CMM_REPORT = 'Formal/CMM Report';
export const QC_MATERIAL_CERTIFICATE = 'Material Certificate';
export const QC_COATING_FINISHING_CERTIFICATE =
  'Coating (Finishing) Certificate';
export const QC_OTHERS = 'Others';

export const QC_REPORT_MAIN_OPTIONS = [
  {
    name: QC_NO_QC_REPORT,
    tooltip:
      'No inspection done after fabrication. However, parts will still be fabricated according to provided files.',
  },
  {
    name: QC_STANDARD_QC_REPORT,
    tooltip:
      'Inspected using measurement tools like Vernier Caliper, micrometer, Go/No-GO Gauge, 2D Vision Inspection System, Profile Projectors.',
  },
  {
    name: QC_FORMAL_CMM_REPORT,
    tooltip:
      'Measurement using sophisticated equipment like CMM or 3D Scanning systems.',
  },
];

export const QC_REPORT_ADD_ONS_OPTIONS = [
  {
    name: QC_MATERIAL_CERTIFICATE,
    tooltip: 'Certificate showing traceability to material and heat treat lot.',
  },
  {
    name: QC_COATING_FINISHING_CERTIFICATE,
    tooltip:
      'Certificate showing compliance to coating requirements in drawing.',
  },
];

export const QC_REPORTS_ALL_OPTIONS = [
  ...QC_REPORT_MAIN_OPTIONS,
  ...QC_REPORT_ADD_ONS_OPTIONS,
  {
    name: QC_OTHERS,
  },
];

export const STANDARD_QC_REPORT_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/10Lyz-uMJihhVCGrintBUp6YXJqreQaecxGRf-iqZa1s/edit#gid=0';

export const QC_REPORT_TEMPLATE_URL = {
  [QC_STANDARD_QC_REPORT]: STANDARD_QC_REPORT_TEMPLATE_URL,
};
