import React from 'react';

import LongReceiptIcon from '../../assets/icons/long_receipt.svg';

import { makeStyles } from 'tss-react/mui';

import {
  Delete as DeleteIcon,
  FileCopy as FileCopyIcon,
  GetApp,
} from '@mui/icons-material';

import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material';

import { downloadS3File, extractFileNameFromUrl } from '../../utils/fileUtils';

import { FACTOREM_BLUE, colors } from '../../palette';
import { notifySuccess } from '../../services/notificationService';

// Style components
const useStyles = makeStyles()(() => ({
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.lightGray,
    },
  },
}));

function DownloadableFileWithActions(props) {
  const classes = useStyles();

  const {
    fileUrl,
    fileName: fileNameParam,
    showDeleteButton = false,
    showCopyUrlButton = false,
    postDownloadFunction,
    onDelete,
    iconImage = null,
    downloadCount = 0,
  } = props;

  const fileName = fileNameParam || extractFileNameFromUrl(fileUrl);

  const handleDownloadFile = (e) => {
    e.stopPropagation();
    downloadS3File(fileUrl, { fileNameParam: fileName }).then(() => {
      if (typeof postDownloadFunction === 'function') {
        postDownloadFunction();
      }
    });
  };

  const renderDeleteCopyButton = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Tooltip title='Download'>
          <IconButton
            className={classes.iconButton}
            style={{ padding: 2, color: FACTOREM_BLUE }}
            onClick={handleDownloadFile}
          >
            <GetApp style={{ fontSize: '1.2rem' }} />
          </IconButton>
        </Tooltip>
        {showCopyUrlButton === true && (
          <Tooltip title='Copy URL'>
            <IconButton
              className={classes.iconButton}
              style={{ padding: 2 }}
              onClick={() => {
                navigator.clipboard.writeText(fileUrl);
                notifySuccess('URL copied');
              }}
            >
              <FileCopyIcon style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </Tooltip>
        )}
        {showDeleteButton === true && (
          <Tooltip title='Delete file'>
            <IconButton
              className={classes.iconButton}
              style={{ padding: 2 }}
              aria-label='Delete PO'
              onClick={onDelete}
            >
              <DeleteIcon style={{ color: 'red', fontSize: '1.2rem' }} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <ListItem
      style={{
        backgroundColor:
          downloadCount > 0 ? colors.bgLightGrey : colors.uploadPOBg,
        width: 260,
        margin: 5,
        border: `solid 1px ${colors.uploadPOBorder}`,
        borderRadius: '5px',
        height: '3.875rem',
        color: 'inherit',
      }}
    >
      <Tooltip title='Click to download file'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: 260,
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onClick={handleDownloadFile}
        >
          <ListItemAvatar style={{ display: 'flex', minWidth: '2.8rem' }}>
            <img
              src={iconImage || LongReceiptIcon}
              alt='icon'
              style={{ height: '1.875rem' }}
            />
          </ListItemAvatar>
          <ListItemText
            style={{
              width: 130,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            primary={fileName}
            className={classes.itemText}
          />
        </div>
      </Tooltip>
      {renderDeleteCopyButton()}
    </ListItem>
  );
}

export default DownloadableFileWithActions;
