import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';
import { getFileNameFromCadFile } from './itemUtils';
import { IMAGE_2D_GENERATE_SUPPORT_FILE_TYPES } from '../constants/twoDImageConstants';
import { generatePresignedUrl } from '../services/s3Service';
// import { CAD_RENDERER_TYPES } from '../constants/cadRendererConstants';

// export const downloadAllFiles = async (urlList, folderName) => {
//   if (isEmpty(urlList)) {
//     return;
//   }
//   const zip = new JSZip();
//   const folder = zip.folder(folderName);
//   await Promise.all(
//     urlList.map(link =>
//       fetch(link)
//         .then(response => response.blob())
//         .then(blob => folder.file(link.split("/").reverse()[0], blob))
//     )
//   );
//   return zip.generateAsync({ type: "blob" })
//     .then(blob => saveAs(blob, `${folderName}.zip`));
// }

export const downloadAllS3Files = async (urlObjList, folderName) => {
  if (isEmpty(urlObjList)) {
    return;
  }
  urlObjList = urlObjList.filter((urlObj) => urlObj.url !== 'null');
  const zip = new JSZip();
  const folder = zip.folder(folderName);
  await Promise.all(
    urlObjList.map(async ({ url, fileName }) => {
      const signedUrl = await generatePresignedUrl(url);
      const response = await fetch(signedUrl);
      const blob = await response.blob();
      const downloadFileName = fileName || url.split('/').reverse()[0];
      folder.file(downloadFileName, blob);
    })
  );
  return zip
    .generateAsync({ type: 'blob' })
    .then((blob) => saveAs(blob, `${folderName}.zip`));
};

/**
 *
 * @param urlObjectList array of objects with url and subFolderName (usually itemID) and download file name
 * @param  folderName
 * @returns
 */
// export const downloadAllFilesSeparatedBySubFolders = async (urlObjectList, folderName) => {
//   if (isEmpty(urlObjectList)) {
//     return;
//   }
//   const zip = new JSZip();
//   const folder = zip.folder(folderName);
//   await Promise.all(
//     urlObjectList.map(({ subFolder, url: link, fileName: downloadFilename }) =>
//       fetch(link)
//         .then(response => response.blob())
//         .then(blob => {
//           const fileName = downloadFilename || getFileNameFromCadFile(link);
//           folder.file(`${subFolder}/${fileName}`, blob);
//         })
//     )
//   );
//   return zip.generateAsync({ type: "blob" })
//     .then(blob => saveAs(blob, `${folderName}.zip`));
// }

/**
 *
 * @param urlObjectList array of objects with url and subFolderName (usually itemID) and download file name
 * @param  folderName
 * @returns
 */
export const downloadAllS3FilesSeparatedBySubFolders = async (
  urlObjectList,
  folderName
) => {
  if (isEmpty(urlObjectList)) {
    return;
  }
  const zip = new JSZip();
  const folder = zip.folder(folderName);
  await Promise.all(
    urlObjectList
      .filter((urlObj) => urlObj.url !== 'null')
      .map(async ({ subFolder, url: link, fileName: downloadFilename }) => {
        const signedUrl = await generatePresignedUrl(link);
        return fetch(signedUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const fileName_1 = downloadFilename || getFileNameFromCadFile(link);
            folder.file(`${subFolder}/${fileName_1}`, blob);
          });
      })
  );
  return zip
    .generateAsync({ type: 'blob' })
    .then((blob) => saveAs(blob, `${folderName}.zip`));
};

export const is2DGenerationSupport = (fileExtension) => {
  return IMAGE_2D_GENERATE_SUPPORT_FILE_TYPES.includes(
    fileExtension.toLowerCase()
  );
};

// export const createDownloadFile = (url) => {
//   const link = document.createElement("a");
//   link.href = url;
//   link.target = "_blank";
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// }

export const downloadS3File = (url, options = {}) => {
  console.log(url);
  if (isEmpty(url) || url === 'null') {
    return;
  }
  const {
    isViewOnBrowser = false,
    fileNameParam = null,
    hasTimestamp = false,
  } = options;
  return generatePresignedUrl(url)
    .then((signedUrl) => {
      fetch(signedUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary URL for the blob object
          const fileURL = URL.createObjectURL(blob);

          // Create a temporary link element
          const link = document.createElement('a');
          link.href = fileURL;
          link.target = '_blank';
          if (!isViewOnBrowser) {
            let fileName = fileNameParam || decodeURI(url.split('/').pop());
            if (hasTimestamp) {
              fileName = fileName.split('_').slice(0, -1).join('_');
            }
            link.download = fileName;
          }

          // Append the link to the document body
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up the temporary resources
          URL.revokeObjectURL(fileURL);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Error downloading the file:', error);
        });
    })
    .catch((error) => {
      console.error('Error downloading the file:', error);
    });
};

// export const getS3File = (url, options = {}) => {
//   const { fileNameParam = null, type = '' } = options;
//   return new Promise((resolve, reject) => {
//     generatePresignedUrl(url).then((signedUrl) => {
//       fetch(signedUrl)
//         .then((response) => response.blob())
//         .then((blob) => {
//           const fileName = fileNameParam || url.split('/').pop();
//           const file = new File([blob], fileName, { type });
//           resolve(file);
//         })
//         .catch((error) => {
//           console.error('Error downloading the file:', error);
//         });
//     });
//   });
// };

/**
 * @param {String} cadFile
 */
export const getCadFile = (cadFile) => {
  const findCadFiles = (files) =>
    files.find((item) =>
      ['stp', 'step', 'tg', 'stl'].includes(
        item.split('.').at(-1).toLowerCase()
      )
    );
  if (cadFile.includes(',')) {
    return findCadFiles(cadFile.split(','));
  }
  return findCadFiles([cadFile]);
};

/**
 * only apply for url with file name with format filename_timestamp
 * eg: http://.../qc_report_1234556869.pdf -> qc_report
 * @param {*} fileUrl
 */
export const extractFileNameFromUrl = (fileUrl) => {
  const fileNameWithExtension = fileUrl.split('/').pop();
  const fileExtension = fileNameWithExtension.split('.').pop();
  let fileName = fileNameWithExtension.split('.').slice(0, -1).join('.');
  fileName = fileName.split('_').slice(0, -1).join('_');
  return `${fileName}.${fileExtension}`;
};

export const generateDesignFileNameForRfq = (
  itemID,
  componentNumber,
  fileExtension
) => {
  const fileName = itemID + componentNumber + fileExtension;
  return fileName;
};
