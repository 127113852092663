import React, { Fragment, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import AdminSidebar from './AdminSidebar';
import SupplierSidebar from './SupplierSidebar';

import { ROLE_TYPES } from '../constants';
// import { retrieveExchangeRate, retrieveUserLocation } from "../actions";
import { REDIRECT_URL_KEY } from '../constants/cookiesConstants';
import { setCookie } from '../services/cookiesService';
import { retrieveExchangeRate, retrieveUserLocation } from '../actions';

function PrivateRoute({
  isAuthenticated,
  isVerifying,
  role,
  userID,
  deniedRoles,
  ...rest
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      setCookie(REDIRECT_URL_KEY, `${location.pathname}${location.search}`, {
        path: '/',
        maxAge: 15 * 60, // 15 minutes
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(retrieveUserLocation(userID));
      dispatch(retrieveExchangeRate());
    }
  }, [isVerifying, isAuthenticated]);

  const getRedirectLoginComp = () => {
    return <Navigate to='/login' replace state={{ from: location }} />;
  };

  if (isEmpty(role)) {
    return getRedirectLoginComp();
  }

  const getAuthenticatedComp = () => {
    let comp = <Outlet />;
    if (!isEmpty(deniedRoles) && deniedRoles.includes(role)) {
      comp = getRedirectLoginComp();
    }
    return (
      <Fragment>
        {role === ROLE_TYPES.ADMIN || role === ROLE_TYPES.SUPER_ADMIN ? (
          <AdminSidebar>{comp}</AdminSidebar>
        ) : role !== ROLE_TYPES.SUPPLIER ? (
          <SupplierSidebar>{comp}</SupplierSidebar>
        ) : (
          comp
        )}
      </Fragment>
    );
  };

  return isAuthenticated ? (
    getAuthenticatedComp()
  ) : (
    <Navigate to='/login' replace />
  ); // state={{ from: props.location }}
}

function mapStateToProps(state) {
  return {
    role: state.auth?.user?.role,
    userID: state.auth?.user?.userID,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
