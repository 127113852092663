import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SolidBlueButton from '../components/buttons/SolidBlueButton';
import Subheader from '../components/Subheader';

import {
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { getAllOnboardingItems } from '../actions';

import { notifyError, notifySuccess } from '../services/notificationService';

import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';
import { TECHNOLOGIES_ARR } from '../constants/technologies';
import { CNC_FINISHING_COLOURS } from '../constants/technologies';
import {
  assignOnboardingItems,
  verifyOnboardingSupplier,
} from '../apis/onboardingApi';

import {
  PAGE_KEYS,
  BUTTON_KEYS,
} from '../constants/onboardingAdminButtonsClickedConstants';
import { useButtonClicked } from '../hooks/useButtonClicked';
import { colors } from '../palette';

function InitiationProgress(props) {
  const { user, items, onPageLoad } = props;

  const [selectedCapability, setSelectedCapability] = useState([]);
  const [cncTestParts, setCncTestParts] = useState([]);
  const [threeDTestParts, setThreeDTestParts] = useState([]);
  const [smfTestParts, setSmfTestParts] = useState([]);
  const [selectedTestPart, setSelectedTestPart] = useState(null);
  const [color, setColor] = useState(null);
  const [buttonsClicked, addButtonsClicked] = useButtonClicked(
    user.userID,
    PAGE_KEYS.INITIATION
  );

  let hasSelectedCapabilities = selectedCapability.length > 0;

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    setCncTestParts(
      items.filter(
        (item) => item.technology === TECHNOLOGY_OPTION_TYPE.CNC_MACHINING
      )
    );
    setThreeDTestParts(
      items.filter(
        (item) => item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
      )
    );
    setSmfTestParts(
      items.filter(
        (item) =>
          item.technology === TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION
      )
    );
  }, [user, items]);

  const onClickSendEmail = () => {
    if (!hasSelectedCapabilities) {
      notifyError('Missing machining capabilities');
      return;
    }
    if (selectedTestPart === null) {
      notifyError('Missing test part');
      return;
    }
    if (
      selectedCapability.includes(TECHNOLOGY_OPTION_TYPE.CNC_MACHINING) &&
      color === null
    ) {
      notifyError('Missing color selection');
      return;
    }

    let qcReports = {
      main: 'Standard QC Report',
      addOns: [],
      others: [],
    };

    // handle create one technology / project first
    const payload = {
      supplierID: user.userID,
      projectStatus: 'Open',
      items: Object.values(selectedTestPart).map((part) => {
        const item = {
          ...part,
          name: part.referenceName,
          qty: part.qty,
          cadPart: [part.cadFile],
          surfaceFinish:
            part.surfaceFinish === 'Standard' ? 'NIL' : part.surfaceFinish,
          predefinedItemID: part.id,
          description: part.description,
        };
        if (part.threeDInfill) {
          item.threeDInfill =
            parseFloat(part.threeDInfill.replace('%', '')) / 100;
        }
        if (part.threeDLayerThickness) {
          item.threeDLayerThickness = parseFloat(
            part.threeDLayerThickness.replace('mm', '')
          );
        }
        if (part.technology === TECHNOLOGY_OPTION_TYPE.CNC_MACHINING) {
          item.color = color;
        }
        if (part.imageUrl) {
          item.imageUrl = part.imageUrl;
        }
        return item;
      }),
      qcReports,
    };
    try {
      assignOnboardingItems(payload);
      addButtonsClicked(BUTTON_KEYS.SEND_INITIATION_EMAIL); // Button only changes green if the database is successfully updated
    } catch (err) {
      notifyError('There was an error. Please contact Factorem support team.');
    }
  };

  const getRelevantTestPart = (capability) => {
    switch (capability) {
      case TECHNOLOGY_OPTION_TYPE.CNC_MACHINING:
        return cncTestParts;
      case TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING:
        return threeDTestParts;
      case TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION:
        return smfTestParts;
      default:
        return [];
    }
  };

  const handleCapabilityChange = (event) => {
    const value = event.target.value;
    setSelectedCapability(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSelectTestPart = (capability, value) => {
    setSelectedTestPart({
      ...selectedTestPart,
      [capability]: value,
    });
  };

  const renderColorSelection = () => {
    return (
      <Fragment>
        <Typography style={{ paddingBottom: '1rem' }}>
          Please select a color for the CNC Machining part.
        </Typography>
        <FormControl
          key='color-selection-section'
          style={{ display: 'block', paddingBottom: '1rem' }}
        >
          <InputLabel>Color options</InputLabel>
          <Select
            id={`select-color`}
            value={color}
            onChange={(event) => {
              setColor(event.target.value);
            }}
            style={{ minWidth: '20rem' }}
            label={`Color options`}
          >
            {CNC_FINISHING_COLOURS.map((color) => (
              <MenuItem key={color} value={color}>
                {color}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Fragment>
    );
  };

  return (
    <Card style={{ padding: '1rem', width: '60%' }} variant='outlined'>
      <CardContent>
        <Subheader title='Configure Supplier Information' />
        <Typography style={{ paddingBottom: '1rem' }}>
          Please select the technologies you would like to onboard for this
          supplier.
        </Typography>
        <FormControl
          error={!hasSelectedCapabilities}
          style={{ paddingBottom: '1rem' }}
        >
          <InputLabel>Machining Capabilities</InputLabel>
          <Select
            multiple
            id='multiple-select-capability'
            value={selectedCapability}
            onChange={handleCapabilityChange}
            style={{ minWidth: '20rem' }}
            label='Machining Capabilities'
          >
            {TECHNOLOGIES_ARR.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Please select at least one capability.
          </FormHelperText>
        </FormControl>
        <Subheader title='Configure Email' />
        <Typography style={{ paddingBottom: '1rem' }}>
          Please select a test part for every technology you selected above.
        </Typography>
        {selectedCapability.map((capability) => (
          <FormControl
            key={capability}
            style={{ display: 'block', paddingBottom: '1rem' }}
          >
            <InputLabel>{capability} Test Part</InputLabel>
            <Select
              id={`select-${capability}-test-part`}
              value={
                selectedTestPart && capability in selectedTestPart
                  ? selectedTestPart[capability]
                  : ''
              }
              onChange={(event) =>
                handleSelectTestPart(capability, event.target.value)
              }
              style={{ minWidth: '20rem' }}
              label={`${capability} Test Part`}
            >
              {getRelevantTestPart(capability).map((item) => (
                <MenuItem key={item.itemID} value={item}>
                  [Item ID: {item.id}] {item.referenceName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
        {selectedCapability.includes(TECHNOLOGY_OPTION_TYPE.CNC_MACHINING) &&
          renderColorSelection()}
      </CardContent>
      <CardActions
        style={{
          justifyContent: 'right',
        }}
      >
        <Typography
          style={{
            textAlign: 'right',
            fontSize: '0.8rem',
            maxWidth: '20rem',
          }}
        >
          Note: By sending the email, you are also verifying this supplier and
          will kickstart their onboarding process.
        </Typography>
      </CardActions>
      <CardActions style={{ justifyContent: 'right' }}>
        <SolidBlueButton
          btnContent={'send email'}
          onBtnClick={onClickSendEmail}
          disabled={user.step !== 'Initiation'}
          overrideStyle={
            buttonsClicked.includes(BUTTON_KEYS.SEND_INITIATION_EMAIL)
              ? { backgroundColor: colors.successGreen }
              : {}
          }
        />
      </CardActions>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    items: state.items.items,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    onPageLoad: () => dispatch(getAllOnboardingItems()),
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InitiationProgress);
