import * as React from 'react';

import { Select, MenuItem } from '@mui/material';

import { useGridApiContext } from '@mui/x-data-grid';

import { isArray } from 'lodash';
import { TECHNOLOGIES_ARR } from '../constants/technologies';

// adapted from https://github.com/mui/mui-x/issues/4410
/**
 *
 * @param {*} props
 * @returns MultiSelect Edit Component for MUI DataGrid
 */
export function CustomEditComponent(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    const eventValue = event.target.value; // The new value entered by the user
    const newValue =
      typeof eventValue === 'string' ? eventValue : eventValue.toString();

    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    });
  };

  return (
    <Select
      labelId='demo-multiple-name-label'
      id='demo-multiple-name'
      multiple
      value={isArray(value) ? value : value.split(',').filter((x) => x !== '')}
      onChange={handleChange}
      sx={{ width: '100%' }}
    >
      {TECHNOLOGIES_ARR.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}
