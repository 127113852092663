import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';

export const isCustomMaterial = (material) => {
  return material === 'Others' || material === 'Custom Material';
};

export const isCustomTechnology = (technology) => {
  return (
    technology === TECHNOLOGY_OPTION_TYPE.OTHERS ||
    technology === 'Custom Fabrication Technology'
  );
};

export const isCustomSurfaceFinish = (surfaceFinish) => {
  return surfaceFinish === 'Others' || surfaceFinish === 'Custom Finish';
};

export const validateInput = (input) => {
  if (input < 1) {
    return '1';
  }
  return Math.round(input).toString();
};
