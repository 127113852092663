import { Cookies } from 'react-cookie';
import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

// import { getCurrentDateStr, getDateStr } from "../utils/dateTimeUtils";

export const GET_ITEMS_LOADING = 'GET_ITEMS_LOADING';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE';
export const CREATE_ITEM_LOADING = 'CREATE_ITEM_LOADING';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAILURE = 'CREATE_ITEM_FAILURE';
export const VIEW_ITEM_DETAIL_SUCCESS = 'VIEW_ITEM_DETAIL_SUCCESS';
export const OPEN_EDIT_ITEM_PAGE = 'OPEN_EDIT_ITEM_PAGE';
export const GET_ITEMS_AVAILABLE_FOR_INVOICE_LOADING =
  'GET_ITEMS_AVAILABLE_FOR_INVOICE_LOADING';
export const GET_ITEMS_AVAILABLE_FOR_INVOICE_SUCCESS =
  'GET_ITEMS_AVAILABLE_FOR_INVOICE_SUCCESS';
export const GET_ITEMS_AVAILABLE_FOR_INVOICE_FAILURE =
  'GET_ITEMS_AVAILABLE_FOR_INVOICE_FAILURE';

const cookies = new Cookies();

const getItems = () => {
  return {
    type: GET_ITEMS_LOADING,
  };
};

const getItemsSuccess = (items) => {
  return {
    type: GET_ITEMS_SUCCESS,
    items,
  };
};

const getItemsFailure = () => {
  return {
    type: GET_ITEMS_FAILURE,
  };
};

// const createItemSuccess = props => {
//   // props.history.push("/manage-parts");
//   // toast.success("Successfully created new part!", {
//   //   position: toast.POSITION.TOP_RIGHT
//   // });
//   return {
//     type: CREATE_ITEM_SUCCESS
//   };
// };

// const createItemLoading = () => {
//   return {
//     type: CREATE_ITEM_LOADING
//   };
// };

// const createItemFailure = () => {
//   toast.error("Unable to create new part. Please try again later.", {
//     position: toast.POSITION.TOP_RIGHT
//   });
//   return {
//     type: CREATE_ITEM_FAILURE
//   };
// };

// const viewItemDetailsSuccess = (id, props) => {
//   props.history.push(`/items/${id}`);
//   return {
//     type: VIEW_ITEM_DETAIL_SUCCESS,
//     id,
//     props
//   };
// };

export const openEditItemPageSuccess = (id, props) => {
  props.history.push(`/item/edit/${id}`);
  return {
    type: OPEN_EDIT_ITEM_PAGE,
    id,
    props,
  };
};

// const getItemsAvailableForInvoiceLoading = () => {
//   return {
//     type: GET_ITEMS_AVAILABLE_FOR_INVOICE_LOADING
//   };
// };

// const getItemsAvailableForInvoiceSuccess = itemsAvailableForInvoice => {
//   return {
//     type: GET_ITEMS_AVAILABLE_FOR_INVOICE_SUCCESS,
//     itemsAvailableForInvoice
//   };
// };

// const getItemsAvailableForInvoiceFailure = () => {
//   return {
//     type: GET_ITEMS_AVAILABLE_FOR_INVOICE_FAILURE
//   };
// };

// export const getAllItems = () => (dispatch) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/items`;
//   const token = cookies.get("token");
//   fetch(requestUrl, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   })
//     .then(function(response) {
//       dispatch(getItems());
//       response.json().then(items => {
//         if (items.success) {
//           dispatch(getItemsSuccess(items.data));
//           return items.data;
//         } else {
//           dispatch(getItemsFailure());
//         }
//       });
//     })
//     .catch(function() {
//       dispatch(getItemsFailure());
//     });
// };

export const getAllOnboardingItems = () => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/get-items`;
  fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  })
    .then(function (response) {
      dispatch(getItems());
      response.json().then((items) => {
        if (items.success) {
          dispatch(getItemsSuccess(items.data));
          return items.data;
        } else {
          dispatch(getItemsFailure());
        }
      });
    })
    .catch(function () {
      dispatch(getItemsFailure());
    });
};

export const getSingleOnboardingItem = (itemID) => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/items/${itemID}`;
  fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  })
    .then((response) => {
      dispatch(getItems());
      return response.json();
    })
    .then((items) => {
      if (items.success) {
        dispatch(getItemsSuccess(items.data));
        console.log(items.data);
        return items.data;
      } else {
        dispatch(getItemsFailure());
      }
    })
    .catch((error) => {
      console.error('Fetch error:', error);
      dispatch(getItemsFailure());
    });
};

// export const displayItemDetailsPage = (id, props) => dispatch => {
//   dispatch(viewItemDetailsSuccess(id, props));
// };

// export const displayEditItemPage = (id, props) => dispatch => {
//   dispatch(openEditItemPageSuccess(id, props));
// };

// export const receiveFormData = (data, props) => (dispatch, getState) => {
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/create`;
//   const arrayOfCadFiles = data.cadPart;
//   let joinStrings = arrayOfCadFiles.join();
//   let toleranceSubmit = parseFloat(0);
//   if (data.tolerance) {
//     toleranceSubmit = parseFloat(data.tolerance);
//   }

//   const formBody = {
//     name: data.name,
//     status: data.status,
//     datePosted: getCurrentDateStr(),
//     deliveryPreference: data.deliveryPref,
//     partApplication: data.partApplication,
//     quantity: data.qty,
//     technology: data.technology,
//     deadline: getDateStr(data.selectedDeadline),
//     description: data.description,
//     surfaceFinish: data.surfaceFinish,
//     tolerance: toleranceSubmit, // decimal
//     originalFiles: joinStrings,
//     imageFile: data.imgPart,
//     material: data.material,
//     expectedPrice: data.expectedPrice,
//     userID:
//       getState().auth.user.role === "admin" ||
//       getState().auth.user.role === "reviewer" ||
//       getState().auth.user.role === "superadmin"
//         ? data.userID
//         : getState().auth.user.userID
//   };
//   fetch(requestUrl, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${cookies.get("token")}`
//     },
//     body: JSON.stringify(formBody)
//   })
//     .then(function(response) {
//       dispatch(createItemLoading());
//       response.json().then(items => {
//         if (items.success) {
//           dispatch(getAllItems());
//           dispatch(createItemSuccess(props));
//         } else {
//           dispatch(createItemFailure());
//         }
//       });
//     })
//     .catch(function() {
//       dispatch(createItemFailure());
//     });
// };

// export const getAllItemsAvailableForInvoice = () => dispatch => {
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/items/available-for-invoice`;
//   const token = cookies.get("token");
//   fetch(requestUrl, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   })
//     .then(function(response) {
//       dispatch(getItemsAvailableForInvoiceLoading());
//       response.json().then(items => {
//         if (items.success) {
//           dispatch(getItemsAvailableForInvoiceSuccess(items.data));
//         } else {
//           dispatch(getItemsAvailableForInvoiceFailure());
//         }
//       });
//     })
//     .catch(function() {
//       dispatch(getItemsAvailableForInvoiceFailure());
//     });
// };
