// Import settings
import React, { useState } from 'react';
import {
  Drawer,
  Fab,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Forward as ForwardIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@mui/icons-material';

import { makeStyles } from 'tss-react/mui';

// Import color palette
import { colors, FACTOREM_BLUE } from '../palette';

// Style component
const useStyles = makeStyles()({
  root: {
    display: 'table',
    height: '100%',
  },
  drawer: (props) => ({
    flexShrink: 0,
    width: props.drawerWidth,
  }),
  drawerPaper: (props) => ({
    color: colors.fontGrey,
    display: 'flex',
    flexDirection: 'column',
    width: props.drawerWidth,
    justifyContent: 'space-between',
  }),
  sidebar: {
    display: 'table-cell',
  },
});

/**
 *
 * @param {Object} props
 * @param {Number} props.drawerWidth
 * @param {React.ReactElement} props.children
 * @param {React.ReactElement} props.Content
 */

function ResponsiveSidebar(props) {
  const { children, drawerWidth, Content } = props;
  const [showDrawer, setShowDrawer] = useState(false);

  const { classes } = useStyles({ drawerWidth });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderDrawer = () => {
    return (
      <Drawer
        className={classes.drawer}
        style={{ width: drawerWidth }}
        variant={isMobile ? 'temporary' : 'permanent'}
        classes={{
          paper: classes.drawerPaper,
        }}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        anchor='left'
      >
        {isMobile && showDrawer && (
          <IconButton
            style={{
              position: 'fixed',
              top: '50%',
              left: drawerWidth - 45,
              borderRadius: '50%',
              borderLeft: `1px solid ${FACTOREM_BLUE}`,
              padding: 8,
              zIndex: 1,
            }}
            onClick={() => setShowDrawer(false)}
          >
            <NavigateBeforeIcon
              style={{ fontSize: '2rem', color: FACTOREM_BLUE }}
            />
          </IconButton>
        )}
        {children}
      </Drawer>
    );
  };
  const renderLeftSideBar = () => {
    return <div className={classes.sidebar}>{renderDrawer()}</div>;
  };

  const renderExpandDrawerButton = () => {
    return (
      <Fab
        style={{
          position: 'fixed',
          top: '50%',
          left: -22,
          padding: 2,
          zIndex: 999,
        }}
        onClick={() => setShowDrawer(true)}
        color='primary'
        size='large'
      >
        <ForwardIcon style={{ fontSize: '3rem' }} />
      </Fab>
    );
  };

  return (
    <div className={classes.root}>
      {!isMobile && renderLeftSideBar()}
      {isMobile && renderDrawer()}
      {isMobile && renderExpandDrawerButton()}
      <div style={{ display: 'table-cell', width: '100%' }}>{Content}</div>
    </div>
  );
}

export default ResponsiveSidebar;
