import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import _, { get, isEmpty } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { CsvBuilder } from 'filefy';

// import MaterialTable from 'material-table';

import { makeStyles } from 'tss-react/mui';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import {
  Alert,
  Button,
  Icon,
  IconButton,
  MenuList,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  FilterList,
  FirstPage,
  GetApp,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Visibility,
} from '@mui/icons-material';

import FactCheckIcon from '../assets/icons/fact_check_blue.svg';

// import { generateCurrentCustomDateTimeString } from '../util';
import * as fileUtils from '../utils/fileUtils';
// import { getMaterialWithColorText, getSurfaceFinishWithColorText } from "../utils/itemUtils";
import {
  getAcceptedQuotationsBySupplier,
  supplierAcknowledgePO,
  supplierBulkAcknowledgePO,
} from '../apis/quotationApi';
import {
  dateAcceptedCol,
  quotationStatusColumn,
  supplierPriceStrDisplayCol,
} from '../constants/quotationTableConstants';

// import { materialWithColorCol, surfaceFinishingWithColorCol } from '../constants/itemTableConstants';
// import { transformCsvExportData } from '../utils/csvExportUtils';
import { ORDER_STATUS, ROLE_TYPES } from '../constants';
import { isEmptyValue } from '../utils/commonUtils';
import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';
import YesNoPopup from '../components/popups/YesNoPopup';
import { notifyError, notifySuccess } from '../services/notificationService';
import { getUnAcknowledgedQuotationsCountAction } from '../actions/poAcknowledge';
import {
  getDateStrWithMonth,
  getSGTDateStrWithMonth,
} from '../utils/dateTimeUtils';
// import { HEADER_STYLE } from '../constants/tableConstants';
import { convertPriceToCurrency } from '../utils/currencyUtils';
// import MenuList from '../components/MenuList';
import { combinedPOs } from '../apis/pdfApi';
import { fourDigitUtil } from '../utils/numberUtils';
import { getQuotationExchangeRate } from '../utils/quotationUtils';
import DownloadIconButton from '../components/buttons/DownloadIconButton';
// import { FEATURE_FLAG_ORDER_READY_MANAGEMENT } from '../constants/featureFlagConstants';
import OrderReadyStatusDisplay from './orderReady/OrderReadyStatusDisplay';
import { PageTitle } from '../components/PageTitle';

import {
  DELIVERY_STATUSES,
  ORDER_READY_STATUS,
  ORDER_READY_STATUS_TOOLTIP,
} from '../constants/orderReadyConstants';

import { colors, FACTOREM_BLUE } from '../palette';

const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: 'right',
  },
  value: {
    textAlign: 'left',
  },
  body: {
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(3),
  },
  table: {
    padding: '1rem',
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  containerMenu: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
  },
  itemMenu: {
    padding: '5px',
    textDecoration: 'none',
    color: colors.fontGrey,
    fontSize: '0.8125rem',
    cursor: 'pointer',
    '&:is(:hover, :active)': {
      backgroundColor: colors.solidBlue,
      color: colors.fontWhite,
    },
  },
  listAcknowledgePartIDs: {
    textAlign: 'start',
    marginLeft: '50px',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  cardDisplay: {
    minWidth: 275,
    variant: 'elevated',
  },
  header: {
    fontSize: 14,
    paddingBottom: theme.spacing(1),
  },
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  rowDetail: {
    color: colors.fontGrey,
    display: 'flex',
    fontSize: '15px',
    marginTop: 5,
    marginLeft: 20,
  },
  rowTitle: {
    fontWeight: 600,
  },
  rowContent: {
    marginLeft: 5,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  orderReadyButton: {
    minWidth: '120px',
    textTransform: 'uppercase',
    background: colors.buttonColorBlueGradient,
    fontSize: '0.8rem',
    color: colors.fontWhite,
  },
  linkButton: {
    color: FACTOREM_BLUE,
    textDecoration: 'underline',
  },
}));

function SupplierAcceptedOrders(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, role, currency, exchangeRate } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { techTags, userID } = user;
  const is3DPrintingSupplier =
    techTags &&
    techTags.includes(TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING.toLowerCase());

  const [tableData, setTableData] = useState({
    rows: [],
    projectItemsCount: {},
  });
  const [selectedAcknowledgeQuotation, setSelectedAcknowledgeQuotation] =
    useState(null);
  const [openAcknowledgeQuotationPopup, setOpenAcknowledgeQuotationPopup] =
    useState(false);
  const [selectAcknowledge, setSelectAcknowledge] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});

  const loadTableData = () => {
    getAcceptedQuotationsBySupplier(userID).then((allAcceptedQuotes) => {
      if (isEmpty(allAcceptedQuotes)) {
        setTableData({
          rows: [],
          projectItemsCount: {},
        });
        return [];
      }
      const projectItemsCount = allAcceptedQuotes.reduce((acc, quote) => {
        acc[quote.projectID] = {
          total: (acc[quote.projectID] ? acc[quote.projectID].total : 0) + 1,
          itemIDs: acc[quote.projectID]
            ? [...acc[quote.projectID].itemIDs, quote.itemID]
            : [quote.itemID],
        };
        return acc;
      }, {});
      setTableData({
        rows: allAcceptedQuotes,
        projectItemsCount,
      });
    });
  };

  useEffect(() => {
    if (isEmptyValue(userID)) {
      return;
    }
    loadTableData();
  }, [userID]);

  const handleAcknowledgePO = () => {
    const { quotationID } = selectedAcknowledgeQuotation;
    supplierAcknowledgePO(quotationID)
      .then(() => {
        notifySuccess('Acknowledged PO Successfully');
        loadTableData();
        dispatch(getUnAcknowledgedQuotationsCountAction(userID));
      })
      .catch(() => notifyError('Acknowledged PO failed'));
  };

  const handleBulkAcknowledgePO = (projectID) => {
    supplierBulkAcknowledgePO({ projectID, supplierID: userID })
      .then(() => {
        notifySuccess('Acknowledged PO (project) Successfully');
        loadTableData();
        dispatch(getUnAcknowledgedQuotationsCountAction(userID));
      })
      .catch(() => notifyError('Acknowledged PO (project) failed'));
  };

  const handleDownloadPOProject = (projectID, combinedPOsForm) => {
    if (combinedPOsForm !== null) {
      fileUtils.downloadS3File(combinedPOsForm);
      return;
    }
    combinedPOs({ projectID, supplierID: userID })
      .then((data) => {
        fileUtils.downloadS3File(data.pdfUrl);
        loadTableData();
      })
      .catch(() => notifyError('Download PO (project) failed'));
  };

  const renderYes = (date) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ color: 'green' }}>Yes</span>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            whiteSpace: 'nowrap',
          }}
        >
          {date}
        </div>
      </div>
    );
  };

  const onClickAcknowledgePO = (rowData, type) => {
    setSelectedAcknowledgeQuotation(rowData);
    setSelectAcknowledge(type);
    setOpenAcknowledgeQuotationPopup(true);
  };

  const handleDownloadItemFiles = (rowData) => {
    const urlList = rowData.cadFile.split(',').map((url) => ({
      url,
      fileName: fileUtils.generateDesignFileNameForRfq(
        rowData.itemID,
        '01.',
        url.toString().split('.').pop()
      ),
    }));
    const folderName = `part-${rowData.itemID}`;
    fileUtils.downloadAllS3Files(urlList, folderName);
  };

  const renderButtonAcknowledgePO = () => {
    return (
      <Tooltip title='Acknowledge PO'>
        <IconButton color='primary'>
          <Icon>
            <img
              className={classes.imageIcon}
              src={FactCheckIcon}
              color='primary'
              alt=''
            />
          </Icon>
        </IconButton>
      </Tooltip>
    );
  };

  const renderNoWithAcknowledgeButton = (rowData, projectItemsCount) => {
    const itemsCount = projectItemsCount[rowData.projectID] || {};

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>
          <span style={{ color: 'orange' }}>No</span>
        </Typography>
        {itemsCount.total === 1 ? (
          <div onClick={() => onClickAcknowledgePO(rowData, 'single')}>
            {renderButtonAcknowledgePO()}
          </div>
        ) : (
          <MenuList parent={renderButtonAcknowledgePO()}>
            <div className={classes.containerMenu}>
              <div
                onClick={() => onClickAcknowledgePO(rowData, 'project')}
                className={classes.itemMenu}
              >
                Acknowledge PO (Project)
              </div>
            </div>
          </MenuList>
        )}
      </div>
    );
  };

  const colPoAcknowledged = (rowData) => {
    return {
      render: (projectItemsCount) => {
        if (rowData.poAcknowledged === null) {
          return 'N.A.';
        }
        if (Boolean(rowData.poAcknowledged) === true) {
          let date = '';
          if (rowData.acknowledgedDate) {
            date = getDateStrWithMonth(rowData.acknowledgedDate);
          }
          return renderYes(date);
        } else if (Boolean(rowData.poAcknowledged) === false) {
          return renderNoWithAcknowledgeButton(rowData, projectItemsCount);
        } else {
          return 'N.A.';
        }
      },
    };
  };

  const colOrderReady = (rowData) => {
    return {
      render: () => {
        const orderReadyPath = `/projects/${rowData.projectID}/order-ready`;
        if (
          [
            ORDER_READY_STATUS.NEED_UPDATE,
            ORDER_READY_STATUS.SUBMITTED,
            ORDER_READY_STATUS.VERIFIED,
            ORDER_READY_STATUS.IN_TRANSIT,
            ORDER_READY_STATUS.READY_TO_SHIP,
            ORDER_READY_STATUS.DELIVERED,
          ].includes(rowData.orderReadyStatus)
        ) {
          const selectedTab = DELIVERY_STATUSES.includes(
            rowData.orderReadyStatus
          )
            ? 1
            : 0;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip
                title={ORDER_READY_STATUS_TOOLTIP[rowData.orderReadyStatus]}
              >
                <IconButton
                  color='primary'
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(orderReadyPath, { selectedTab: selectedTab });
                  }}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
              <OrderReadyStatusDisplay
                status={rowData.orderReadyStatus}
                onAcceptedOrderPage={true}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(orderReadyPath, { selectedTab: selectedTab });
                }}
              />
            </div>
          );
        } else if (
          rowData.orderReadyStatus === ORDER_READY_STATUS.NOT_STARTED
        ) {
          return (
            <Tooltip
              title={
                Boolean(rowData.poAcknowledged) !== true
                  ? 'Start Order Ready process when the product is ready to be shipped. Enabled only when PO is acknowledged.'
                  : 'Start Order Ready process when the product is ready to be shipped'
              }
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  size='small'
                  variant='outlined'
                  disableElevation
                  className={classes.orderReadyButton}
                  onClick={() => navigate(orderReadyPath)}
                  disabled={Boolean(rowData.poAcknowledged) !== true}
                >
                  Start Order Ready
                </Button>
              </div>
            </Tooltip>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              N.A.
            </div>
          );
        }
      },
    };
  };

  const getColumns = (projectItemsCount) => [
    {
      headerName: 'Part ID',
      field: 'itemID',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              variant='outlined'
              href={`projects/${rowData.projectID}/quote?technology=${rowData.technology}&viewQuote=true`}
            >
              {rowData.itemID}
            </Button>
            <DownloadIconButton
              id={`download-item-files-${rowData.itemID}`}
              tooltip='Download Design Files'
              icon={GetApp}
              onClick={() => handleDownloadItemFiles(rowData)}
            />
          </div>
        );
      },
      width: 150,
    },
    // {
    //   headerName: 'PO Acknowledged',
    //   renderCell: rowData => colPoAcknowledged(rowData.row).render(projectItemsCount),
    //   // hidden: role !== ROLE_TYPES.SUPPLIER || (role === ROLE_TYPES.SUPPLIER && !isMobile),
    //   // export: false
    // },
    {
      headerName: 'Project ID',
      field: 'projectID',
      renderCell: (rowData) => {
        return (
          <Button
            variant='outlined'
            href={`projects/${rowData.row.projectID}/quote?technology=${rowData.row.technology}&viewQuote=true`}
          >
            {rowData.row.projectID}
          </Button>
        );
      },
    },
    // {
    //   title: "Delivery Date",
    //   cellStyle: {
    //     textAlign: 'center',
    //   },
    //   hidden: true,
    //   export: true,
    //   render: (rowData) => isEmptyValue(rowData.collectionDate) ? "N.A." : getSGTDateStrWithMonth(rowData.collectionDate),
    //   customSort: (a, b) => {
    //     if (a.collectionDate === "N.A.") return 1;
    //     if (b.collectionDate === "N.A.") return -1;
    //     return new Date(a.collectionDate) - new Date(b.collectionDate);
    //   }
    // },
    {
      ...supplierPriceStrDisplayCol({ currency, exchangeRate }),
    },
    {
      ...quotationStatusColumn,
    },
    {
      headerName: 'Purchase Order',
      field: 'purchaseOrder',
      renderCell: (params) => {
        const rowData = params.row;
        const status = rowData.ppeQuoteStatus || rowData.status;
        const itemsCount = projectItemsCount[rowData.projectID] || {};
        return rowData.purchaseOrderForm && status === ORDER_STATUS.ACCEPTED ? (
          <div>
            {itemsCount.total === 1 ? (
              <Button
                data-cy='download-btn'
                size='small'
                variant='outlined'
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownloadPOProject(
                    rowData.projectID,
                    rowData.combinedPOsForm
                  );
                }}
              >
                Download
              </Button>
            ) : (
              <MenuList title='Download' id='download-btn'>
                <div className={classes.containerMenu}>
                  <a
                    data-cy='download-combined-pos'
                    className={classes.itemMenu}
                    onClick={() =>
                      handleDownloadPOProject(
                        rowData.projectID,
                        rowData.combinedPOsForm
                      )
                    }
                  >
                    Download PO (Project)
                  </a>
                </div>
              </MenuList>
            )}
          </div>
        ) : (
          <div>N.A.</div>
        );
      },
      width: 150,
    },
    {
      headerName: 'PO Acknowledged',
      field: 'poAcknowledged',
      renderCell: (rowData) => {
        return colPoAcknowledged(rowData.row).render(projectItemsCount);
      },
      width: 150,
    },
    {
      headerName: 'Order Ready Status',
      field: 'orderReadyStatus',
      renderCell: (rowData) => colOrderReady(rowData.row).render(),
      width: 220,
    },
    // {
    //   ...dateAcceptedCol,
    // },
    // {
    //   title: "Date of Acknowledgement",
    //   render: rowData => rowData.acknowledgedDate
    //     ? getDateStrWithMonth(rowData.acknowledgedDate)
    //     : "",
    //   cellStyle: {
    //     minWidth: 150,
    //     textAlign: 'center',
    //   },
    //   hidden: true,
    //   export: true,
    // },
    //Columns from here onwards are not displayed in the table (they are hidden)
    //They are included here so to make the export excel function work, which will export
    //data from the columns
    // {
    //   title: "Quote ID",
    //   field: "quotationID",
    //   cellStyle: {
    //     textAlign: 'center',
    //   },
    //   hidden: true,
    //   export: true,
    // },
    // {
    //   title: "Date of Quote",
    //   field: "dateOfOrder",
    //   render: rowData => rowData.dateOfOrder
    //     ? getDateStrWithMonth(rowData.dateOfOrder)
    //     : "",
    //   cellStyle: {
    //     minWidth: 150,
    //     textAlign: 'center',
    //   },
    //   hidden: true,
    //   export: true,
    // },
    // {
    //   title: "Customer remarks",
    //   render: rowData => rowData.customerRemarks || 'N.A',
    //   cellStyle: {
    //     maxWidth: "280px",
    //     textAlign: 'center',
    //   },
    //   hidden: true,
    //   export: true,
    // },
    // {
    //   title: "Quantity",
    //   field: "quantity",
    //   hidden: true,
    //   export: true,
    // },
    // {
    //   title: "Lead time",
    //   render: rowData => `${rowData.leadTime} working day(s)`,
    //   hidden: true,
    //   export: true,
    // },
    // {
    //   ...materialWithColorCol,
    //   hidden: true,
    //   export: true,
    // },
    // {
    //   ...surfaceFinishingWithColorCol,
    //   hidden: true,
    //   export: true,
    // },
    // {
    //   title: "CMM Price",
    //   render: rowData => {
    //     return convertPriceToCurrency({
    //       price: (rowData.metadata && rowData.metadata.cmmPrice) || 0,
    //       currency,
    //       exchangeRate: getQuotationExchangeRate(rowData, currency) || exchangeRate,
    //     });
    //   },
    //   hidden: true,
    //   export: true,
    //   cellStyle: {
    //     minWidth: 200,
    //     textAlign: 'center',
    //   },
    // },
    // {
    //   title: "My remarks",
    //   cellStyle: {
    //     maxWidth: "250px",
    //   },
    //   render: rowData => `${rowData.ppeQuoteRemarks || rowData.remarks || 'N.A'}`,
    //   hidden: true,
    //   export: true,
    // },
    // is3DPrintingSupplier ? {
    //   title: "3D Printing Technology",
    //   render: rowData => {
    //     const technology = get(rowData, 'metadata.threeDTechnology');
    //     return isEmptyValue(technology) ? 'N.A' : technology;
    //   },
    //   hidden: true,
    //   export: true,
    // } : null,
    // is3DPrintingSupplier ? {
    //   title: "3D Infill",
    //   render: rowData => {
    //     const infill = get(rowData, 'metadata.threeDInfill');
    //     return isEmptyValue(infill) ? 'N.A' : `${(infill * 100).toFixed(0)}%`;
    //   },
    //   hidden: true,
    //   export: true,
    // } : null,
    // is3DPrintingSupplier ? {
    //   title: "3D Layer Thickness",
    //   render: rowData => {
    //     const thickness = get(rowData, 'metadata.threeDLayerThickness');
    //     return isEmptyValue(thickness) ? 'N.A' : `${thickness}mm`;
    //   },
    //   hidden: true,
    //   export: true,
    // } : null,
  ];

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const _columns = getColumns(tableData.projectItemsCount);
    setColumns(_columns.filter((col) => !!col));
  }, [tableData, isMobile]);

  //Function to render the rows in the detail panel of the tab.e
  // const DetailRow = ({ title, content, id }) => (
  //   <div className={classes.rowDetail}>
  //     <div className={classes.rowTitle}>{title}: </div>
  //     <div className={classes.rowContent} data-cy={id}>{content}</div>
  //   </div>
  // );

  // const renderCMMPrice = (rowData) => {
  //   const price = (rowData.metadata && rowData.metadata.cmmPrice) || 0
  //   const content = convertPriceToCurrency({
  //     price: price, currency,
  //     exchangeRate: getQuotationExchangeRate(rowData, currency) || exchangeRate,
  //   })
  //   return (price > 0 && <DetailRow title="CMM Price" content={content} />)
  // }

  // const customExportCsv = useCallback((columns, rows) => {
  //   const fileName = `All My Accepted Quotes ${generateCurrentCustomDateTimeString()}.csv`;
  //   const { exportedColumns, exportedData } = transformCsvExportData(columns, rows);
  //   const builder = new CsvBuilder(fileName);
  //   builder
  //     .setDelimeter(',')
  //     .setColumns(exportedColumns)
  //     .addRows(exportedData)
  //     .exportFile();
  //   return builder;
  // }, [columns, tableData]);

  return (
    <div>
      <PageTitle title='My Accepted Quotes' />
      {tableData && tableData.rows && tableData.rows.length === 0 ? (
        <div>
          <Alert severity='warning'>No data.</Alert>
        </div>
      ) : (
        <div className={classes.table}>
          <DataGrid
            autoHeight
            rows={tableData.rows}
            columns={columns}
            editMode='row'
            getRowId={(row) => row.quotationID}
            rowModesModel={rowModesModel}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            disableRowSelectionOnClick
          />
        </div>
      )}
      <YesNoPopup
        open={openAcknowledgeQuotationPopup}
        handleYes={
          selectAcknowledge === 'single'
            ? handleAcknowledgePO
            : () =>
                handleBulkAcknowledgePO(selectedAcknowledgeQuotation.projectID)
        }
        handleNo={() => setOpenAcknowledgeQuotationPopup(false)}
        noButtonLabel='Cancel'
        yesButtonLabel='Acknowledge'
        title='Please acknowledge this Purchase Order'
        body={
          selectedAcknowledgeQuotation &&
          selectedAcknowledgeQuotation.purchaseOrderForm ? (
            <div>
              <div>
                {selectAcknowledge === 'single' ? (
                  <div>
                    Acknowledge PO for the following Part ID in Project{' '}
                    {selectedAcknowledgeQuotation.projectID}:
                  </div>
                ) : (
                  <div>
                    Acknowledge PO for the following Part IDs in Project{' '}
                    {selectedAcknowledgeQuotation.projectID}:
                  </div>
                )}
                <ul className={classes.listAcknowledgePartIDs}>
                  {selectedAcknowledgeQuotation &&
                    tableData.projectItemsCount[
                      selectedAcknowledgeQuotation.projectID
                    ].itemIDs.map((itemID, index) => (
                      <li key={index}>{itemID}</li>
                    ))}
                </ul>
                <div>
                  View Project PO:&nbsp;
                  <Link
                    onClick={() =>
                      handleDownloadPOProject(
                        selectedAcknowledgeQuotation.projectID,
                        selectedAcknowledgeQuotation.combinedPOsForm
                      )
                    }
                  >
                    PO #{fourDigitUtil(selectedAcknowledgeQuotation.userID)}
                    {fourDigitUtil(selectedAcknowledgeQuotation.projectID)}
                  </Link>
                </div>
              </div>
            </div>
          ) : null
        }
      />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    user: state.auth.user,
    userId: state.auth.user.userID,
    currency: state.auth.location.currency,
    role: state.auth.user.role,
    exchangeRate:
      state.auth.rates && state.auth.rates[state.auth.location.currency],
  };
}

function matchDispatchToProps(dispatch, props) {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(SupplierAcceptedOrders);
