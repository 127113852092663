import { useState, Fragment } from 'react';

import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';

function DisplayPartDetails(props) {
  const { title, details } = props;

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const daysText = (numOfDays) => {
    if (parseInt(numOfDays) === 1) {
      return 'day';
    } else {
      return 'days';
    }
  };

  return (
    <Fragment>
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component='nav'
        aria-labelledby='nested-list-subheader'
      >
        {title && (
          <ListItemButton onClick={handleClick}>
            <ListItemText primary={title} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List
            component='div'
            disablePadding
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              paddingLeft: '3rem',
            }}
          >
            <Typography>
              <b>Part Name:</b> {details?.name}
            </Typography>
            <Typography>
              <b>Technology:</b> {details?.technology}
            </Typography>
            <Typography>
              <b>Tolerance:</b> {details?.tolerance}
            </Typography>
            <div
              style={{
                display: 'flex',
              }}
            >
              <Typography>
                <b>Surface Finish:&nbsp;</b>
              </Typography>
              <Typography
                style={{
                  color: `${details?.qSurfaceFinish === details?.surfaceFinish ? 'green' : 'orange'}`,
                }}
              >
                {details?.qSurfaceFinish}
              </Typography>
              {details?.qSurfaceFinish !== details?.surfaceFinish && (
                <Typography>
                  &nbsp;(original:&nbsp;
                  <span style={{ color: 'green' }}>
                    {details?.surfaceFinish}
                  </span>
                  )
                </Typography>
              )}
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <Typography>
                <b>Material:&nbsp;</b>
              </Typography>
              <Typography
                style={{
                  color: `${details?.qMaterial === details?.material ? 'green' : 'orange'}`,
                }}
              >
                {details?.qMaterial}
              </Typography>
              {details?.qMaterial !== details?.material && (
                <Typography>
                  &nbsp;(original:&nbsp;
                  <span style={{ color: 'green' }}>{details?.material}</span>)
                </Typography>
              )}
            </div>
            {details?.color && (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Typography>
                  <b>Color:&nbsp;</b>
                </Typography>
                <Typography
                  style={{
                    color: `${details?.qColor === details?.color ? 'green' : 'orange'}`,
                  }}
                >
                  {details?.qColor ? details.qColor : 'NIL'}
                </Typography>
                {details?.qColor !== details?.color && (
                  <Typography>
                    &nbsp;(original:&nbsp;
                    <span style={{ color: 'green' }}>{details?.color}</span>)
                  </Typography>
                )}
              </div>
            )}
            {details?.technology ===
              TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING && (
              <Fragment>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Typography>
                    <b>3D Technology:&nbsp;</b>
                  </Typography>
                  <Typography
                    style={{
                      color: `${details?.qMetadata?.threeDTechnology === details?.metadata?.threeDTechnology ? 'green' : 'orange'}`,
                    }}
                  >
                    {details?.qMetadata?.threeDTechnology || 'NIL'}
                  </Typography>
                  {details?.qMetadata?.threeDTechnology !==
                    details?.metadata?.threeDTechnology && (
                    <Typography>
                      &nbsp;(original:&nbsp;
                      <span style={{ color: 'green' }}>
                        {details?.qMetadata?.threeDTechnology}
                      </span>
                      )
                    </Typography>
                  )}
                </div>
                {details?.metadata?.threeDInfill && (
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Typography>
                      <b>3D Infill:&nbsp;</b>
                    </Typography>
                    <Typography
                      style={{
                        color: `${details?.qMetadata?.threeDInfill === details?.metadata?.threeDInfill ? 'green' : 'orange'}`,
                      }}
                    >
                      {details?.qMetadata?.threeDInfill || 'NIL'}
                    </Typography>
                    {details?.qMetadata?.threeDInfill !==
                      details?.metadata?.threeDInfill && (
                      <Typography>
                        &nbsp;(original:&nbsp;
                        <span style={{ color: 'green' }}>
                          {details?.qMetadata?.threeDInfill}
                        </span>
                        )
                      </Typography>
                    )}
                  </div>
                )}
                {details?.metadata?.threeDLayerThickness && (
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Typography>
                      <b>3D Layer Thickness:&nbsp;</b>
                    </Typography>
                    <Typography
                      style={{
                        color: `${details?.qMetadata?.threeDLayerThickness === details?.metadata?.threeDLayerThickness ? 'green' : 'orange'}`,
                      }}
                    >
                      {details?.qMetadata?.threeDLayerThickness || 'NIL'}
                    </Typography>
                    {details?.qMetadata?.threeDLayerThickness !==
                      details?.metadata?.threeDLayerThickness && (
                      <Typography>
                        &nbsp;(original:&nbsp;
                        <span style={{ color: 'green' }}>
                          {details?.qMetadata?.threeDLayerThickness}
                        </span>
                        )
                      </Typography>
                    )}
                  </div>
                )}
              </Fragment>
            )}
            <Typography>
              <b>Item remarks:</b> {details?.remarks || 'N.A.'}
            </Typography>
            <Typography>
              <b>Supplier remarks:</b> {details?.qRemarks || 'N.A.'}
            </Typography>
            <div
              style={{
                display: 'flex',
              }}
            >
              <Typography>
                <b>Lead Time:&nbsp;</b>
              </Typography>
              <Typography
                style={{
                  color: `${details?.qLeadTime > details?.leadTime || details?.qLeadTime === null ? 'orange' : 'green'}`,
                }}
              >
                {details?.qLeadTime
                  ? `${details?.qLeadTime} ${daysText(details?.qLeadTime)}`
                  : ''}
              </Typography>
              {details?.qLeadTime !== details?.leadTime && (
                <Typography>
                  &nbsp;(original:&nbsp;
                  <span style={{ color: 'green' }}>{details?.leadTime}</span>
                  &nbsp;{daysText(details?.leadTime)})
                </Typography>
              )}
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <Typography>
                <b>Price bidded:&nbsp;</b>
              </Typography>
              {details?.priceBidded ? (
                <Typography>S${details?.priceBidded}</Typography>
              ) : (
                <Typography>N.A.</Typography>
              )}
            </div>
          </List>
        </Collapse>
      </List>
    </Fragment>
  );
}

export default DisplayPartDetails;
