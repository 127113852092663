import { applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage';
import { verifyAuth } from './actions/';
import { persistReducer, persistStore } from 'redux-persist';

export default function configStore(persistedState) {
  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth', 'orders', 'createUser'],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    preloadedState: persistedState,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [applyMiddleware(thunkMiddleware)],
  });

  let persistor = persistStore(store);
  store.dispatch(verifyAuth());
  return { store, persistor };
}
