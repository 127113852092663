import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { getMaterialInfo } from '../apis/configurationApi';

export const useMaterialInfo = () => {
  const [params, setParams] = useState();
  const [description, setDescription] = useState(null);
  const [datasheetUrl, setDatasheetUrl] = useState(null);

  useEffect(() => {
    if (isEmpty(params)) {
      return;
    }
    getMaterialInfo(params).then((response) => {
      if (isEmpty(response)) {
        return;
      }
      setDescription(response.description);
      setDatasheetUrl(response.datasheetUrl);
    });
  }, [params]);

  return [
    {
      description,
      datasheetUrl,
    },
    {
      setParams,
    },
  ];
};
