export const ALL_CAD_FILES_TYPES = [
  'step',
  'stp',
  'stl',
  'obj',
  'aml',
  '3mf',
  'x_t',
  'sldprt',
  '3dxml',
  'catpart',
  'ipt',
  'dxf',
  'dwg',
  'pdf',
  'zip',
];
export const ALL_TECHNICAL_DRAWING_FILES_TYPES = ['pdf'];

export const QC_REPORT_TEMPLATE_DEFAULT =
  'https://docs.google.com/spreadsheets/d/10ArP_KNnAZ5mzy8KRUOYfzH6WPkc00nK/edit?usp=drive_link&ouid=101179497062011384048&rtpof=true&sd=true';
