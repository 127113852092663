export const PASSWORD_RULES = {
  // name should correspond to name in password-validator
  min: {
    min: 8,
    message: '✓ Uses 8 or more characters',
  },
  digits: {
    min: 1,
    message: '✓ Contains at least 1 number',
  },
  uppercase: {
    min: 1,
    message: '✓ Contains at least 1 uppercase letter',
  },
  symbols: {
    min: 1,
    message: '✓ Contains at least 1 special character',
  },
};
