import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

import { notifyError } from '../services/notificationService';

export const GET_FABRICATION_SUCCESS = 'GET_FABRICATION_SUCCESS';
export const GET_FABRICATION_FAILURE = 'GET_FABRICATION_FAILURE';
export const GET_FABRICATION_LOADING = 'GET_FABRICATION_LOADING';

const getFabricationInfoSuccess = (info) => {
  return {
    type: GET_FABRICATION_SUCCESS,
    info: info,
  };
};

const getFabricationInfoFailure = () => {
  notifyError('Unable to load fabrication step data.');
  return {
    type: GET_FABRICATION_FAILURE,
  };
};

const getFabricationInfoLoading = () => {
  return {
    type: GET_FABRICATION_LOADING,
  };
};

export const getFabricationInfo = (userID) => async (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/get-fabrication/${userID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  })
    .then(function (response) {
      dispatch(getFabricationInfoLoading());
      response.json().then((info) => {
        if (info.success) {
          dispatch(getFabricationInfoSuccess(info.data));
        } else {
          dispatch(getFabricationInfoFailure());
        }
      });
    })
    .catch(() => {
      dispatch(getFabricationInfoFailure());
    });
};
