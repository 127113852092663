import {
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USERS_LOADING,
  EDIT_USER_LOADING,
  EDIT_USER_FAILURE,
  EDIT_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from '../actions/users';
import { LOGOUT_SUCCESS } from '../actions/';

const defaultState = {
  users: [],
  getUsersLoading: false,
  getUsersSuccess: false,
  getUsersFailure: false,
  editUserSuccess: false,
  editUserLoading: false,
  editUserFailure: false,
  updateRole: '',
  updateName: '',
  updateEmail: '',
  updateAddress: '',
  updateContact: '',
  updateProfilePic: '',
};

export default function users(state = defaultState, action) {
  const users = [...state.users];
  switch (action.type) {
    case GET_USERS_LOADING:
      return {
        ...state,
        getUsersLoading: true,
        getUsersSuccess: false,
        getUsersFailure: false,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        getUsersLoading: false,
        getUsersSuccess: true,
        getUsersFailure: false,
        users: action.users,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        getUsersLoading: false,
        getUsersSuccess: false,
        getUsersFailure: true,
      };
    case EDIT_USER_LOADING:
      return {
        ...state,
        editUserSuccess: false,
        editUserLoading: true,
        editUserFailure: false,
      };
    case EDIT_USER_FAILURE:
      return {
        ...state,
        editUserSuccess: false,
        editUserLoading: false,
        editUserFailure: true,
      };
    case EDIT_USER_SUCCESS:
      const indexToUpdate = users.findIndex(
        (user) => user.userID === action.editedUser.userID
      );
      if (indexToUpdate !== -1) {
        users[indexToUpdate] = action.editedUser;
      }
      return {
        ...state,
        editUserSuccess: true,
        editUserLoading: false,
        editUserFailure: false,
        users,
      };
    case DELETE_USER_SUCCESS:
      const indexToDelete = users.findIndex(
        (user) => user.userID === action.deleteUserID
      );
      if (indexToDelete !== -1) users.splice(indexToDelete, 1);
      return {
        ...state,
        users,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
}
