import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Avatar } from '@mui/material';

import { colors } from '../palette';
import { getUserName } from '../utils/userUtils';

const useStyles = makeStyles()(() => ({
  profileLink: {
    color: colors.fontGrey,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
    width: '100%',
  },
  displayImage: {
    height: 45,
    width: 45,
    border: `2px solid ${colors.inputLabelGrey}`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  displayName: {
    fontWeight: 600,
    fontSize: '18px',
    marginLeft: 12,
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  },
}));

function ProfileMenuItem(props) {
  const { classes } = useStyles();

  const { name, profilePic } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* <Link to="/profile" className={classes.profileLink}> */}
      {/* /profile not made in this iteration */}
      <Link to='/' className={classes.profileLink}>
        <div>
          <Avatar src={profilePic} className={classes.displayImage} />
        </div>
        <div className={classes.displayName}>{getUserName(name)}</div>
      </Link>
    </div>
  );
}

export default ProfileMenuItem;
