// Import settings
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { isEmpty } from 'lodash';
import { getCookie, removeCookie } from '../services/cookiesService';
import { REDIRECT_URL_KEY } from '../constants/cookiesConstants';

// Import material UI components
import { Box, Button, Container, Paper, Typography } from '@mui/material';

import { ArrowForward } from '@mui/icons-material';
import Megaphone from '../assets/icons/megaphone.svg';

// Import color palette
import { colors } from '../palette';

// Import actions
import { logoutUser } from '../actions/index';
import Background from '../components/backdrops/Background';

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'grid',
    alignContent: 'center',
    justifyItems: 'center',
    textAlign: 'center', // Center the content horizontally
    justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
  },
  title: {
    marginTop: 20,
    marginBottom: -30,
    fontSize: '22pt',
    fontWeight: 700,
    textAlign: 'center',
  },
  imageIcon: {
    height: '64px', // Adjust the height as needed
    width: '64px', // Adjust the width as needed
    margin: 'auto', // Center the image horizontally
    display: 'block', // Ensures that it behaves like a block-level element
  },
  text: {
    fontSize: '12pt',
    textAlign: 'center',
    marginBottom: 35,
  },
  subtext: {
    fontSize: '12pt',
    textAlign: 'center',
    marginTop: '45px',
    marginBottom: '-30px',
  },
  paper: {
    borderRadius: 25,
    display: 'flex',
    padding: '3.5rem 3rem',
    flexDirection: 'column',
    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.20)',
    [theme.breakpoints.down('sm')]: {
      padding: '1.75rem 1.25rem',
    },
    height: '33rem',
    width: '35rem',
    justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    textAlign: 'center',
  },
  redirectButton: {
    fontFamily: 'Open Sans',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '30px',
    borderRadius: 8,
    padding: '0.5rem 1.5rem',
    textAlign: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    width: 'auto',
    display: 'flex',
    justifyContent: 'center', // Center the content horizontally
  },
  backButton: {
    fontWeight: 600,
    fontSize: '12pt',
    marginTop: '15px',
    color: colors.bgGrey,
    textTransform: 'none',
    '&:hover': {
      background: colors.fontWhite,
      color: colors.fontBlackGrey,
    },
  },
  link: {
    color: '#1272E3',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      color: '#0059C1',
    },
  },
}));

function GoMainSupplierPortal(props) {
  const { classes } = useStyles();
  let dispatch = useDispatch();

  const { isAuthenticated } = props;

  const [goBackClicked, setGoBackClicked] = useState(false);
  const [goSupplierPortalClicked, setGoSupplierPortalClicked] = useState(false);

  const goBackToLogin = () => {
    setGoBackClicked(true);
  };

  const goSupplierPortal = () => {
    setGoSupplierPortalClicked(true);
  };

  useEffect(() => {
    if (goBackClicked) {
      dispatch(logoutUser());
    } else if (goSupplierPortalClicked && isAuthenticated) {
      const redirectUrl = getCookie(REDIRECT_URL_KEY);

      if (!isEmpty(redirectUrl)) {
        removeCookie(REDIRECT_URL_KEY, { path: '/' });
        window.location.href = redirectUrl;
      } else {
        // TODO: auto-login to factorem.co using the current user token ?
        let currentUrl = window.location.href;

        if (currentUrl === 'https://onboarding.factorem.co/') {
          window.location.href = 'https://app.factorem.co/login';
        } else if (currentUrl === 'https://onboarding-stg.factorem.co/') {
          window.location.href = 'https://app-staging.factorem.co/login';
        } else {
          window.location.href = 'http://localhost:3000/login';
        }
      }
    }
  }, [goBackClicked, goSupplierPortalClicked, isAuthenticated, dispatch]);

  const MainPlatformLink = ({ currentUrl }) => {
    if (currentUrl === 'https://onboarding.factorem.co/') {
      return (
        <a className={classes.link} href='https://app.factorem.co/login'>
          app.factorem.co
        </a>
      );
    } else if (currentUrl === 'https://onboarding-stg.factorem.co/') {
      return (
        <a
          className={classes.link}
          href='https://app-staging.factorem.co/login'
        >
          app-staging.factorem.co
        </a>
      );
    } else {
      return (
        <a className={classes.link} href='http://localhost:3000/login'>
          localhost:3000
        </a>
      );
    }
  };

  const renderGoMainSupplierPortalPage = () => {
    return (
      <Paper className={classes.paper}>
        <Background />
        <img className={classes.imageIcon} src={Megaphone} alt='' />
        <div className={classes.title}>
          You're currently on the <br /> Supplier Onboarding Platform
        </div>
        <Box style={{ width: '100px', height: '70px' }}></Box>
        <Typography className={classes.text}>
          To view orders and provide quotations, head <br /> to the Supplier
          Portal at <MainPlatformLink currentUrl={window.location.href} />
        </Typography>
        <Typography className={classes.text}>
          To onboard for a new vertical, sign up with a <br /> new email address
          on the onboarding platform.
        </Typography>
        <Button
          type='button'
          variant='contained'
          margin='normal'
          className={classes.redirectButton}
          component={Link}
          onClick={goSupplierPortal}
        >
          Go To Portal{'\u00A0'}
          <ArrowForward />
        </Button>
        <Button
          type='button'
          fullWidth
          variant='text'
          margin='normal'
          className={classes.backButton}
          component={Link}
          onClick={goBackToLogin}
        >
          Go back
        </Button>
        <Typography className={classes.subtext}>
          Need any help?{' '}
          <a
            className={classes.link}
            href='https://api.whatsapp.com/send?phone=6588391796&text='
            target='_blank'
            rel='noopener noreferrer'
          >
            Whatsapp
          </a>
          {' or '}
          <a
            className={classes.link}
            href='mailto: partner@factorem.co'
            target='_blank'
            rel='noopener noreferrer'
          >
            email us
          </a>
        </Typography>
      </Paper>
    );
  };

  return (
    <Container component='main' className={classes.container} maxWidth='sm'>
      {renderGoMainSupplierPortalPage()}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

const withConnect = connect(mapStateToProps);

export default withConnect(GoMainSupplierPortal);
