import React from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import BootstrapInput from './BootstrapInput';
import BootstrapInputSmall from './BootstrapInputSmall';

import { colors, FACTOREM_BLUE } from '../../palette';
import { SURFACE_FINISH_STR } from '../../constants/quotationConstants';
// import InfoMatchValue from '../info/InfoMatchValue';

const useStyles = makeStyles()(() => ({
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '30%',
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
}));

function SurfaceFinishingField(props) {
  const { classes } = useStyles();

  const {
    visible = false,
    surfaceFinishOptions = [],
    onSelect,
    value,
    rowDisplay = false,
    bootstrapStyle = true,
    selectColorSurfaces = [],
    valueCustomer,
    required = false,
  } = props;

  const handleChange = (evt) => onSelect(evt.target.value);

  if (!visible) {
    return null;
  }

  const renderMenuItems = () => {
    return surfaceFinishOptions.map((option) => {
      const label =
        selectColorSurfaces && selectColorSurfaces.includes(option) ? (
          <span>
            {option}&nbsp;
            <span
              style={{
                fontStyle: 'italic',
                color: FACTOREM_BLUE,
                fontSize: '0.8rem',
              }}
            >
              (Select Color)
            </span>
          </span>
        ) : (
          option
        );
      return (
        <MenuItem key={option} value={option}>
          {label}
        </MenuItem>
      );
    });
  };

  if (bootstrapStyle) {
    return (
      <div className={rowDisplay ? classes.rowDisplay : classes.container}>
        <InputLabel
          className={
            rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel
          }
        >
          Surface Finish
        </InputLabel>
        <Select
          className={classes.selectField}
          id='surfaceFinish'
          input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
          value={value}
          renderValue={(value) => value}
          onChange={handleChange}
          required={required}
        >
          {renderMenuItems()}
        </Select>
      </div>
    );
  }

  return (
    <div className={classes.rowContainer}>
      <FormControl variant='outlined' className={classes.formControl} fullWidth>
        <TextField
          labelId='surface-finishing'
          label='Surface Finish'
          id='surface-finishing'
          name={SURFACE_FINISH_STR}
          variant='outlined'
          select
          value={value}
          SelectProps={{
            renderValue: (value) => value,
          }}
          onChange={handleChange}
          margin='dense'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required={required}
        >
          {renderMenuItems()}
        </TextField>
      </FormControl>
      {/* {location.pathname === '/verify-quotes' && (
        <InfoMatchValue
          name='Surface Finish'
          valueCustomer={valueCustomer}
          valueSupplier={value}
        />
      )} */}
    </div>
  );
}

export default SurfaceFinishingField;
