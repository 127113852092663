import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';

import { makeStyles } from 'tss-react/mui';

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';

import { TreeItem, TreeView } from '@mui/lab';

import {
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import BootstrapInput from '../inputs/BootstrapInput';
import BootstrapInputSmall from '../inputs/BootstrapInputSmall';
import { FACTOREM_BLUE } from '../../palette';

const useStyles = makeStyles()(() => ({
  selectField: {
    width: '100%',
  },
  treeItem: {
    marginLeft: '0.5rem',
    '& .MuiTreeItem-label': {
      padding: '0.5rem',
      userSelect: 'none',
    },
  },
}));

const DEFAULT_OPTIONS = [
  {
    category: 'Alu',
    options: ['ALU1', 'ALU32'],
  },
  {
    category: 'Alu2',
    options: ['ALU3', 'ALU4'],
  },
];

function TreeViewBootstrapDropDown(props) {
  const { classes } = useStyles();

  const inputRef = useRef();

  const {
    value,
    onSelect,
    small = false,
    options: treeViewOptions = DEFAULT_OPTIONS,
    bootstrapStyle = true,
    searchable = true,
    required = false,
  } = props;

  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState(value);
  const [selectedDisplay, setSelectedDisplay] = useState(value);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    let _selected = value;
    const _expanded = [];
    for (const treeItem of displayOptions) {
      const obj = treeItem.options.find((o) => o === value);
      if (obj) {
        const { recommended } = treeItem;
        if (recommended.includes(value)) {
          _selected = `${value} (Recommended)`; // append Recommended but not used for now
        }
        _expanded.push(treeItem.category);
        _expanded.push(obj);
        break;
      }
    }
    setSelected(value);
    // setSelectedDisplay(_selected);
    setSelectedDisplay(value);
    if (isEmpty(filterText)) {
      setExpanded(_expanded);
    }
  }, [value, displayOptions]);

  useEffect(() => {
    if (isEmpty(displayOptions)) {
      return;
    }
    setCategories(displayOptions.map((o) => o.category));
  }, [displayOptions]);

  useEffect(() => {
    if (isEmpty(treeViewOptions)) {
      return;
    }
    if (isEmpty(filterText)) {
      setDisplayOptions(treeViewOptions);
    } else {
      const _displayOptions = [];
      const _expanded = [];
      for (const categoryOption of treeViewOptions) {
        const { category, options, ...rest } = categoryOption;
        const filteredOptions = options.filter((o) =>
          o.toLowerCase().includes(filterText.toLowerCase())
        );
        if (isEmpty(filteredOptions)) {
          continue;
        } else {
          _displayOptions.push({
            category,
            options: filteredOptions,
            ...rest,
          });
          _expanded.push(category);
        }
      }
      setDisplayOptions(_displayOptions);
      setExpanded(_expanded);
    }
  }, [treeViewOptions, filterText]);

  const handleToggle = (event, nodeIds) => {
    setExpanded([nodeIds[0]]);
    event.stopPropagation();
  };

  const handleSelect = (event, nodeIds) => {
    if (!categories.includes(nodeIds)) {
      setSelected(nodeIds);
      onSelect(nodeIds);
      setFilterText('');
    }
  };

  const renderSearchInput = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 9999,
          backgroundColor: 'white',
        }}
        key={`search-input-material-category`}
      >
        <IconButton
          className={classes.closeButton}
          aria-label='delete'
          size='small'
          onClick={(event) => {
            event.stopPropagation();
            setFilterText('');
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          <CloseIcon style={{ fontSize: '15pt' }} />
        </IconButton>
        <TextField
          id={`search-input-material-category`}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onFocus={(event) => {
            event.stopPropagation();
          }}
          onSelect={(event) => {
            event.stopPropagation();
          }}
          onKeyUp={(event) => {
            event.stopPropagation();
          }}
          onKeyDown={(evt) => {
            if (evt.key === 'Escape') {
              setFilterText('');
            }
            evt.stopPropagation();
          }}
          inputRef={inputRef}
          value={filterText}
          onChange={(event) => {
            setFilterText(event.target.value);
            event.stopPropagation();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          autoFocus
        />
      </div>
    );
  };

  return (
    <FormControl variant='outlined' fullWidth required={required}>
      {!bootstrapStyle && <InputLabel shrink>Material</InputLabel>}
      <Select
        id='tree-view-bootstrap-dropdown'
        label={!bootstrapStyle ? 'Material' : null}
        className={classes.selectField}
        input={
          bootstrapStyle ? (
            small ? (
              <BootstrapInputSmall />
            ) : (
              <BootstrapInput />
            )
          ) : null
        }
        variant='outlined'
        margin='dense'
        value={selected}
        onChange={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        renderValue={() => {
          return <div>{selectedDisplay}</div>;
        }}
      >
        <div
          style={{
            maxHeight: 500,
          }}
        >
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
          >
            {searchable && renderSearchInput()}
            {displayOptions.map((object) => {
              const { options, recommended } = object;
              return (
                <TreeItem
                  key={object.category}
                  className={classes.treeItem}
                  nodeId={object.category}
                  label={object.category}
                >
                  {options.map((option) => {
                    const label = recommended.includes(option) ? (
                      <span>
                        {option}&nbsp;
                        <span
                          style={{
                            fontStyle: 'italic',
                            color: FACTOREM_BLUE,
                            fontSize: '0.8rem',
                          }}
                        >
                          (Preferred)
                        </span>
                      </span>
                    ) : (
                      option
                    );
                    return (
                      <TreeItem key={option} nodeId={option} label={label} />
                    );
                  })}
                </TreeItem>
              );
            })}
          </TreeView>
        </div>
      </Select>
    </FormControl>
  );
}

export default TreeViewBootstrapDropDown;
