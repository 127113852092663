// import { useNavigate } from "react-router-dom";
// import { Cookies } from "react-cookie";
// import { getAllOrders } from "./orders";

import * as quotationApi from '../apis/quotationApi';
import { notifyError, notifySuccess } from '../services/notificationService';
import { getAllMyOrders } from './myorders';

export const GET_ORDER_LOADING = 'GET_ORDER_LOADING';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const DELETE_ORDER_LOADING = 'DELETE_ORDER_LOADING';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';
export const EDIT_ORDER_LOADING = 'EDIT_ORDER_LOADING';
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE';

// const getOrder = () => {
//   return {
//     type: GET_ORDER_LOADING
//   };
// };

// const getOrderSuccess = order => {
//   return {
//     type: GET_ORDER_SUCCESS,
//     order
//   };
// };

// const getOrderFailure = () => {
//   return {
//     type: GET_ORDER_FAILURE
//   };
// };

// const deleteOrderSuccess = props => {
//   notifySuccess("Order deleted!");
//   return {
//     type: DELETE_ORDER_SUCCESS
//   };
// };

const deleteSupplierOrderSuccess = (props) => {
  notifySuccess('Order deleted!');
  return {
    type: DELETE_ORDER_SUCCESS,
  };
};

const deleteOrderLoading = () => {
  return {
    type: DELETE_ORDER_LOADING,
  };
};

const deleteOrderFailure = () => {
  notifyError('Unable to delete order. Please try again later.');
  return {
    type: DELETE_ORDER_FAILURE,
  };
};

// const editOrderSuccess = () => {
//   notifySuccess("Order successfully edited!");
//   return {
//     type: EDIT_ORDER_SUCCESS
//   };
// };

// const editOrderLoading = () => {
//   return {
//     type: EDIT_ORDER_LOADING
//   };
// };

// const editOrderFailure = (message) => {
//   const toastMessage = message || 'Unable to edit order. Please try again later.';
//   notifyError(toastMessage);
//   return {
//     type: EDIT_ORDER_FAILURE
//   };
// };

// const cookies = new Cookies();

// export const getOrderDetails = id => dispatch => {
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/quotation/${id}`;
//   fetch(requestUrl, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json; charset=utf-8",
//       Authorization: `Bearer ${cookies.get("token")}`
//     }
//   })
//     .then(function (response) {
//       dispatch(getOrder());
//       response.json().then(order => {
//         dispatch(getOrderSuccess(order.data[0]));
//       });
//     })
//     .catch(function () {
//       dispatch(getOrderFailure());
//     });
// };

export const deleteSupplierOrder = (quoteID, props) => async (dispatch) => {
  dispatch(deleteOrderLoading());
  try {
    await quotationApi.deleteSupplierOrder(quoteID);
    dispatch(deleteSupplierOrderSuccess(props));
    dispatch(getAllMyOrders());
  } catch (err) {
    dispatch(deleteOrderFailure());
  }
};

// export const deleteOrder = (deletedOrder, props) => dispatch => {
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/quotation/delete/${deletedOrder}`;
//   fetch(requestUrl, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json; charset=utf-8",
//       Authorization: `Bearer ${cookies.get("token")}`
//     }
//   }).then(function (response) {
//     dispatch(deleteOrderLoading());
//     response.json().then(res => {
//       if (res.success) {
//         let navigate = useNavigate();
//         navigate("/manage-orders");
//         dispatch(deleteOrderSuccess(props));
//       } else {
//         dispatch(deleteOrderFailure());
//       }
//     });
//   }).catch(function () {
//     dispatch(deleteOrderFailure());
//   });
// };

// export const editOrder = editedOrder => (dispatch, getState) => {
//   const orderID = editedOrder.quotationID
//     ? editedOrder.quotationID
//     : getState().order.order.quotationID;
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/quotation/edit/${orderID}`;

//   const newOrder = {
//     name: editedOrder.editName,
//     status: editedOrder.editStatus,
//     dateOfExpiry: editedOrder.editDateOfExpiry,
//     priceBidded: {
//       quantity: editedOrder.editQuantity,
//       quote: editedOrder.editPriceBidded,
//       markupPercent: editedOrder.editMarkupPercent,
//       material: editedOrder.editMaterial,
//       otherMaterial: editedOrder.otherMaterial,
//       materialColor: editedOrder.materialColor,
//       surfaceFinish: editedOrder.editSurfaceFinish,
//       otherSurfaceFinish: editedOrder.otherSurfaceFinish,
//       color: editedOrder.color,
//     },
//     gst: editedOrder.editGst,
//     deliveryFee: editedOrder.editDeliveryFee,
//     remarks: editedOrder.editRemarks,
//     leadTime: editedOrder.editLeadTime,
//     markupLeadTime: editedOrder.editMarkupLeadTime,
//     customerRemarks: editedOrder.editCustomerRemarks,
//     isPPE: editedOrder.isPPE,
//     userID:
//       getState().auth.user.role === "admin" ||
//         getState().auth.user.role === "reviewer" ||
//         getState().auth.user.role === "superadmin"
//         ? editedOrder.editUserID
//         : getState().auth.user.userID,
//     threeDTechnology: editedOrder.threeDTechnology,
//     threeDInfill: editedOrder.threeDInfill,
//     threeDLayerThickness: editedOrder.threeDLayerThickness,
//     cmmPrice: editedOrder.editedCmmPrice,
//     markupCmmPrice: editedOrder.markupCmmPrice,
//     cmmPriceCustomer: editedOrder.cmmPriceCustomer,
//   };

//   return fetch(requestUrl, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${cookies.get("token")}`
//     },
//     body: JSON.stringify(newOrder)
//   }).then(function (response) {
//     dispatch(editOrderLoading());
//     response.json().then(res => {
//       if (res.success) {
//         dispatch(getAllOrders());
//         dispatch(editOrderSuccess());
//       } else {
//         const message = res.statusCode === 401
//           ? 'Permission Denied'
//           : null;
//         dispatch(editOrderFailure(message));
//       }
//     });
//   }).catch(function (err) {
//     dispatch(editOrderFailure());
//   });
// };
