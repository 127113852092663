import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from 'tss-react/mui';

import { getAllProjectOrderReady } from '../apis/orderReadyApi';

import {
  getLatestCollectionDate,
  getLatestDeliveryDate,
} from '../utils/itemUtils';
// import { includesIgnoreCase } from "../utils/stringUtils";

import { ORDER_READY_STATUS_MAPPING } from '../constants/orderReadyConstants';
import OrderReadyStatusDisplay from './orderReady/OrderReadyStatusDisplay';
import { PageTitle } from '../components/PageTitle';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(3),
  },
  headerName: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

function AllOrderReadyManagement() {
  const classes = useStyles();

  const navigate = useNavigate();

  const { data: allProjectOrderReady = [] } = useQuery(
    'getAllProjectOrderReady',
    getAllProjectOrderReady
  );

  const [rowModesModel, setRowModesModel] = useState({});

  const columns = [
    {
      headerName: 'Order Ready ID',
      field: 'projectOrderReadyID',
      width: 200,
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
      width: 200,
    },
    {
      headerName: 'Part ID(s)',
      field: 'itemOrderReadyList',
      renderCell: (params) => {
        return params.value.map((item) => item.itemID).join(', ');
      },
    },
    {
      headerName: 'Supplier',
      field: 'supplier',
      valueGetter: (params) => {
        return `${params.row.supplierName} (${params.row.supplierEmail})`;
      },
      width: 450,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: (rowData) => {
        return <OrderReadyStatusDisplay status={rowData.value} />;
      },
      width: 200,
    },
    {
      headerName: 'Collection Date',
      field: 'itemOrderReadyList',
      renderCell: (rowData) => {
        return getLatestCollectionDate(rowData.value);
      },
      // customSort: (a, b) => {
      //   const dateA = new Date(getLatestCollectionDate(a.itemOrderReadyList));
      //   const dateB = new Date(getLatestCollectionDate(b.itemOrderReadyList));
      //   return dateB - dateA;
      // },
      width: 200,
    },
    {
      headerName: 'Delivery Date',
      field: 'itemOrderReadyList',
      renderCell: (rowData) => {
        return getLatestDeliveryDate(rowData.value);
      },
      // customSort: (a, b) => {
      //   const dateA = new Date(getLatestDeliveryDate(a.itemOrderReadyList));
      //   const dateB = new Date(getLatestDeliveryDate(b.itemOrderReadyList));
      //   return dateB - dateA;
      // },
      width: 200,
    },
  ];

  const handleRowClick = (rowData) => {
    navigate(
      `/projects/${rowData.row.projectID}/order-ready?supplierID=${rowData.row.supplierID}`
    );
  };

  return (
    <div className={classes.body}>
      <PageTitle title='All Project Order Ready' />
      {/* <MaterialTable
        title=""
        icons={TABLE_ICONS}
        columns={columns}
        data={allProjectOrderReady}
        onRowClick={handleRowClick}
        options={{
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: true,
          pageSize: 10,
          headerStyle: {
            textAlign: 'center',
          },
        }}
      /> */}
      <div
        style={{
          width: '80vw',
          overflow: 'scroll',
          marginLeft: '15px',
          marginRight: '15px',
        }}
      >
        <DataGrid
          autoHeight
          rows={allProjectOrderReady}
          columns={columns}
          editMode='row'
          getRowId={(row) => row.projectOrderReadyID}
          rowModesModel={rowModesModel}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
}

export default AllOrderReadyManagement;
