import { createBulkQuotations } from '../apis/quotationApi';
import { getDateStr } from '../utils/dateTimeUtils';

export const INIT_FORM_STATE = 'INIT_FORM_STATE';
export const UPDATE_QUOTE_FIELD_VALUE = 'UPDATE_QUOTE_FIELD_VALUE';
export const SUBMIT_QUOTES = 'SUBMIT_QUOTES';
export const ADD_QUOTE_FIELDS = 'ADD_QUOTE_FIELDS';
export const REMOVE_QUOTATION = 'REMOVE_QUOTATION';
export const FORM_ERROR = 'FORM_ERROR';
export const UPDATE_REMARKS_VALUE = 'UPDATE_REMARKS_VALUE';
export const TOGGLE_IGNORE_ITEM = 'TOGGLE_IGNORE_ITEM';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_WATCHING_JOB = 'UPDATE_WATCHING_JOB';
export const UPDATE_ALL_WATCHING_JOB = 'UPDATE_ALL_WATCHING_JOB';

export const initFormState = (project) => (dispatch, getState) => {
  const authState = getState().auth;
  const currency = authState.location.currency;
  const exchangeRate = authState.rates[authState.location.currency];
  const action = {
    type: INIT_FORM_STATE,
    payload: {
      ...project,
      currency,
      exchangeRate,
    },
  };
  dispatch(action);
};

export const updateQuoteFieldValue = (payload) => {
  return {
    type: UPDATE_QUOTE_FIELD_VALUE,
    payload,
  };
};

export const submitQuotes = (payload) => async (dispatch, getState) => {
  const authState = getState().auth;
  const currency = authState.location.currency;
  const exchangeRate = authState.rates[authState.location.currency];
  const projectBulkQuotationsForm = getState().projectBulkQuotationsForm;
  const items = projectBulkQuotationsForm.items.filter(
    (item) => item.ignoreItem === false && !item.supplierQuoted
  );
  const { dateOfExpiry } = payload;
  const requestBody = {
    ...projectBulkQuotationsForm,
    currency,
    exchangeRate,
    items,
    ...payload,
    dateOfExpiry: getDateStr(dateOfExpiry),
  };
  return createBulkQuotations(requestBody);
};

export const addQuoteFields = (payload) => {
  return {
    type: ADD_QUOTE_FIELDS,
    payload,
  };
};

export const removeQuotation = (payload) => {
  return {
    type: REMOVE_QUOTATION,
    payload,
  };
};

export const formError = (payload) => {
  return {
    type: FORM_ERROR,
    payload,
  };
};

export const updateRemarksValue = (payload) => {
  return {
    type: UPDATE_REMARKS_VALUE,
    payload,
  };
};

export const toggleIgnoreItem = (payload) => {
  return {
    type: TOGGLE_IGNORE_ITEM,
    payload,
  };
};

export const updateCurrency = (currency) => (dispatch, getState) => {
  const authState = getState().auth;
  const exchangeRate = authState.rates[authState.location.currency];
  const action = {
    type: UPDATE_CURRENCY,
    payload: {
      currency,
      exchangeRate,
    },
  };
  dispatch(action);
};

export const updateWatchingJob = (payload) => {
  return {
    type: UPDATE_WATCHING_JOB,
    payload,
  };
};

export const updateAllWatchingJob = (payload) => {
  return {
    type: UPDATE_ALL_WATCHING_JOB,
    payload,
  };
};
