import { isEmpty } from 'lodash';

import { isEmptyValue } from './commonUtils';
import { compareDate, formatDeliveryDate } from './dateTimeUtils';

import { PLACEHOLDER_IMAGE_URL } from '../constants/imageConstants';
import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';

export const getItemImageUrl = (item) => {
  return item.imageFile && item.imageFile.startsWith('http')
    ? item.imageFile
    : item.twoDImageUrl && item.twoDImageUrl.startsWith('http')
      ? item.twoDImageUrl
      : PLACEHOLDER_IMAGE_URL;
};

export const getItemNameFromCadFile = (cadFile) => {
  const fileNameWithExtension = cadFile.split('/').pop();
  const fileNameOnly = fileNameWithExtension.split('.').slice(0, -1).join(' ');
  const decodedFileName = decodeURIComponent(fileNameOnly); // to handle encoded URL file name which contains special characters
  const partNameArr = decodedFileName.split('_');
  const fileNameWithoutAutoGeneratedNumber =
    partNameArr.length > 1 &&
    Number.isInteger(Number(partNameArr[partNameArr.length - 1]))
      ? partNameArr.slice(0, -1).join('_')
      : partNameArr.join('_');
  return fileNameWithoutAutoGeneratedNumber;
};

export const getFileNameFromCadFile = (cadFile) => {
  const fileExtension = cadFile.split('.').pop();
  const fileName = `${getItemNameFromCadFile(cadFile)}.${fileExtension}`;
  return fileName;
};

export const getMaterialWithColorText = (item) => {
  const material = item.material || 'No material';
  return item.materialColor ? `${item.materialColor} ${material}` : material;
};

export const getSurfaceFinishWithColorText = (item) => {
  const surfaceFinish = item.surfaceFinish || 'No surface finish';
  return !isEmpty(item.color) && item.color !== 'Does not matter'
    ? `${item.color} ${surfaceFinish}`
    : surfaceFinish;
};

export const is3DPTechnology = (technology) => {
  return technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING;
};

export const getLatestCollectionDate = (itemList) => {
  let latestCollectionDate = undefined;
  for (const item of itemList) {
    const { collectionDate } = item;
    if (
      !isEmptyValue(collectionDate) &&
      (typeof latestCollectionDate === 'undefined' ||
        compareDate(collectionDate, latestCollectionDate) > 0)
    ) {
      latestCollectionDate = collectionDate;
    }
  }
  return isEmptyValue(latestCollectionDate)
    ? null
    : formatDeliveryDate(latestCollectionDate);
};

export const getLatestDeliveryDate = (itemList) => {
  let latestDeliveryDate = undefined;
  for (const item of itemList) {
    const { deliveryDate } = item;
    if (
      !isEmptyValue(deliveryDate) &&
      (typeof latestDeliveryDate === 'undefined' ||
        compareDate(deliveryDate, latestDeliveryDate) > 0)
    ) {
      latestDeliveryDate = deliveryDate;
    }
  }
  return isEmptyValue(latestDeliveryDate)
    ? null
    : formatDeliveryDate(latestDeliveryDate);
};

export const getMaxLeadTime = (itemList) => {
  let maxLeadTime = undefined;
  for (const item of itemList) {
    const { leadTime } = item;
    if (
      Number.isInteger(Number(leadTime)) &&
      (typeof maxLeadTime === 'undefined' || Number(leadTime) > maxLeadTime)
    ) {
      maxLeadTime = Number(leadTime);
    }
  }
  return maxLeadTime;
};
