import queryString from 'query-string';
import { getAuthorizedHeader } from '../utils/apiUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

import { BACKEND_SERVICE_URL } from '../constants';

export const assignOnboardingItems = async (payload) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_SERVICE}/onboarding/assignOnboardingItem`,
    {
      method: 'POST',
      headers: getAuthorizedHeader(),
      body: JSON.stringify(payload),
    }
  )
    .then((res) => {
      if (res.ok) {
        notifySuccess(
          'Supplier has been verified and email has been sent successfully!'
        );
      } else {
        notifyError(
          'There was an error. Please fill in the necessary fields and try again.'
        );
      }
    })
    .catch(() => {
      notifyError(
        'There was an error. Please fill in the necessary fields and try again.'
      );
    });
};

/**
 * this is for super admin call only
 *
 * @param {*} userID
 * @returns
 */
export const verifyOnboardingSupplier = async (userID) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_SERVICE}/users/verify/${userID}`,
    {
      method: 'POST',
      headers: getAuthorizedHeader(),
    }
  ).then((res) => {
    if (res.ok) {
      notifySuccess('Supplier has been verified!');
    } else {
      notifyError('There was an error with verification.');
    }
  });
};

export const getPartnershipAgreementInfo = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/partnership-agreement?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

export const sendOnboardCompleteEmail = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/onboard-email`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

export const addNewOnboardingItem = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/predefined-item`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const body = await response.json();
      throw Error(body.error);
    }
    const { data } = await response.json();
    return data;
  });
};

export const editOnboardingItem = async (itemId, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/edit-predefined-item`;
  const requestBody = {
    id: itemId,
    data: body,
  };
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(requestBody),
  }).then(async (response) => {
    if (!response.ok) {
      const body = await response.json();
      throw Error(body.error);
    }
    const { data } = await response.json();
    return data;
  });
};

export const getButtonClicked = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/admin-buttons-clicked?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      const body = await response.json();
      throw Error(body.error);
    }
    const { data } = await response.json();
    return data;
  });
};

export const getSupplierInfoForESign = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/get-supplier-info-partnership-agreement?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

export const getESignFileForSupplier = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/get-partnership-agreement?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

export const generatePartnershipAgreement = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/generate-partnership-agreement`;

  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const body = await response.json();
      throw Error(body.error);
    }
    const responseBody = await response.json();
    return responseBody.createdEmbeddedSigningLinkInfo;
  });
};

export const generateEmbeddedSingingLink = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/generate-embedded-signing-invite`;

  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const body = await response.json();
      throw Error(body.error);
    }
    const responseBody = await response.json();
    return responseBody.signingLink;
  });
};
