// import { get, isEmpty } from "lodash";
// import { isEmptyValue } from "../utils/commonUtils";
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithColorText,
} from '../utils/itemUtils';

// import { includesIgnoreCase } from "../utils/stringUtils";

// import { DELIVERY_OPTIONS_DISPLAY_MAPPING } from "./itemConstants";

// export const deliveryOptionCol = {
//   title: "Delivery Option",
//   cellStyle: {
//     minWidth: 150,
//   },
//   render: rowData => {
//     const deliveryOptionDisplay = isEmpty(rowData.deliveryOption)
//       ? 'N.A.'
//       : DELIVERY_OPTIONS_DISPLAY_MAPPING[rowData.deliveryOption];
//     return deliveryOptionDisplay;
//   },
//   customFilterAndSearch: (term, rowData) => {
//     const data = isEmpty(rowData.deliveryOption)
//       ? 'N.A.'
//       : DELIVERY_OPTIONS_DISPLAY_MAPPING[rowData.deliveryOption];
//     return includesIgnoreCase(data, term);
//   }
// };

// const getCadFileLinksDesign = (fileStr) => {
//   const cadFileLinks = fileStr
//     ? fileStr
//       .split(",")
//       .filter(file => !file.toLowerCase().includes(".pdf"))
//       .toString()
//     : 'N.A.';
//   return cadFileLinks;
// }

// export const customerCadFileLinksDesignCol = {
//   title: "Customer Cad File links (Design)",
//   render: rowData => getCadFileLinksDesign(rowData.originalFiles),
//   customFilterAndSearch: (term, rowData) => {
//     const data = getCadFileLinksDesign(rowData.originalFiles);
//     return includesIgnoreCase(data, term);
//   },
// }

// export const factoremCadFileLinksDesignCol = {
//   title: "Factorem Cad File links (Design)",
//   render: rowData => getCadFileLinksDesign(rowData.cadFile),
//   customFilterAndSearch: (term, rowData) => {
//     const data = getCadFileLinksDesign(rowData.cadFile);
//     return includesIgnoreCase(data, term);
//   },
// }

// const getCadFileLinksPdf = (fileStr) => {
//   const cadFileLinks = fileStr
//     ? fileStr
//       .split(",")
//       .filter(file => file.toLowerCase().includes(".pdf"))
//       .toString()
//     : 'N.A.';
//   return cadFileLinks;
// }

// export const customerCadFileLinksPdfCol = {
//   title: "Customer Cad File links (PDF)",
//   render: rowData => getCadFileLinksPdf(rowData.originalFiles),
//   customFilterAndSearch: (term, rowData) => {
//     const data = getCadFileLinksPdf(rowData.originalFiles);
//     return includesIgnoreCase(data, term);
//   },
// }

// export const factoremCadFileLinksPdfCol = {
//   title: "Factorem Cad File links (PDF)",
//   render: rowData => getCadFileLinksPdf(rowData.cadFile),
//   customFilterAndSearch: (term, rowData) => {
//     const data = getCadFileLinksPdf(rowData.cadFile);
//     return includesIgnoreCase(data, term);
//   },
// }

// export const targetPriceCol = {
//   title: 'Target Unit Price (S$)',
//   field: 'expectedPrice',
//   hidden: true,
//   export: true,
// }

// export const deliveryCostCol = {
//   title: 'Delivery Cost (S$)',
//   field: 'actualDeliveryCost',
//   hidden: true,
//   export: true,
// }

// export const surfaceFinishingCol = {
//   title: 'Finishing',
//   field: 'surfaceFinish',
//   export: true,
// }

export const surfaceFinishingWithColorCol = {
  headerName: 'Finishing',
  field: 'surfaceFinish',
  renderCell: (rowData) => {
    const displayStr = getSurfaceFinishWithColorText(rowData.row);
    return <DataGridWrapTextCell text={displayStr} />;
  },
};

// export const materialCol = {
//   title: 'Material',
//   field: 'material',
// }

export const materialWithColorCol = {
  headerName: 'Material',
  field: 'material',
  renderCell: (rowData) => {
    const displayStr = getMaterialWithColorText(rowData.row);
    return <DataGridWrapTextCell text={displayStr} />;
  },
};

// export const descriptionCol = {
//   title: 'Description',
//   field: 'description',
//   hidden: true,
//   export: true,
// }

// export const partApplicationCol = {
//   title: 'Part Application',
//   field: 'partApplication',
//   hidden: true,
//   export: true,
// }

// export const deliveryPreferenceCol = {
//   title: 'Delivery Preference',
//   field: 'deliveryPreference',
//   hidden: true,
//   export: true,
// }

// export const toleranceCol = {
//   title: 'Tightest Tolerance Required (mm)',
//   field: 'tolerance',
//   hidden: true,
//   export: true,
// }

// export const colorCol = {
//   title: 'Color',
//   field: 'color',
//   hidden: true,
//   export: true,
// }

// export const threeDTechnologyCol = {
//   title: '3D Printing Technology',
//   exportData: rowData => {
//     const data = get(rowData.metadata, 'threeDTechnology') || '';
//     return data;
//   },
//   hidden: true,
//   export: true,
// }

// export const threeDInfillCol = {
//   title: '3D Infill',
//   exportData: rowData => {
//     const data = get(rowData.metadata, 'threeDInfill') || '';
//     return isEmptyValue(data) ? '' : `${Number(data * 100).toFixed(2)}%`;
//   },
//   hidden: true,
//   export: true,
// }

// export const threeDLayerThicknessCol = {
//   title: '3D Layer Thickness',
//   exportData: rowData => {
//     const data = get(rowData.metadata, 'threeDLayerThickness') || '';
//     return isEmptyValue(data) ? '' : `${data}mm`;
//   },
//   hidden: true,
//   export: true,
// }

// export const partIDColumn = {
//   title: "Part ID",
//   field: "itemID",
//   cellStyle: {
//     width: "5%",
//     maxWidth: 5
//   },
// }
