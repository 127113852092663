import React from 'react';

import { IconButton, Tooltip, Typography } from '@mui/material';

import { downloadS3File } from '../../utils/fileUtils';

import { colors } from '../../palette';

function DownloadIconButton(props) {
  const {
    id = 'download-icon-button',
    tooltip,
    icon: IconComp,
    url,
    label,
    onClick,
    navigateUrl = false,
  } = props;

  const handleButtonClick = (e) => {
    if (navigateUrl) {
      window.open(url);
      return;
    }
    if (url) {
      e.stopPropagation();
      downloadS3File(url);
      return;
    }
    if (typeof onClick === 'function') {
      e.stopPropagation();
      onClick(e);
    }
  };

  return (
    <Tooltip arrow title={tooltip} placement='top'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0.5rem',
        }}
      >
        <IconButton
          id={id}
          style={{
            color: colors.fontGrey,
            padding: 2,
          }}
          aria-label='pdf'
          onClick={handleButtonClick}
        >
          <IconComp style={{ fontSize: '2rem' }} />
        </IconButton>
        {label && <Typography variant='caption'>{label}</Typography>}
      </div>
    </Tooltip>
  );
}

export default DownloadIconButton;
