import React from 'react';

import { Chip, Tooltip, Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

function StatusDisplay(props) {
  const {
    label = 'label',
    bgColor,
    textColor = 'black',
    toolTip = '',
    icon,
    onAcceptedOrderPage,
  } = props;

  const useStyles = makeStyles({
    chip: {
      backgroundColor: bgColor,
      color: textColor,
      minWidth: '150px',
    },
    chipHover: {
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.3)',
      },
    },
  });
  const classes = useStyles();

  return (
    <Tooltip title={toolTip}>
      <Chip
        avatar={
          <Avatar style={{ backgroundColor: 'transparent' }}>{icon}</Avatar>
        }
        label={label}
        // className={`${classes.chip} ${onAcceptedOrderPage ? classes.chipHover : ''}`}
        style={{
          backgroundColor: bgColor,
          color: textColor,
          minWidth: '150px',
        }}
      />
    </Tooltip>
  );
}

export default StatusDisplay;
