import PageTitle from '../components/PageTitle';
import AddNewItemForm from '../components/forms/AddNewItemForm';

export default function AddNewItem(props) {
  return (
    <>
      <PageTitle title={'Add New Item'} />
      <AddNewItemForm />
    </>
  );
}
