import { getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

export const getAllProjectOrderReady = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready?onboarding=true`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getProjectOrderReadyInfo = async ({
  projectID,
  supplierID,
  poAcknowledgedVersion = 1,
}) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/project/${projectID}?supplierID=${supplierID}&poAcknowledgedVersion=${poAcknowledgedVersion}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getProjectOrderReadyHistory = async ({ projectOrderReadyID }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/history`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateItemOrderReadyStatus = async (itemOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/item-order-ready/${itemOrderReadyID}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const upsertOrderReadyResource = async (projectOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/resource`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const trackOrderReadyResourceDownload = async ({ resourceID }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/resource/${resourceID}/download-count`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const deleteOrderReadyResource = async (
  projectOrderReadyID,
  resourceID
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/resource/${resourceID}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const adminGetSubmittedProjectOrderReady = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready?status=submitted&onboarding=true`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateOrderShipped = async (projectOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/shipped`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const updateOrderCollected = async (projectOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/collected`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const adminAddItemOrderReadyRequiredImages = async (
  itemOrderReadyID,
  body
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/item-order-ready/${itemOrderReadyID}/add-required-images`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const updateProjectOrderReadyInfo = async (
  projectOrderReadyID,
  body
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};
