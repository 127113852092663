import { isEmpty } from 'lodash';

export const includesIgnoreCase = (str = '', term = '') => {
  if (isEmpty(str)) {
    return false;
  }
  return str.toLowerCase().includes(term.toLowerCase());
};

/**
 *
 * @param {String} text
 * @example "defaultSurfaceFinish" to "Default Surface Finish"
 * @example "threeDTechnology" to "3 D Technology"
 */
export const convertCamelCaseToTitle = (text) => {
  if (text.startsWith('threeD')) {
    text = text.replace(/threeD/g, '3D');
  }
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 *
 * @param {String|Number} data - number to convert
 * @param {*} numDigits - default 4
 * @examples
 * '12' -> '0012'
 */
export const convertToDigits = (data, numDigits = 4) => {
  return data ? data.toString().padStart(numDigits, '0') : '';
};
