import { getUnAcknowledgeQuotationsCount } from '../apis/userApi';

export const GET_PO_UN_ACKNOWLEDGED_COUNT = 'GET_PO_UN_ACKNOWLEDGED_COUNT';

export const getUnAcknowledgedQuotationsCountAction =
  (userId) => (dispatch) => {
    getUnAcknowledgeQuotationsCount(userId).then((count) => {
      dispatch({
        type: GET_PO_UN_ACKNOWLEDGED_COUNT,
        payload: count,
      });
    });
  };
