import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  RETRIEVE_LOCATION_SUCCESS,
  SET_LOCATION_SUCCESS,
  RETRIEVE_EXCHANGE_RATE_SUCCESS,
  SET_EXCHANGE_RATES_SUCCESS,
} from '../actions/';

const defaultState = {
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: false,
  loginError: false,
  logoutError: false,
  isAuthenticated: false,
  location: {
    currency: 'SGD',
  },
  user: {
    role: null,
  },
};

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true,
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false,
      };
    case RETRIEVE_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location,
      };
    case SET_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location,
      };
    case RETRIEVE_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        rates: action.rates,
      };
    case SET_EXCHANGE_RATES_SUCCESS:
      return {
        ...state,
        rates: action.rates,
      };
    default:
      return state;
  }
}
