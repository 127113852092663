import { isEmpty, set } from 'lodash';

import {
  ADD_QUOTE_FIELDS,
  FORM_ERROR,
  INIT_FORM_STATE,
  REMOVE_QUOTATION,
  UPDATE_CURRENCY,
  UPDATE_QUOTE_FIELD_VALUE,
  UPDATE_REMARKS_VALUE,
} from '../actions/projectBulkQuotationsForm';
import { CURRENCY_CODE } from '../constants/currencyConstants';

import formulas from '../formulas/formulas';

import {
  ITEM_QUOTE_TYPE,
  PROJECT_TYPE,
  QC_FORMAL_CMM_REPORT,
} from '../constants/projectConstants';
import {
  MATERIAL_STR,
  OTHER_MATERIAL_STR,
  OTHER_SURFACE_FINISH_STR,
  QUANTITY_STR,
  REMARKS_STR,
  SURFACE_FINISH_STR,
  UNIT_QUOTE_STR,
} from '../constants/quotationConstants';
import {
  convertPriceToCurrency,
  convertPriceWithQuantityToCurrency,
} from '../utils/currencyUtils';
import { isCustomMaterial, isCustomSurfaceFinish } from '../utils/inputUtils';
import { getQuotationExchangeRate } from '../utils/quotationUtils';

const { Decimal } = require('decimal.js');

const defaultState = {};

export const FORM_ERROR_TYPE = {
  FORM: 'FORM',
  ITEM: 'ITEM',
};

/**
 * The partsTotal price here is already converted to supplier's currency
 * @param {*} items
 * @param {*} currency
 * @param {*} exchangeRate
 * @returns
 */
const getPartsTotal = (items, currency, exchangeRate) => {
  if (isEmpty(items) || isEmpty(currency)) {
    return 0;
  }
  const partsTotal = items.reduce((acc, item) => {
    if (item.ignoreItem || item.supplierQuoted) {
      return acc;
    }
    const quotation = item.quotations[0];
    const qExchangeRate =
      getQuotationExchangeRate(item, currency) || exchangeRate;
    if (item.itemQuoteType === ITEM_QUOTE_TYPE.PPE) {
      const { totalPrice } = convertPriceWithQuantityToCurrency({
        totalPrice: quotation.quote,
        quantity: quotation.quantity,
        currency,
        exchangeRate: qExchangeRate,
      });
      return new Decimal(acc).plus(new Decimal(totalPrice)).toNumber();
    }
    const unitQuote =
      item.itemQuoteType !== ITEM_QUOTE_TYPE.PPE ||
      currency === CURRENCY_CODE.SGD
        ? quotation.unitQuote
        : quotation.unitQuote * qExchangeRate;
    return acc + quotation.quantity * unitQuote;
  }, 0);

  const partsTotalStr = currency
    ? convertPriceToCurrency({
        price: partsTotal,
        currency,
        exchangeRate: 1,
      })
    : 0;
  return partsTotalStr;
};

const getOtherQuoteProperties = (item) => {
  const {
    material,
    customMaterial,
    surfaceFinish,
    customSurfaceFinish,
    materialColor,
    color,
  } = item;
  const properties = {
    material,
    otherMaterial: customMaterial,
    surfaceFinish,
    otherSurfaceFinish: customSurfaceFinish,
    materialColor,
    color,
  };
  return properties;
};

export default function projectBulkQuotationsForm(
  state = defaultState,
  action
) {
  switch (action.type) {
    case INIT_FORM_STATE: {
      const {
        projectID,
        projectType,
        items: itemDataList,
        currency,
        exchangeRate,
        qcReports,
      } = action.payload;
      const items = itemDataList.map((item) => {
        const {
          technology,
          material,
          customMaterial,
          surfaceFinish,
          customSurfaceFinish,
          itemQuoteType,
        } = item;
        return {
          itemID: item.itemID,
          name: item.name,
          price: item.price,
          collectionDate: item.collectionDate,
          itemQuoteType,
          referenceName: item.referenceName,
          technology,
          quantity: item.quantity,
          material,
          customMaterial,
          description: item.description,
          surfaceFinish,
          customSurfaceFinish,
          tolerance: item.tolerance,
          cadFile: item.cadFile,
          imageFile: item.imageFile,
          twoDImageUrl: item.twoDImageUrl,
          remarks: '',
          deadline: item.deadline,
          leadTime: item.leadTime,
          metadata: item.metadata,
          color: item.color,
          materialColor: item.materialColor,
          ...item.metadata,
          supplierQuoted: item.supplierQuoted,
          isSupplierQuoteAccepted: item.isSupplierQuoteAccepted,
          exchangeRates: item.exchangeRates,
          instantQuotesLogMeta: item.instantQuotesLogMeta,
          quotations: [
            {
              id: 0,
              quantity: item.quantity || '',
              quote: itemQuoteType === ITEM_QUOTE_TYPE.PPE ? item.price : '',
              unitQuote:
                itemQuoteType === ITEM_QUOTE_TYPE.PPE
                  ? Number(item.price) / (item.quantity || 1)
                  : '',
              remarks: '',
              ...getOtherQuoteProperties(item),
              ...item.metadata,
              exchangeRates: item.exchangeRates,
              cmmPrice: '',
              isCmmReport: false,
            },
          ],
          ignoreItem: false,
          watchingJobStatus: item.watchingJobStatus,
        };
      });
      const initState = {
        projectID,
        projectType,
        items,
        currency,
        exchangeRate,
        partsTotal: convertPriceToCurrency({
          price: 0,
          currency,
          exchangeRate: 1,
        }),
        formError: {},
        qcReports,
      };
      if (
        [
          PROJECT_TYPE.PPE,
          PROJECT_TYPE.MIX_PPE_RFQ,
          PROJECT_TYPE.MIX_TECHNOLOGIES,
        ].includes(projectType)
      ) {
        const newPartsTotal = getPartsTotal(items, currency, exchangeRate);
        initState.partsTotal = newPartsTotal;
      }
      return initState;
    }
    case UPDATE_QUOTE_FIELD_VALUE: {
      const { itemID, quotationID, fieldName, value } = action.payload;
      const cloneState = JSON.parse(JSON.stringify(state));
      const item = cloneState.items.find((item) => item.itemID === itemID);
      const quotation = item.quotations.find(
        (quote) => quote.id === quotationID
      );
      quotation[fieldName] = value;
      if ([QUANTITY_STR, UNIT_QUOTE_STR].includes(fieldName)) {
        quotation.quote = (quotation.quantity * quotation.unitQuote).toFixed(2);
        quotation.totalPrice = formulas.calculateDefaultTotalPrice(
          quotation.quote,
          cloneState.currency,
          cloneState.exchangeRate
        );
        const newPartsTotal = getPartsTotal(
          cloneState.items,
          cloneState.currency,
          cloneState.exchangeRate
        );
        cloneState.partsTotal = newPartsTotal;
      }
      if (fieldName === MATERIAL_STR && !isCustomMaterial(value)) {
        quotation[OTHER_MATERIAL_STR] = null;
      }
      if (fieldName === SURFACE_FINISH_STR && !isCustomSurfaceFinish(value)) {
        quotation[OTHER_SURFACE_FINISH_STR] = null;
      }
      cloneState.formError = {};
      return cloneState;
    }
    case UPDATE_REMARKS_VALUE: {
      const cloneState = JSON.parse(JSON.stringify(state));
      const { itemID, value } = action.payload;

      const itemIndex = cloneState.items.findIndex(
        (item) => item.itemID === itemID
      );
      if (itemIndex === -1) {
        return cloneState;
      }

      // Create a new state object with the updated value
      const newState = {
        ...cloneState,
        items: cloneState.items.map((item, index) => {
          if (index === itemIndex) {
            // Update the specific item's remarks field
            return {
              ...item,
              remarks: value,
            };
          }
          return item;
        }),
      };
      return newState;
    }
    case ADD_QUOTE_FIELDS: {
      const cloneState = JSON.parse(JSON.stringify(state));
      const { itemID } = action.payload;
      const item = cloneState.items.find((item) => item.itemID === itemID);
      const lastQuotation = item.quotations[item.quotations.length - 1];
      item.quotations.push({
        id: lastQuotation.id + 1,
        quantity: '',
        quote: '',
        unitQuote: '',
        leadTime: '',
        remarks: '',
        ...getOtherQuoteProperties(item),
        ...item.metadata,
      });
      return cloneState;
    }
    case REMOVE_QUOTATION: {
      const cloneState = JSON.parse(JSON.stringify(state));
      const { itemID, quotationID } = action.payload;
      const item = cloneState.items.find((item) => item.itemID === itemID);
      item.quotations = item.quotations.filter(
        (quotation) => quotation.id !== quotationID
      );
      return cloneState;
    }
    case UPDATE_CURRENCY: {
      const cloneState = JSON.parse(JSON.stringify(state));
      const { currency, exchangeRate } = action.payload;
      const newPartsTotal = getPartsTotal(
        cloneState.items,
        currency,
        exchangeRate
      );
      cloneState.partsTotal = newPartsTotal;
      cloneState.formError = {};
      return {
        ...cloneState,
        currency,
        exchangeRate,
      };
    }
    case FORM_ERROR: {
      const { type, message } = action.payload;
      const cloneState = JSON.parse(JSON.stringify(state));
      if (type === FORM_ERROR_TYPE.FORM) {
        cloneState.formError.form = message;
      } else if (type === FORM_ERROR_TYPE.ITEM) {
        const { itemID, quotationID, field } = action.payload;
        if (field === REMARKS_STR) {
          set(cloneState.formError, `${itemID}.${field}`, message);
        } else {
          set(
            cloneState.formError,
            `${itemID}.${quotationID}.${field}`,
            message
          );
        }
      }
      return cloneState;
    }
    default:
      return state;
  }
}
