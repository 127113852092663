import { get } from 'lodash';
import { CURRENCY_CODE } from '../constants/currencyConstants';
import { convertPriceToCurrency } from './currencyUtils';

import { isEmptyValue } from './commonUtils';
import { colors } from '../palette';

export const getBackgroundColorByQuoteStatus = (status) => {
  let bgColor = '';
  switch (status) {
    case 'verifying':
      bgColor = '#ffc107';
      break;
    case 'pending':
    case 'submitted':
      bgColor = colors.bgSubmittedStatus;
      break;
    case 'accepted':
      bgColor = '#a1e21e';
      break;
    case 'rejected':
      bgColor = '#ffa37c';
      break;
    default:
      bgColor = '#cecece';
      break;
  }
  return bgColor;
};

export const getQuotationExchangeRate = (quotation, currency) => {
  const exchangeRate = get(
    quotation,
    ['exchangeRates', 'exchangeRates', 'exchange_rates'],
    {}
  )[currency];
  return exchangeRate;
};

export const rejectedReasonTextUtil = (reasonRejected) => {
  return reasonRejected
    .map((reason) => {
      if (reason.includes('Others:')) {
        return `• ${reason.substring(7)}`;
      }
      return `• ${reason}`;
    })
    .join(' ');
};

export const isRocketQuote = (quotation) => {
  return (
    quotation.rfqQuote !== 1 &&
    (quotation.rocketQuote || !isEmptyValue(quotation.adminID))
  );
};

export const getDiscountAmountUtil = (data) => {
  const totalPriceBeforeGst = convertPriceToCurrency(
    {
      price: data.totalPrice,
      currency: CURRENCY_CODE.SGD,
      exchangeRate: 1,
    },
    ''
  );
  const [unitPrice, totalPrice] = totalPriceBeforeGst.split(' ');
  const percentOff = get(data, ['stripeDiscountInfo', 'coupon', 'percent_off']);
  if (isNaN(+percentOff) || +percentOff <= 0) {
    return 'N.A.';
  }
  const discountAmount = ((totalPrice * percentOff) / 100).toFixed(2);
  return `-${unitPrice} ${discountAmount}`;
};

export const isPpeQuote = (quotation) => {
  return quotation.rfqQuote === 0;
};
