import React from 'react';
import { Typography } from '@mui/material';
import { FACTOREM_BLUE } from '../palette';

function Subheader(props) {
  const { title } = props;

  return (
    <Typography
      variant='h6'
      style={{
        color: FACTOREM_BLUE,
        paddingBottom: '0.5rem',
        fontWeight: 600,
        fontSize: '1.1rem',
      }}
    >
      {title}
    </Typography>
  );
}

export default Subheader;
