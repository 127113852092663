import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';
import queryString from 'query-string';

export const getTechnologyOptions = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/technologies`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const get3DPrintingTechnologyOptions = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/3dp-technologies`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getMaterialsByTechnology = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/item-input-config?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getSurfaceFinishingOptions = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/surface-finishing?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getSurfaceFinishColorOptions = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/surface-finishing-colors?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getMaterialColorOptions = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/material-colors?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getSelectColorSurfaces = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/select-color-surfaces?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getMaterialInfo = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/material-info?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

// export const getAllConfigurations = async () => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/configurations`;
//   return fetch(requestUrl, {
//     method: 'GET',
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return (await response.json()).data;
//   });
// };

// export const getConfigByKey = async (key) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/configurations?key=${key}`;
//   return fetch(requestUrl, {
//     method: 'GET',
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return (await response.json()).data;
//   });
// };

// export const updateConfiguration = async (body) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/configurations`;
//   return fetch(requestUrl, {
//     method: 'POST',
//     headers: getAuthorizedHeader(),
//     body: JSON.stringify(body),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return (await response.json()).data;
//   })
// };

// export const getSupportedFileTypes = async (params) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/configurations/supported-file-types?${queryString.stringify(params)}`;
//   return fetch(requestUrl, {
//     method: "GET",
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return (await response.json()).data;
//   })
// };

// export const getFeatureFlags = async (params) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/configurations/feature-flags?${queryString.stringify(params)}`;
//   return fetch(requestUrl, {
//     method: "GET",
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return (await response.json()).data;
//   })
// };

// export const getVerifyItemsChecklist = async (params) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/configurations//verify-items-checklist?${queryString.stringify(params)}`;
//   return fetch(requestUrl, {
//     method: "GET",
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return (await response.json()).data;
//   })
// };
