import queryString from 'query-string';

import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

// export const adminGetAllQuotations = async (params) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/quotations/search?${queryString.stringify(params)}`;
//   return fetch(requestUrl, {
//     method: 'GET',
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     const { data } = await response.json();
//     return data;
//   });
// }

// export const adminExportQuotationsAsCsv = async (params) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/quotations/search/csv-export?${queryString.stringify(params)}`;
//   return fetch(requestUrl, {
//     method: 'GET',
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     return response;
//   });
// }

export const createBulkQuotations = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/bulk-create`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const json = await response.json();
      const { message } = json;
      if (message.startsWith('ER_DUP_ENTRY: Duplicate entry')) {
        throw new Error(
          `Quotation for this supplier and this project is existed.`
        );
      }
      throw new Error(response.statusText);
    }
    return response.json();
  });
};

// export const getAllMyQuotations = async (userId) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/user/quotations/${userId}`;
//   return fetch(requestUrl, {
//     method: 'GET',
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     const { data } = await response.json();
//     return data;
//   });
// }

export const getAcceptedQuotationsBySupplier = async (userId) => {
  const params = {
    status: 'accepted',
    isOnboarding: true,
  };
  const requestUrl = `${BACKEND_SERVICE_URL}/user/quotations/${userId}?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

// export const getQuotationByID = async (quotationId) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quotationId}`;
//   return fetch(requestUrl, {
//     method: 'GET',
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     const { data } = await response.json();
//     return data[0];
//   });
// }

export const deleteSupplierOrder = async (quoteID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quoteID}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

// export const approveMultipleQuotations = async (body) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/quotations/approve`;
//   return fetch(requestUrl, {
//     method: "POST",
//     headers: getAuthorizedHeader(),
//     body: JSON.stringify(body),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return response.json();
//   });
// }

// export const adminUpdateQuotation = async (quotationID, body) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quotationID}/admin-edit`;
//   return fetch(requestUrl, {
//     method: "PUT",
//     headers: getAuthorizedHeader(),
//     body: JSON.stringify(body),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return response.json();
//   });
// }

export const supplierAcknowledgePO = async (quotationID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quotationID}/acknowledge`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const supplierBulkAcknowledgePO = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/bulk-acknowledge`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

// export const getAllPoAutomationQuotations = async () => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/quotations/po-automation`;
//   return fetch(requestUrl, {
//     method: "GET",
//     headers: getAuthorizedHeader(),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return (await response.json()).data;
//   });
// }

// export const setReasonRejectedQuotation = async (body) => {
//   const requestUrl = `${BACKEND_SERVICE_URL}/quotations/reason-rejected`;
//   return fetch(requestUrl, {
//     method: "POST",
//     headers: getAuthorizedHeader(),
//     body: JSON.stringify(body),
//   }).then(async (response) => {
//     if (!response.ok) {
//       throw Error(response.statusText);
//     }
//     return (await response.json()).data;
//   });
// }
