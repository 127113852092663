import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

export const GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS';
export const GET_ASSESSMENT_FAILURE = 'GET_ASSESSMENT_FAILURE';
export const GET_ASSESSMENT_LOADING = 'GET_ASSESSMENT_LOADING';

const getAssessmentInfoSuccess = (info) => {
  return {
    type: GET_ASSESSMENT_SUCCESS,
    info: info,
  };
};

const getAssessmentInfoFailure = () => {
  notifyError('Unable to load assessment step data.');
  return {
    type: GET_ASSESSMENT_FAILURE,
  };
};

const getAssessmentInfoLoading = () => {
  return {
    type: GET_ASSESSMENT_LOADING,
  };
};

export const getAssessmentInfo = (userID) => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/onboarding/get-assessment/${userID}`;
  fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  })
    .then(function (response) {
      dispatch(getAssessmentInfoLoading());
      response.json().then((info) => {
        if (info.success) {
          dispatch(getAssessmentInfoSuccess(info.data));
        } else {
          dispatch(getAssessmentInfoFailure());
        }
      });
    })
    .catch(() => {
      dispatch(getAssessmentInfoFailure());
    });
};
