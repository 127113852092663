import {
  GET_MYORDERS_FAILURE,
  GET_MYORDERS_SUCCESS,
  GET_MYORDERS_LOADING,
} from '../actions';
import { LOGOUT_SUCCESS } from '../actions/';

const defaultState = {
  myorders: [],
  getMyordersLoading: false,
  getMyordersSuccess: false,
  getMyordersFailure: false,
};

export default function myorders(state = defaultState, action) {
  switch (action.type) {
    case GET_MYORDERS_LOADING:
      return {
        ...state,
        getMyordersLoading: true,
        getMyordersSuccess: false,
        getMyordersFailure: false,
      };
    case GET_MYORDERS_SUCCESS:
      return {
        ...state,
        getMyordersLoading: false,
        getMyordersSuccess: true,
        getMyordersFailure: false,
        myorders: action.myorders,
      };
    case GET_MYORDERS_FAILURE:
      return {
        ...state,
        getMyordersLoading: false,
        getMyordersSuccess: false,
        getMyordersFailure: true,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
}
