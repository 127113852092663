import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';

import {
  get3DPrintingTechnologyOptions,
  getMaterialColorOptions,
  getMaterialsByTechnology,
  getSelectColorSurfaces,
  getSurfaceFinishColorOptions,
  getSurfaceFinishingOptions,
  getTechnologyOptions,
} from '../apis/configurationApi';
import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';
import { notifyError } from '../services/notificationService';
import { isEmptyValue } from '../utils/commonUtils';

export const useItemInputConfig = ({
  setTechnology,
  setMaterial,
  setThreeDTechnology,
  setSurfaceFinish,
  setMaterialColor,
  setColor = function () {},
}) => {
  const [technologyOptions, setTechnologyOptions] = useState([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [threeDTechnologyOptions, setThreeDTechnologyOptions] = useState([]);
  const [threeDMaterialOptions, setThreeDMaterialOptions] = useState([]);
  const [defaultThreeDMaterial, setDefaultThreeDMaterial] = useState('');
  const [surfaceFinishOptions, setSurfaceFinishOptions] = useState([]);
  const [materialColorOptions, setMaterialColorOptions] = useState([]);
  const [surfaceFinishColorOptions, setSurfaceFinishColorOptions] = useState(
    []
  );
  const [selectColorSurfaces, setSelectColorSurfaces] = useState([]);

  const loadSelectColorSurfaces = async ({ technology }) => {
    getSelectColorSurfaces({ technology }).then((response) => {
      setSelectColorSurfaces(response);
    });
  };

  const loadTechnologyOptions = async (setDefault = true) => {
    return getTechnologyOptions().then((response) => {
      setTechnologyOptions(response.options);
      if (setDefault) {
        setTechnology(response.default);
        loadSelectColorSurfaces({ technology: response.default });
      }
      return response.options;
    });
  };

  const loadMaterialCategoryOptions = async (params, setDefault = true) => {
    return getMaterialsByTechnology(params).then((response) => {
      setMaterialCategoryOptions(response.options);
      if (response.default && setDefault) {
        setMaterial(response.default);
      }
      return response;
    });
  };

  const loadCustomMaterialOption = async (params) => {
    return getMaterialsByTechnology(params).then((response) => {
      setMaterialCategoryOptions(response.options);
      setMaterial('Custom Material');
      return response;
    });
  };

  const load3DTechnologyOptions = async (setDefault = true) => {
    return get3DPrintingTechnologyOptions().then((response) => {
      setThreeDTechnologyOptions(response.options);
      if (response.default && setDefault) {
        setThreeDTechnology(response.default);
      }
      return response.default;
    });
  };

  const loadThreeDMaterialOptions = async (params, setDefault = true) => {
    const { threeDTechnology } = params;
    if (isEmptyValue(threeDTechnology)) {
      const message = 'Missing threeDTechnology value';
      notifyError(message);
      throw new Error(message);
    }
    return getMaterialsByTechnology(params).then((response) => {
      setThreeDMaterialOptions(response.options);
      setDefaultThreeDMaterial(response.default);
      if (response.default && setDefault) {
        setMaterial(response.default);
      }
      return response;
    });
  };

  const loadSurfaceFinishOptions = async (params, setDefault = true) => {
    return getSurfaceFinishingOptions(params)
      .then((response) => {
        if (isEmpty(response)) {
          setSurfaceFinishOptions([]);
          setSurfaceFinish(null);
          return {
            options: [],
            default: null,
          };
        }
        setSurfaceFinishOptions(response.options);
        if (setDefault) {
          setSurfaceFinish(response.default);
        }
        return response;
      })
      .catch((err) => {
        setSurfaceFinishOptions(['Custom Finish']);
        if (setDefault) {
          setSurfaceFinish(null);
        }
        return {
          options: [],
          default: null,
        };
      });
  };

  const loadMaterialColorOptions = async (params, setDefault = true) => {
    getMaterialColorOptions(params).then((response) => {
      if (isEmpty(response) && setMaterialColorOptions && setMaterialColor) {
        setMaterialColorOptions(null);
        setMaterialColor(null);
        return;
      }
      const colorPalette = response.options;
      if (setMaterialColorOptions) {
        setMaterialColorOptions(colorPalette);
      }
      if (setDefault && setMaterialColor) {
        setMaterialColor(response.default);
      }
    });
  };

  const loadSurfaceFinishColorOptions = async (params, setDefault = true) => {
    setSurfaceFinishColorOptions([]);
    getSurfaceFinishColorOptions(params).then((response) => {
      if (isEmpty(response)) {
        setSurfaceFinishColorOptions([]);
        if (setDefault) {
          setColor(null);
        }
        return;
      }
      const colorPalette = response.options;
      setSurfaceFinishColorOptions(colorPalette);
      if (setDefault) {
        setColor(response.default);
      }
    });
  };

  const surfaceFinishHasChanged = async ({
    technology,
    material,
    surfaceFinish,
  }) => {
    const params = {
      technology,
      material,
      surfaceFinish,
    };
    loadSurfaceFinishColorOptions(params);
  };

  const materialHasChanged = async ({
    technology,
    material,
    threeDTechnology,
  }) => {
    const params = {
      technology,
      threeDTechnology,
      material,
    };
    loadSurfaceFinishOptions(params).then((response) => {
      setColor(null);
      setSurfaceFinishColorOptions([]);
      if (response) {
        const { default: surfaceFinish } = response;
        surfaceFinishHasChanged({ technology, material, surfaceFinish });
      }
    });
    loadMaterialColorOptions(params);
  };

  const threeDTechnologyHasChanged = async ({
    technology,
    threeDTechnology,
  }) => {
    loadThreeDMaterialOptions({
      technology,
      threeDTechnology,
    }).then(({ default: material }) =>
      materialHasChanged({ technology, material, threeDTechnology })
    );
  };

  const technologyHasChanged = async (technology) => {
    if (technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
      load3DTechnologyOptions().then((threeDTechnology) =>
        threeDTechnologyHasChanged({ technology, threeDTechnology })
      );
    } else {
      loadMaterialCategoryOptions({
        technology,
      }).then(({ default: material }) =>
        materialHasChanged({ technology, material })
      );
    }
    loadSelectColorSurfaces({ technology });
  };

  return [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadTechnologyOptions: useCallback(loadTechnologyOptions, []),
      loadMaterialCategoryOptions: useCallback(loadMaterialCategoryOptions, []),
      loadCustomMaterialOption: useCallback(loadCustomMaterialOption, []),
      load3DTechnologyOptions: useCallback(load3DTechnologyOptions, []),
      loadThreeDMaterialOptions: useCallback(loadThreeDMaterialOptions, []),
      loadSurfaceFinishOptions: useCallback(loadSurfaceFinishOptions, []),
      loadMaterialColorOptions: useCallback(loadMaterialColorOptions, []),
      loadSurfaceFinishColorOptions: useCallback(
        loadSurfaceFinishColorOptions,
        []
      ),
      loadSelectColorSurfaces: useCallback(loadSelectColorSurfaces, []),
      technologyHasChanged: useCallback(technologyHasChanged, []),
      materialHasChanged: useCallback(materialHasChanged, []),
      threeDTechnologyHasChanged: useCallback(threeDTechnologyHasChanged, []),
      surfaceFinishHasChanged: useCallback(surfaceFinishHasChanged, []),
      setSurfaceFinishOptions,
      setMaterialColorOptions,
      setSurfaceFinishColorOptions,
      setThreeDMaterialOptions,
    },
  ];
};
