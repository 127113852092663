import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import clsx from 'clsx';

import { Card, CardActionArea, CardContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { getDaysElapsed } from '../../utils/dateTimeUtils';

import DaysElapsed from '../labels/DaysElapsed';
import { colors } from '../../palette';
import DisplayItemName from './DisplayItemName';
import DisplayItemTech from './DisplayItemTech';
import ProjectImageCollection from '../images/ProjectImageCollection';

const useStyles = makeStyles()((theme) => ({
  card: {
    minWidth: '21.6rem',
    maxWidth: '21.6rem',
    minHeight: '26.2rem',
    maxHeight: '26.2rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '18.8rem',
      maxWidth: '18.8rem',
      minHeight: '25.3rem',
      maxHeight: '25.3rem',
    },
  },
  quotedTextWrapper: {
    zIndex: 10,
    position: 'absolute',
    top: 30,
    right: 0,
    backgroundColor: colors.quotedLabelBlue,
    padding: '10px 20px 10px 20px',
    fontSize: 16,
    color: colors.fontWhite,
    boxShadow: `5px 4px 8px ${colors.bgGrey}`,
    fontWeight: 'bold',
    backgroundImage: colors.colorBlueGradient,
    borderRadius: '8px 0px 0px 8px',
  },
  newLabelWrapper: {
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${colors.hotRed}`,
    borderRadius: '4px',
  },
  newTextWrapper: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: colors.hotRed,
  },
  cardItemNameWrapper: {
    width: '265px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    columnGap: '2px',
  },
  cardItemContainer: {
    maxWidth: '340px',
  },
  cardDisplay: {
    display: 'flex',
  },
  projectLabel: {
    padding: '5px 0px 5px 0px',
    fontSize: '10pt',
    color: colors.fontBlackGrey,
    borderRadius: 8,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  projectJob: {
    backgroundColor: colors.projectJob,
    color: 'white',
  },
}));

function JobLabelDisplay(props) {
  const { classes } = useStyles();

  const { noOfComponents } = props;

  return (
    <div>
      <div className={clsx(classes.projectLabel, classes.projectJob)}>
        <span>Project Job:&nbsp;</span>
        <b>
          {noOfComponents} {noOfComponents > 1 ? 'Components' : 'Component'}
        </b>
      </div>
    </div>
  );
}

function ProjectItemDisplay(props) {
  const { classes } = useStyles();

  const { project } = props;
  const { projectID, projectType, items } = project || {};
  const item = items[0];
  const { verifiedDate } = item || {};

  const daysElapsed = getDaysElapsed(verifiedDate);

  return (
    <div className={classes.cardItemContainer}>
      <div className={classes.cardDisplay}>
        <div className={classes.cardItemNameWrapper}>
          <DisplayItemName text={`Project ${projectID}`} />
          {daysElapsed <= 3 && (
            <div className={classes.newLabelWrapper}>
              <div className={classes.newTextWrapper}>new</div>
            </div>
          )}
        </div>
      </div>
      <DisplayItemTech text={item.technology} />
      <DaysElapsed daysElapsed={daysElapsed} />
      <JobLabelDisplay
        items={items}
        projectType={projectType}
        noOfComponents={project.items.length}
      />
    </div>
  );
}

function ProjectItemDisplayCard(props) {
  const { classes } = useStyles();

  const { project } = props;
  const { items, hasQuote } = project;
  const technology = items[0].technology;

  const renderQuotedLabel = () => {
    return <div className={classes.quotedTextWrapper}>Quoted</div>;
  };

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <Link
          to={{
            pathname: `/projects/${project.projectID}/quote`,
            search: `${queryString.stringify({ technology })}`,
          }}
          state={{ project: project }}
          style={{ textDecoration: 'none' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ProjectImageCollection items={items} />
            {hasQuote && renderQuotedLabel()}
          </div>
          <CardContent>
            <ProjectItemDisplay project={project} />
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}

export default ProjectItemDisplayCard;
