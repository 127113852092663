import React from 'react';
import { colors } from '../../palette';

import { Button } from '@mui/material';

function SolidBlueButton(props) {
  const {
    btnContent,
    onBtnClick,
    component,
    to,
    size,
    disabled,
    overrideStyle = {},
  } = props;
  if (size === 'small') {
    return (
      <Button
        sx={{
          textTransform: 'uppercase',
          background: colors.solidBlue,
          borderRadius: '5px',
          fontSize: '9pt',
          letterSpacing: '0.15em',
          color: colors.fontWhite,
          padding: '10px 15px',
          textAlign: 'center',
          fontWeight: '300',
          ...overrideStyle,
        }}
        onClick={onBtnClick}
        variant='contained'
        component={component ? component : 'span'}
        to={to ? to : null}
        disabled={disabled}
      >
        {btnContent}
      </Button>
    );
  }
  return (
    <Button
      sx={{
        textTransform: 'uppercase',
        background: colors.solidBlue,
        borderRadius: '5px',
        fontSize: '11pt',
        letterSpacing: '0.15em',
        color: colors.fontWhite,
        padding: '9px 60px',
        fontWeight: '300',
        ...overrideStyle,
      }}
      onClick={onBtnClick}
      variant='contained'
      component={component ? component : 'span'}
      to={to ? to : null}
      disabled={disabled}
    >
      {btnContent}
    </Button>
  );
}

export default SolidBlueButton;
