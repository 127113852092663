import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { InputLabel, MenuItem, Select, TextField } from '@mui/material';

import BootstrapInput from './BootstrapInput';

import BootstrapInputSmall from './BootstrapInputSmall';

import { colors } from '../../palette';
import { THREE_D_TECHNOLOGY } from '../../constants/quotationConstants';

const useStyles = makeStyles()(() => ({
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '30%',
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
}));

function ThreeDPrintingTechnologyInputField(props) {
  const { classes } = useStyles();

  const {
    visible = false,
    threeDTechnologyOptions = [],
    onChange,
    value,
    error,
    rowDisplay = false,
    shortLabel = false,
    bootstrapStyle = true,
    required = false,
  } = props;

  if (!visible) {
    return null;
  }

  if (bootstrapStyle) {
    return (
      <div className={rowDisplay ? classes.rowDisplay : classes.container}>
        <InputLabel
          className={
            rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel
          }
        >
          {shortLabel ? '3D Technology' : '3D Printing Technology'}
        </InputLabel>
        <Select
          id='3d-technology'
          className={classes.selectField}
          input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
          value={value}
          onChange={(evt) => onChange(evt.target.value)}
          required={required}
        >
          {threeDTechnologyOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  return (
    <TextField
      name={THREE_D_TECHNOLOGY}
      select
      label='3D Printing Technology'
      variant='outlined'
      onChange={(evt) => onChange(evt.target.value)}
      error={error}
      value={value}
      margin='dense'
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      required={required}
    >
      {threeDTechnologyOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default ThreeDPrintingTechnologyInputField;
