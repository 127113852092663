import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import configStore from './configureStore';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const store = configStore();

function Root() {
  return (
    <div>
      <CookiesProvider>
        <Provider store={store.store}>
          <PersistGate loading={null} persistor={store.persistor}>
            <Router>
              <App />
              <ToastContainer />
            </Router>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    </div>
  );
}
export default Root;
