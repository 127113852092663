import React from 'react';

function ColoredWordsSentence(props) {
  const { sentence, highlightWords, color } = props;
  let words = sentence.split(' ');
  words = words.map((word) =>
    highlightWords.includes(word) ? (
      <span style={{ color: color, fontWeight: 'bold' }}>{word}</span>
    ) : (
      <span>{word}</span>
    )
  );

  return (
    <div>
      {words.map((word, index) => (
        <span key={index}>{word} </span>
      ))}
    </div>
  );
}

export default ColoredWordsSentence;
