export const ONBOARDING_STEPS = [
  'Initiation',
  'Fabrication',
  'Delivery',
  'Assessment',
  'Onboard',
];

export const ONBOARDING_STEPS_MAPPING = {
  Initiation: 0,
  Fabrication: 1,
  Delivery: 2,
  Assessment: 3,
  Onboard: 4,
};
