import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import ProjectItemQuotationForm from '../forms/ProjectItemQuotationForm';
import { colors } from '../../palette';
import KeyValueInfoDisplay from '../labels/KeyValueInfoDisplay';
import SingleImage from '../images/SingleImage';
import {
  getItemImageUrl,
  getMaterialWithColorText,
  getSurfaceFinishWithColorText,
} from '../../utils/itemUtils';
import FileDownloadListDisplay from '../labels/FileDownloadListDisplay';
import DownloadAllFilesButton from '../buttons/DownloadAllFilesButton';

// import * as formActions from '../../actions/projectBulkQuotationsForm';
// import * as cadRendererAINCActions from '../../actions/cadRendererAINC';

import { makeStyles } from 'tss-react/mui';
import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
} from '../../constants/NewPartConstants';
import { get, isEmpty } from 'lodash';
// import { useEffect } from 'react';
// import { ITEM_QUOTE_TYPE } from '../../constants/projectConstants';
import CornerRibbon from '../decorations/CornerRibbon';
// import { WatchingJob } from '../WatchingJob';
// import { WATCHING_JOB_STATUS } from '../../constants/watchingJob';
// import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../constants/configurations';
// import { getFeatureFlags } from '../../apis/configurationApi';
import { generateDesignFileNameForRfq } from '../../utils/fileUtils';

const useStyles = makeStyles()((theme) => ({
  ignoreLabel: {
    color: 'orange',
  },
  expandButton: {
    border: `1px solid ${colors.lightGray}`,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: `${colors.lightGray}`,
    },
  },
  infoImage: {
    marginTop: '5px',
    fontSize: '0.7rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      wrap: 'no-wrap',
      minWidth: 'max-content',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  show3DCad: {
    width: '5.5rem',
    padding: '6px 0',
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '0.875rem',
    width: '70%',
    textAlign: 'center',
  },
  renderImage: {
    width: 'fit-content',
  },
  renderImageHover: {
    cursor: 'pointer',
    position: 'relative',
    width: 'fit-content',
    '& img': {
      filter: 'brightness(0.8)',
    },
    '& $render3dCAD': {
      display: 'block',
    },
  },
}));

const ITEM_IMAGE_SIZE = 85;

function ProjectSingleQuoteItemDisplay(props) {
  const { classes } = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const { data: featureFlags, isSuccess: isSuccessFetchFeatureFlags } = useQuery('getAllFeatureFlags', () =>
  //   getFeatureFlags()
  // );

  const dispatch = useDispatch();

  const { item, currency } = props;

  const [expanded, setExpanded] = useState(true);
  const [showExpectLeadTime, setShowExpectLeadTime] = useState(item.deadline);

  const render3DPrintingTechnology = () => {
    return (
      <Grid item xs={12} sm={3}>
        <KeyValueInfoDisplay
          name='3D Tech'
          value={item.threeDTechnology}
          titleTooltip='3D Printing Technology'
        />
      </Grid>
    );
  };

  const render3DInfill = () => {
    return (
      <Grid item xs={12} sm={3}>
        <KeyValueInfoDisplay
          name='Infill'
          value={`${Number(item.threeDInfill * 100).toFixed(2)}%`}
          titleTooltip='3D Infill'
        />
      </Grid>
    );
  };

  const render3DLayerThickness = () => {
    return (
      <Grid item xs={12} sm={3}>
        <KeyValueInfoDisplay
          name='Layer Thickness'
          value={`${item.threeDLayerThickness}mm`}
          titleTooltip='3D Layer Thickness'
        />
      </Grid>
    );
  };

  const renderItemInfo = (item) => {
    // success response instantQuotesLogMeta
    let infoInstantLogQuoteMeta = get(
      item,
      'instantQuotesLogMeta.additionalParameters'
    );
    if (!infoInstantLogQuoteMeta) {
      // failed response instantQuotesLogMeta
      infoInstantLogQuoteMeta = get(item, 'instantQuotesLogMeta') || {};
    }
    const {
      boundingBoxX,
      boundingBoxY,
      boundingBoxZ,
      volume: volumeInfo,
      surfaceArea: surfaceAreaInfo,
    } = infoInstantLogQuoteMeta;
    let sizeInfo = null;
    if (boundingBoxX && boundingBoxY && boundingBoxZ) {
      sizeInfo = `${boundingBoxX.toFixed(2)}mm x ${boundingBoxY.toFixed(2)}mm x ${boundingBoxZ.toFixed(2)}mm`;
    }
    // const cadFile = getCadFile(item.cadFile)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection:
              isMobile && (sizeInfo || volumeInfo || surfaceAreaInfo)
                ? 'column'
                : 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'row' : 'column',
              columnGap: isMobile ? '1rem' : '',
            }}
          >
            {
              <SingleImage
                url={getItemImageUrl(item)}
                width={ITEM_IMAGE_SIZE}
                height={ITEM_IMAGE_SIZE}
              />
            }
            {!item.ignoreItem && (
              <div className={classes.infoImage}>
                {sizeInfo && (
                  <div>
                    <strong>Size</strong>
                    <span>{sizeInfo}</span>
                  </div>
                )}
                {volumeInfo && (
                  <div>
                    <strong>Volume</strong>
                    <span>{`${volumeInfo.toFixed(2)} cm3`}</span>
                  </div>
                )}
                {surfaceAreaInfo && (
                  <div>
                    <strong>Surface Area</strong>
                    <span>{`${surfaceAreaInfo.toFixed(2)} cm2`}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            style={{
              marginLeft: '1rem',
              marginTop: 1,
              minWidth: 160,
              maxWidth: 200,
              padding: '10px 0 0 4px',
            }}
          >
            <FileDownloadListDisplay
              title='Design files'
              urlList={item.cadFile.split(',')}
              itemID={item.itemID}
            />
            <DownloadAllFilesButton
              urlList={item.cadFile.split(',').map((url) => ({
                url: url,
                fileName: generateDesignFileNameForRfq(
                  item.itemID,
                  '01.',
                  url.toString().split('.').pop()
                ),
              }))}
              folderName={`Factorem_${item.referenceName}`}
            />
          </div>
        </div>
        <Grid
          style={{
            marginLeft: '1rem',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
          container
          spacing={1}
        >
          <Grid item xs={12} sm={3}>
            <KeyValueInfoDisplay name='Quantity' value={item.quantity} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <KeyValueInfoDisplay
              name='Material'
              value={getMaterialWithColorText(item)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <KeyValueInfoDisplay
              name='Finishing'
              value={getSurfaceFinishWithColorText(item)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={
              !isMobile && showExpectLeadTime ? { maxWidth: '21.7%' } : null
            }
          >
            <KeyValueInfoDisplay
              name='Tolerance'
              value={item.tolerance}
              titleTooltip='Tightest Tolerance Required'
            />
          </Grid>
          {item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING &&
            !isEmpty(item.threeDTechnology) &&
            render3DPrintingTechnology()}
          {item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING &&
            item.threeDTechnology === THREE_D_P_FDM_TECH &&
            render3DInfill()}
          {item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING &&
            item.threeDTechnology === THREE_D_P_FDM_TECH &&
            render3DLayerThickness()}
          <Grid item xs={12}>
            <KeyValueInfoDisplay
              name='Description'
              value={item.description || 'N/A'}
              hideLongValueText
              maxDisplayText={250}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderNoQuoteLabel = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography>No Quote</Typography>
      </div>
    );
  };

  const renderExpandButton = () => {
    return (
      <IconButton
        className={classes.expandButton}
        size='small'
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <ExpandLessIcon style={{ fontSize: '1rem' }} />
        ) : (
          <ExpandMoreIcon style={{ fontSize: '1rem' }} />
        )}
      </IconButton>
    );
  };

  const renderItemQuotedChip = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Chip
          style={{
            color: 'white',
            backgroundColor: colors.quotedLabelBlue,
            backgroundImage: colors.colorBlueGradient,
          }}
          label='QUOTED'
        ></Chip>
      </div>
    );
  };
  const renderItemQuotedRibbon = () => {
    if (expanded && item.supplierQuoted) {
      const text = item.isSupplierQuoteAccepted ? 'Accepted' : 'Quoted';
      const bg = item.isSupplierQuoteAccepted ? colors.greenGradient : null;
      return <CornerRibbon text={text} bg={bg} />;
    }
    return null;
  };

  return (
    <div
      style={{
        border: `1px solid ${colors.expansionBackgroundColor}`,
        borderRadius: '3px',
        padding: '0.5em',
        marginBottom: '1.5rem',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '0.5rem',
        }}
      >
        <Typography variant='h6'>{item.itemID}</Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!expanded && item.supplierQuoted && renderItemQuotedChip()}
          {!isMobile && <Box style={{ width: '1rem' }}></Box>}
          {!isMobile && <Box style={{ width: '1rem' }}></Box>}
          {renderExpandButton()}
        </div>
      </div>
      {expanded && renderItemInfo(item)}
      {renderItemQuotedRibbon()}
      {!item.supplierQuoted &&
        (item.ignoreItem ? (
          renderNoQuoteLabel()
        ) : (
          <ProjectItemQuotationForm
            item={item}
            expanded={expanded}
            currency={currency}
          />
        ))}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    formData: state.projectBulkQuotationsForm,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ProjectSingleQuoteItemDisplay);
