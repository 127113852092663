import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Tab, Tabs, Typography } from '@mui/material';

import PropTypes from 'prop-types';

import InitiationProgress from '../containers/InitiationProgress';
import FabricationProgress from '../containers/FabricationProgress';
import AssessmentProgress from '../containers/AssessmentProgress';
import OnboardProgress from '../containers/OnboardProgress';
import PageTitle from '../components/PageTitle';

import {
  ONBOARDING_STEPS,
  ONBOARDING_STEPS_MAPPING,
} from '../constants/userConstants';
import DeliveryStep from '../containers/DeliveryStep';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SupplierProgress(props) {
  const { state } = useLocation();
  const user = state?.user;

  const [value, setValue] = useState(ONBOARDING_STEPS_MAPPING[user?.step]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const switchToAssessmentTab = () =>
    setValue(ONBOARDING_STEPS_MAPPING['Assessment']);

  if (!user) {
    return;
  }

  return (
    <Fragment>
      <PageTitle title={'Manage Supplier Progress'} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs'>
          {ONBOARDING_STEPS.map((step, idx) => (
            <Tab
              key={idx}
              label={step}
              disableRipple
              style={{ textTransform: 'none' }}
              {...a11yProps(idx)}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <InitiationProgress user={user} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FabricationProgress user={user} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DeliveryStep
          user={user}
          switchToAssessmentTab={switchToAssessmentTab}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AssessmentProgress user={user} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <OnboardProgress user={user} />
      </TabPanel>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SupplierProgress);
