import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const updateTestPartResults = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/onboarding/update-test-part-results`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data: suppliers } = await response.json();
    return suppliers;
  });
};

export const sendAssessmentCompleteEmail = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/onboarding/assessment-email`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};
