import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const verifyToken = async ({ token }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/jwt/verify-token`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ token }),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { valid } = await response.json();
    return valid;
  });
};
