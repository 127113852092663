import {
  GET_ITEMS_FAILURE,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_LOADING,
  CREATE_ITEM_FAILURE,
  CREATE_ITEM_LOADING,
  CREATE_ITEM_SUCCESS,
  GET_ITEMS_AVAILABLE_FOR_INVOICE_FAILURE,
  GET_ITEMS_AVAILABLE_FOR_INVOICE_SUCCESS,
  GET_ITEMS_AVAILABLE_FOR_INVOICE_LOADING,
} from '../actions';
import { LOGOUT_SUCCESS } from '../actions/';

const defaultState = {
  items: [],
  itemsInProgress: [],
  getItemsLoading: false,
  getItemsSuccess: false,
  getItemsFailure: false,
  createItemLoading: false,
  createItemSuccess: false,
  createItemFailure: false,
  getItemsAvailableForInvoiceRequest: false,
  getItemsAvailableForInvoiceSuccess: false,
  getItemsAvailableForInvoiceFailure: false,
};

export default function items(state = defaultState, action) {
  switch (action.type) {
    case GET_ITEMS_LOADING:
      return {
        ...state,
        getItemsLoading: true,
        getItemsSuccess: false,
        getItemsFailure: false,
      };
    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        getItemsLoading: false,
        getItemsSuccess: true,
        getItemsFailure: false,
        items: action.items,
      };
    case GET_ITEMS_FAILURE:
      return {
        ...state,
        getItemsLoading: false,
        getItemsSuccess: false,
        getItemsFailure: true,
      };
    case CREATE_ITEM_SUCCESS:
      return {
        ...state,
        createItemLoading: false,
        createItemSuccess: true,
        createItemFailure: false,
      };
    case CREATE_ITEM_LOADING:
      return {
        ...state,
        createItemLoading: true,
        createItemSuccess: false,
        createItemFailure: false,
      };
    case CREATE_ITEM_FAILURE:
      return {
        ...state,
        createItemLoading: false,
        createItemSuccess: false,
        createItemFailure: true,
      };
    case GET_ITEMS_AVAILABLE_FOR_INVOICE_LOADING:
      return {
        ...state,
        getItemsAvailableForInvoiceLoading: true,
        getItemsAvailableForInvoiceSuccess: false,
        getItemsAvailableForInvoiceFailure: false,
      };
    case GET_ITEMS_AVAILABLE_FOR_INVOICE_SUCCESS:
      return {
        ...state,
        getItemsAvailableForInvoiceLoading: false,
        getItemsAvailableForInvoiceSuccess: true,
        getItemsAvailableForInvoiceFailure: false,
        itemsAvailableForInvoice: action.itemsAvailableForInvoice,
      };
    case GET_ITEMS_AVAILABLE_FOR_INVOICE_FAILURE:
      return {
        ...state,
        getItemsAvailableForInvoiceLoading: false,
        getItemsAvailableForInvoiceSuccess: false,
        getItemsAvailableForInvoiceFailure: true,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
}
