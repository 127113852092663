import React, { useState, useRef, useEffect } from 'react';

import SingleImage from './SingleImage';
import { colors } from '../../palette';

import { Box, Typography } from '@mui/material';

export default function SingleImageWithUpload(props) {
  const {
    imageUrl = null,
    handleUpload = () => {},
    width = 250,
    height = 250,
  } = props;

  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [mouseEnter, setMouseEnter] = useState(false);

  useEffect(() => {
    setImage(null);
  }, [imageUrl]);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      handleUpload(e.target.files[0]);
    }
  };

  return (
    <Box
      sx={{
        marginTop: '6px',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          '& img': {
            filter: 'brightness(0.8)',
          },
        },
      }}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
      onClick={() => inputRef.current.click()}
    >
      {!image && <SingleImage width={width} height={height} url={imageUrl} />}
      {image && (
        <img
          width={width}
          height={height}
          src={URL.createObjectURL(image)}
          alt='Uploaded part'
          style={{
            borderRadius: 5,
            border: `1px solid ${colors.inputBorderBlue}`,
          }}
        />
      )}
      {mouseEnter && (
        <>
          <Typography
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              textAlign: 'center',
              color: 'white',
              fontSize: '1.2rem',
            }}
          >
            Upload image
          </Typography>
        </>
      )}
      <input
        ref={inputRef}
        type='file'
        onChange={(e) => handleChange(e)}
        style={{
          display: 'none',
        }}
        accept='image/*'
      />
    </Box>
  );
}
