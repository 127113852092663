import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { PageTitle } from '../components/PageTitle';

import {
  getPartnershipAgreementInfo,
  getESignFileForSupplier,
  generateEmbeddedSingingLink,
} from '../apis/onboardingApi';
import { connect } from 'react-redux';
import UploadFileItem from '../components/cards/UploadFileItem';
import {
  getPartnershipAgreementFileS3Key,
  uploadFileToS3,
} from '../services/s3Service';
import { uploadPartnershipAgreementFile } from '../apis/itemApi';
import { notifyError, notifySuccess } from '../services/notificationService';
import { Typography } from '@mui/material';
import DownloadFileItem from '../components/cards/DownloadFileItem';
import { downloadS3File } from '../utils/fileUtils';

function PartnershipAgreement(props) {
  const [partnerInviteLink, setPartnerInviteLink] = useState(null);

  const { userID } = props;

  const { data: partnershipAgreementInfo = {}, refetch } = useQuery(
    ['getPartnershipAgreementInfo', userID],
    () => getPartnershipAgreementInfo({ supplierID: userID })
  );

  const { partnershipAgreement, partnershipAgreementUploaded, itemID } =
    partnershipAgreementInfo;

  const { data: eSignPartnershipAgreementInfo = {} } = useQuery(
    ['getESignFileForSupplier', userID],
    () =>
      getESignFileForSupplier({
        supplierID: userID,
        docType: 'Partnership Agreement',
      })
  );

  if (eSignPartnershipAgreementInfo === null) {
    return (
      <div>
        <PageTitle title='Partnership Agreement' />
        <div
          style={{
            padding: '1rem',
          }}
        >
          No file is available for now
        </div>
      </div>
    );
  }

  const {
    supplierID,
    docType,
    docID,
    docNum,
    docName,
    adminEmail,
    adminFieldInviteID,
    partnerEmail,
    partnerFieldInviteID,
    templateID,
  } = eSignPartnershipAgreementInfo;

  const handleUploadPartnershipAgreement = (files) => {
    // upload file to s3 and backend
    let stringOfLinks = [];
    Promise.all(
      Array.from(files).map(async (file) => {
        const s3ObjectKey = getPartnershipAgreementFileS3Key(file, userID);
        return uploadFileToS3(file, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(' ').join('%20'));
          })
          .catch((err) => {
            alert(err);
          });
      })
    )
      .then(async () => {
        const payload = {
          itemID,
          userID,
          file: stringOfLinks[0],
        };
        await uploadPartnershipAgreementFile(payload);
        notifySuccess('File uploaded successfully!');
        refetch();
      })
      .catch((err) => {
        console.log(err);
        notifyError('Error uploading Partnership Agreement file');
      });
  };

  const handleDownloadPA = (e) => {
    console.log('downloading');
    e.stopPropagation();
    downloadS3File(partnershipAgreement);
  };

  const regeneratePartnershipAgreementSigningLink = async (
    docID,
    partnerFieldInviteID,
    currLinkExpirationDuration
  ) => {
    try {
      const params = {
        docId: `${docID}`,
        fieldInviteId: `${partnerFieldInviteID}`,
      };
      const inviteLink = await generateEmbeddedSingingLink(params);
      setPartnerInviteLink(inviteLink);
    } catch (error) {
      // Handle any errors that occur during the link generation process
      console.error('Error generating partnership agreement link:', error);
    }
  };

  if (eSignPartnershipAgreementInfo !== null) {
    // If the partnership agreement has already been generated once, form may or may not be complete
    const currLinkExpirationDuration = 45;
    regeneratePartnershipAgreementSigningLink(
      docID,
      partnerFieldInviteID,
      currLinkExpirationDuration
    );
  } else {
    setPartnerInviteLink(false);
  }

  return (
    <div>
      <PageTitle title='Partnership Agreement' />
      <div
        style={{
          padding: '1rem',
        }}
      >
        {partnerInviteLink === null && <div>Loading...</div>}
        {partnerInviteLink === false && <div>No file is available for now</div>}
        {partnerInviteLink && (
          <div>
            <div>
              You will receive a confirmation email for your onboarding to the
              Factorem Partner Job Board within 24 hours of signing the
              Partnership Agreement below.
            </div>
            <br />
            <iframe
              src={partnerInviteLink}
              width='1000'
              height='1000'
              title='Partnership Agreement'
            ></iframe>
          </div>
        )}
        {/* Left for future reference */}
        {/* {!partnershipAgreement
          && partnershipAgreementUploaded === 0
          && (
            <div>No file is available for now</div>
          )
        } */}
        {partnershipAgreementInfo.partnershipAgreement && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Typography>
              {partnershipAgreementUploaded === 0
                ? 'Please download file below, sign and reupload file again.'
                : 'Signed partnership agreement has been uploaded.'}
            </Typography>
            <DownloadFileItem
              key='download-partnership-agreement'
              text='Download Partnership Agreement'
              onClickItem={handleDownloadPA}
            />
            <UploadFileItem
              itemKey='upload-partnership-agreement'
              text='Upload Partnership Agreement'
              accept='.pdf'
              onFileUpload={(file) => {
                handleUploadPartnershipAgreement(file);
              }}
              uploadState={partnershipAgreement}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(PartnershipAgreement);
