import queryString from 'query-string';

import { BACKEND_SERVICE_URL } from '../constants';
import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

export const getAllTechnologyLevels = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/technology-levels?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
