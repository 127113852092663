import { ceil, isEmpty } from 'lodash';
import { Decimal } from 'decimal.js';

import { CURRENCY_SYMBOL_MAPPING } from '../constants/currencyConstants';

export const getCurrencySymbol = (currency, defaultCurrency = null) => {
  const currencySymbol = CURRENCY_SYMBOL_MAPPING[currency];
  if (isEmpty(currencySymbol)) {
    if (defaultCurrency == null) {
      throw new Error(`Currency Symbol for currency=${currency} not found`);
    }
    return defaultCurrency;
  }
  return currencySymbol;
};

/**
 * @param rate: exchange rate compare to SGD
 * @return
 * S$ 1.00 if currency is SGD
 * RM 1.00 if currency is MYR
 * USD$ 1.00 if currency is USD
 */
export const convertPriceToCurrency = (
  { price, currency, exchangeRate },
  defaultCurrency = null
) => {
  const currencySymbol = getCurrencySymbol(currency, defaultCurrency);
  const convertedPrice = Number(price) * Number(exchangeRate);
  const convertedPriceStr = `${currencySymbol} ${convertedPrice.toFixed(2)}`;
  return convertedPriceStr;
};

/**
 * correct total price if has quantity > 1 and fix 2 decimal points
 * @param {*} param0
 * @param {*} defaultCurrency
 * @returns
 */
export const convertPriceWithQuantityToCurrency = (
  { totalPrice, currency, quantity = 1, exchangeRate = 1 },
  defaultCurrency = null
) => {
  const currencySymbol = getCurrencySymbol(currency, defaultCurrency);
  const unitPriceDec = new Decimal(totalPrice)
    .dividedBy(new Decimal(quantity))
    .times(new Decimal(exchangeRate))
    .toNumber();
  const unitPrice = ceil(unitPriceDec, 2);
  const unitPriceStr = `${currencySymbol} ${unitPrice.toFixed(2)}`;
  const updatedTotalPrice = new Decimal(unitPrice)
    .times(new Decimal(quantity))
    .toNumber();
  const updatedTotalPriceStr = `${currencySymbol} ${updatedTotalPrice.toFixed(2)}`;
  return {
    unitPrice: unitPrice,
    unitPriceStr: unitPriceStr,
    totalPrice: updatedTotalPrice,
    totalPriceStr: updatedTotalPriceStr,
  };
};

/**
 * @param rate: exchange rate compare to SGD
 * @return S$1.00 if currency is SGD, RM 1.00 if currency is MYR
 */
export const convertPriceToCurrencyNoPrefix = ({
  price,
  currency,
  exchangeRate,
}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const convertedPrice = Number(price) * Number(exchangeRate);
  const convertedPriceStr = formatter.format(convertedPrice);
  return convertedPriceStr;
};
