import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { makeStyles } from 'tss-react/mui';

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { colors, FACTOREM_BLUE } from '../../palette';
import { HOW_YOU_HEAR_ANSWERS_MAPPING } from '../../constants/signUpConstants';
import CountryDropDown from './CountryDropDown';
import QuestionAnswerDropdown from './QuestionAnswerDropdown';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  formattedAddress,
  getContactWithCountryCode,
} from '../../utils/userUtils';
import { validatePassword } from '../../utils/passwordUtils';
import { PASSWORD_RULES } from '../../constants/passwordConstants';
import { getUserPreConfirmationInfo } from '../../apis/userApi';
import AddressAutocomplete from '../AddressAutocomplete';
import { GOOGLE_API_KEY } from '../../constants/apiConstants';
import PhoneNumberFields from './PhoneNumberFields';
import { COUNTRY_LIST } from '../../constants/countryConstants';
import ManufacturingCapabilitiesForm from './ManufacturingCapabilitiesForm';
import { isManufacturingCapabilitiesValidated } from '../../utils/technologyLevelsUtils';

const useStyles = makeStyles()((theme) => ({
  paper: {
    borderRadius: 5,
    display: 'flex',
    minWidth: '450px',
    padding: '56px 48px',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.20)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      padding: '28px 15px',
    },
  },
  title: {
    fontSize: '22pt',
    lineHeight: '1.4',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '-1pt',
    paddingBottom: '15pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16pt',
    },
  },
  inputField: {
    width: '100%',
    marginBottom: '13px',
    textTransform: 'none',
    color: colors.fontGrey,
  },
  inputLabel: {
    fontSize: 16,
    width: '100%',
    fontWeight: 600,
    color: colors.fontBlack,
  },
  inputLabelInfo: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.fontBlack,
    width: '100%',
    paddingBottom: '0.2rem',
  },
  nextButton: {
    marginTop: 30,
    fontWeight: 600,
    fontSize: '14pt',
    textTransform: 'none',
    color: colors.fontWhite,
    background: colors.buttonBlue,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: FACTOREM_BLUE,
    },
  },
  unvalidated: {
    fontWeight: '5px',
    color: colors.fontMediumGray,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10pt',
    },
  },
  validated: {
    color: colors.confirmedProjectJob,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10pt',
    },
  },
}));

export function AccountSignUpForm(props) {
  const { classes } = useStyles();

  const { defaultCountry, completeSignup, verificationCode } = props;

  const sections = [
    'Set an account password that you can easily remember',
    'Set up your Factorem account',
    'Help Factorem understand and serve you more effectively',
    'Just a bit more about you',
  ];
  const [currentSection, setCurrentSection] = useState(0);

  // variables for section 0
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRulesFailed, setPasswordRulesFailed] = useState(
    Object.keys(PASSWORD_RULES)
  );
  const [sectionZeroNextButtonEnabled, setSectionZeroNextButtonEnabled] =
    useState(false);

  // variables for section 1
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState(defaultCountry);
  const [sectionOneNextButtonEnabled, setSectionOneNextButtonEnabled] =
    useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [unitNo, setUnitNo] = useState('');

  // variables for section 2
  const [yearEstablished, setYearEstablished] = useState(null);
  const [certifications, setCertifications] = useState('');
  const [machiningCapabilities, setMachiningCapabilities] = useState('');
  const [manufacturingCapabilitiesData, setManufacturingCapabilitiesData] =
    useState([]);
  const [sectionTwoNextButtonEnabled, setSectionTwoNextButtonEnabled] =
    useState(false);

  // variables for section 3
  const [nameAndTitle, setNameAndTitle] = useState('');
  const [contact, setContact] = useState('');
  const [countryValue, setCountryValue] = useState('Singapore');
  const [contactNumber, setContactNumber] = useState('');
  const [contactValidated, setContactValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [secNameAndTitle, setSecNameAndTitle] = useState('');
  const [secEmail, setSecEmail] = useState('');
  const [questionAnswer, setQuestionAnswer] = useState({
    question: 'How did you hear about us?',
  });
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    getUserPreConfirmationInfo(verificationCode).then((res) => {
      setNameAndTitle(res.name);
      setContactNumber(res.contact);
      setEmail(res.email);
    });
  }, []);

  //to enable next button section 0
  useEffect(() => {
    const passwordValidated = isEmpty(passwordRulesFailed);

    setSectionZeroNextButtonEnabled(passwordValidated);
  }, [passwordRulesFailed]);

  //to enable next button section 1
  useEffect(() => {
    const companyNameValidated = !isEmptyValue(companyName);
    const registrationNumberValidated = !isEmptyValue(registrationNumber);
    const countryValidated = !isEmptyValue(country);
    const addressValidated = !isEmptyValue(address);

    const boolean =
      companyNameValidated &&
      registrationNumberValidated &&
      countryValidated &&
      addressValidated;

    setSectionOneNextButtonEnabled(boolean);
  }, [companyName, registrationNumber, country, address]);

  // to enable next button section 2
  useEffect(() => {
    const yearEstablishedValidated = !isEmptyValue(yearEstablished);
    const certificationsValidated = !isEmptyValue(certifications);
    const manufacturingCapabilitiesValidated =
      isManufacturingCapabilitiesValidated(manufacturingCapabilitiesData);

    const boolean =
      yearEstablishedValidated &&
      certificationsValidated &&
      manufacturingCapabilitiesValidated;

    setSectionTwoNextButtonEnabled(boolean);
  }, [yearEstablished, certifications, manufacturingCapabilitiesData]);

  // to enable next button section 3
  useEffect(() => {
    const questionAnswerValidated = !isEmptyValue(questionAnswer.answer);
    const nameAndTitleValidated = !isEmptyValue(nameAndTitle);
    const emailValidated = !isEmptyValue(email);
    const contactNumberValidated = !isEmptyValue(contactNumber);
    const boolean =
      questionAnswerValidated &&
      nameAndTitleValidated &&
      emailValidated &&
      contactNumberValidated;
    setSubmitButtonEnabled(boolean);
  }, [questionAnswer, nameAndTitle, email, contactNumber]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const handleNext = () => {
    setCurrentSection(currentSection + 1);
  };

  const handleSubmit = () => {
    const countryCode = COUNTRY_LIST[countryValue].phone;
    const userData = {
      password,
      companyName,
      website,
      registrationNumber,
      country,
      address: formattedAddress({ address, unitNo, postalCode }),
      yearEstablished,
      certifications,
      machiningCapabilities,
      nameAndTitle,
      contactNumber,
      email,
      secNameAndTitle,
      secContactNumber: getContactWithCountryCode(contact, countryCode),
      secEmail,
      questionAnswer,
      verificationCode,
      selectedManufacturingCapabilities: manufacturingCapabilitiesData.filter(
        (mc) => mc.selected
      ),
    };
    handleNext();
    completeSignup(userData);
  };

  const renderSignUpLastSection = () => {
    return (
      <React.Fragment>
        <Typography className={classes.inputLabel}>
          Your Name & Title (e.g. Anne Tan, Head of Sales)
        </Typography>
        <Typography className={classes.inputLabelInfo}>
          By providing your contact information, you agree to allow Factorem to
          contact you.
        </Typography>
        <TextField
          id='name-and-title'
          name='name-and-title'
          variant='filled'
          className={classes.inputField}
          size='small'
          value={nameAndTitle}
          onChange={(e) => setNameAndTitle(e.target.value)}
          disabled={true}
        />
        <Typography className={classes.inputLabel}>
          Your Company Email Address
        </Typography>
        <TextField
          id='email'
          name='email'
          className={classes.inputField}
          variant='filled'
          size='small'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={true}
        />
        <Typography className={classes.inputLabel}>
          Your Contact Number
        </Typography>
        <TextField
          id='contact-number'
          name='contact-number'
          className={classes.inputField}
          variant='filled'
          size='small'
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
          disabled={true}
        />
        <Typography className={classes.inputLabel}>
          Secondary POC Name & Title (Optional)
        </Typography>
        <Typography className={classes.inputLabelInfo}>
          By providing your contact information, you agree to allow Factorem to
          contact you.
        </Typography>
        <TextField
          id='secondaryPOCNameTitle'
          name='secondaryPOCNameTitle'
          variant='outlined'
          className={classes.inputField}
          size='small'
          value={secNameAndTitle}
          onChange={(e) => setSecNameAndTitle(e.target.value)}
        />
        <Typography className={classes.inputLabel}>
          Secondary POC Company Email Address (Optional)
        </Typography>
        <TextField
          id='secondaryPOCEmail'
          name='secondaryPOCEmail'
          className={classes.inputField}
          variant='outlined'
          size='small'
          value={secEmail}
          onChange={(e) => setSecEmail(e.target.value)}
        />
        <Typography className={classes.inputLabel}>
          Secondary POC Contact Number (Optional)
        </Typography>
        <PhoneNumberFields
          setCountryValue={setCountryValue}
          contact={contact}
          countryValue={countryValue}
          classes={classes}
          setContact={setContact}
          contactValidated={contactValidated}
          setContactValidated={setContactValidated}
        />
      </React.Fragment>
    );
  };

  return (
    <Paper className={classes.paper} elevation={1} sx={{ overflowY: 'auto' }}>
      <Typography className={classes.title}>
        {sections[currentSection]}
      </Typography>
      {currentSection === 0 && (
        <Grid item xs={10} style={{ width: '80%' }}>
          <Typography className={classes.inputLabel}>Password</Typography>
          <TextField
            id='password'
            name='password'
            type={showPassword ? 'text' : 'password'}
            className={classes.inputField}
            value={password}
            onChange={(e) => {
              setPasswordRulesFailed(validatePassword(e.target.value));
              setPassword(e.target.value);
            }}
            variant='outlined'
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {Object.entries(PASSWORD_RULES).map(([key, value]) => {
            return (
              <div
                key={`${key}-rules`}
                className={
                  passwordRulesFailed.includes(key)
                    ? classes.unvalidated
                    : classes.validated
                }
              >
                {value.message}
              </div>
            );
          })}
          <Box style={{ width: '2rem' }}></Box>
          <Button
            id='next'
            type='button'
            fullWidth
            variant='contained'
            className={classes.nextButton}
            onClick={() => handleNext()}
            disabled={!sectionZeroNextButtonEnabled}
          >
            Next
          </Button>
        </Grid>
      )}
      {currentSection === 1 && (
        <Grid item xs={10} style={{ width: '80% ' }}>
          <Typography className={classes.inputLabel}>Company Name</Typography>
          <TextField
            id='companyName'
            name='companyName'
            className={classes.inputField}
            value={companyName}
            onChange={({ target }) => {
              setCompanyName(target.value);
            }}
            placeholder='Company / Education Instituition / Hobbyist, etc.'
            variant='outlined'
            size='small'
          />
          <Typography className={classes.inputLabel}>
            Company Website (optional)
          </Typography>
          <TextField
            id='companyWebsite'
            name='companyWebsite'
            className={classes.inputField}
            value={website}
            onChange={({ target }) => {
              setWebsite(target.value);
            }}
            variant='outlined'
            size='small'
          />
          <Typography className={classes.inputLabel}>
            Company Registration Number
          </Typography>
          <TextField
            id='companyRegistrationNumber'
            name='companyRegistrationNumber'
            className={classes.inputField}
            value={registrationNumber}
            onChange={({ target }) => {
              setRegistrationNumber(target.value);
            }}
            variant='outlined'
            size='small'
          />
          <Typography className={classes.inputLabel}>
            Country of Registration
          </Typography>
          <CountryDropDown
            id='country'
            onSelect={handleCountryChange}
            valueSelected={country}
          />
          <Typography className={classes.inputLabel}>
            Company Address
          </Typography>
          {GOOGLE_API_KEY ? (
            <AddressAutocomplete
              className={classes.inputField}
              margin='none'
              size='small'
              label={null}
              onChange={({ postalCode, unitNo, address }) => {
                setAddress(address);
                setPostalCode(postalCode);
                setUnitNo(unitNo);
              }}
            />
          ) : (
            <TextField
              id='address'
              name='address'
              className={classes.inputField}
              value={address}
              onChange={({ target }) => setAddress(target.value)}
              variant='outlined'
              size='small'
            />
          )}
          <Box style={{ width: '2rem' }}></Box>
          <Button
            id='next'
            type='button'
            fullWidth
            variant='contained'
            className={classes.nextButton}
            onClick={() => handleNext()}
            disabled={!sectionOneNextButtonEnabled}
          >
            Next
          </Button>
        </Grid>
      )}
      {currentSection === 2 && (
        <Grid item xs={10} sx={{ width: '80%' }}>
          <FormControl fullWidth size='small'>
            <Typography className={classes.inputLabel}>
              Year of Company Establishment
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id='companyEstablishment'
                name='companyEstablishment'
                className={classes.inputField}
                value={yearEstablished}
                onChange={(year) => setYearEstablished(year)}
                animateYearScrolling
                inputVariant='outlined'
                views={['year']}
              />
            </LocalizationProvider>
            <Typography className={classes.inputLabel}>
              Certifications
            </Typography>
            <Typography className={classes.inputLabelInfo}>
              (e.g. ISO, indicate NIL for none)
            </Typography>
            <TextField
              id='certifications'
              name='ccertifications'
              variant='outlined'
              className={classes.inputField}
              size='small'
              value={certifications}
              onChange={({ target }) => {
                setCertifications(target.value);
              }}
            />
            <ManufacturingCapabilitiesForm
              handleMachiningCapabilities={setMachiningCapabilities}
              handleManufacturingData={setManufacturingCapabilitiesData}
            />
          </FormControl>
          <Button
            id='next'
            type='button'
            fullWidth
            variant='contained'
            className={classes.nextButton}
            onClick={() => handleNext()}
            disabled={!sectionTwoNextButtonEnabled}
          >
            Next
          </Button>
        </Grid>
      )}
      {currentSection === 3 && (
        <Grid item xs={10} sx={{ width: '80%' }}>
          {renderSignUpLastSection()}
          <QuestionAnswerDropdown
            id='how-did-you-hear-about-us'
            question={questionAnswer.question}
            answerOptions={HOW_YOU_HEAR_ANSWERS_MAPPING}
            updateAnswer={setQuestionAnswer}
          />
          <Box style={{ width: '2rem' }}></Box>
          <Button
            id='signup'
            type='button'
            fullWidth
            variant='contained'
            className={classes.nextButton}
            onClick={() => handleSubmit()}
            disabled={!submitButtonEnabled}
          >
            Create Account
          </Button>
        </Grid>
      )}
      {currentSection === 4 && (
        <Grid item xs={10} sx={{ width: '80%' }}>
          <Typography className={classes.title}>
            Thank you for signing up!
          </Typography>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: colors.fontBlack,
              width: '100%',
              paddingBottom: '0.2rem',
              textAlign: 'center',
            }}
          >
            We are excited for you to explore this opportunity with us! In the
            meantime, we will review your application and get back to you
            shortly.
          </Typography>
        </Grid>
      )}
    </Paper>
  );
}

function mapStateToProps(state) {
  return {};
}

function matchDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(AccountSignUpForm);
