import React from 'react';

import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  daysElapsedText: {
    fontSize: '9pt',
    marginTop: '3pt',
  },
}));

function DaysElapsed(props) {
  const { classes } = useStyles();

  const { daysElapsed } = props;

  return (
    <Box>
      <Typography
        variant='body2'
        color='textSecondary'
        className={classes.daysElapsedText}
      >
        {daysElapsed <= 0 ? (
          'Uploaded today'
        ) : daysElapsed === 1 ? (
          `Uploaded ${daysElapsed} day ago`
        ) : daysElapsed <= 30 ? (
          `Uploaded ${daysElapsed} days ago`
        ) : (
          <br />
        )}
      </Typography>
    </Box>
  );
}

export default DaysElapsed;
