import {
  GET_ORDER_LOADING,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_LOADING,
  EDIT_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  DELETE_ORDER_LOADING,
  DELETE_ORDER_SUCCESS,
} from '../actions';
import { LOGOUT_SUCCESS } from '../actions/';

const defaultState = {
  getOrderLoading: false,
  getOrderSuccess: false,
  getOrderFailure: false,
  order: [],
  deleteOrderLoading: false,
  deleteOrderSuccess: false,
  deleteOrderFailure: false,
  editOrderLoading: false,
  editOrderSuccess: false,
  editOrderFailure: false,
};

export default function order(state = defaultState, action) {
  switch (action.type) {
    case GET_ORDER_LOADING:
      return {
        ...state,
        getOrderLoading: true,
        getOrderSuccess: false,
        getOrderFailure: false,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        getOrderLoading: false,
        getOrderSuccess: true,
        getOrderFailure: false,
        order: action.order,
      };
    case GET_ORDER_FAILURE:
      return {
        ...state,
        getOrderLoading: false,
        getOrderSuccess: false,
        getOrderFailure: true,
      };
    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        editOrderLoading: false,
        editOrderSuccess: true,
        editOrderFailure: false,
      };
    case EDIT_ORDER_FAILURE:
      return {
        ...state,
        editOrderLoading: false,
        editOrderSuccess: false,
        editOrderFailure: true,
      };
    case EDIT_ORDER_LOADING:
      return {
        ...state,
        editOrderLoading: true,
        editOrderSuccess: false,
        editOrderFailure: false,
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        deleteOrderLoading: false,
        deleteOrderSuccess: true,
        deleteOrderFailure: false,
      };
    case DELETE_ORDER_FAILURE:
      return {
        ...state,
        deleteOrderLoading: false,
        deleteOrderSuccess: false,
        deleteOrderFailure: true,
      };
    case DELETE_ORDER_LOADING:
      return {
        ...state,
        deleteOrderLoading: true,
        deleteOrderSuccess: false,
        deleteOrderFailure: false,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
}
