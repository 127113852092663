import React from 'react';

import { Publish as PublishIcon } from '@mui/icons-material';

import { makeStyles } from 'tss-react/mui';

import { FACTOREM_BLUE } from '../palette';

const useStyles = makeStyles()(() => ({
  uploadBom: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: FACTOREM_BLUE,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function FilesUploadButton(props) {
  const { classes } = useStyles();

  const {
    id = 'file-upload',
    buttonText = 'Upload Files',
    handleUploadFiles,
    multiple = true,
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <input
        id={id}
        type='file'
        multiple={multiple}
        onChange={(evt) => handleUploadFiles(evt.target.files)}
        onClick={(event) => (event.target.value = null)}
        style={{
          display: 'none',
        }}
      />
      <label
        className={classes.uploadBom}
        htmlFor={id}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <PublishIcon />
        {buttonText}
      </label>
    </div>
  );
}

export default FilesUploadButton;
