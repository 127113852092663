export const TECHNOLOGIES_ARR = [
  'CNC Machining',
  '3D Printing',
  'Sheet Metal Fabrication',
];
export const CNC_MACHINING_TEST_PARTS = [
  'CNC Test Part A',
  'CNC Test Part B',
  'CNC Test Part C',
];
export const SMF_TEST_PARTS = [
  'SMF Test Part A',
  'SMF Test Part B',
  'SMF Test Part C',
];
export const THREE_D_PRINTING_TEST_PARTS = [
  '3D Test Part A',
  '3D Test Part B',
  '3D Test Part C',
];
export const CNC_FINISHING_COLOURS = [
  'Black',
  'Blue',
  'Green',
  'Red',
  'Purple',
  'Orange',
  'Yellow',
  'Gold',
];
