import { isEmpty } from 'lodash';

export const BACKEND_SERVICE_URL = process.env.REACT_APP_BACKEND_SERVICE;
if (isEmpty(BACKEND_SERVICE_URL)) {
  console.warn(`REACT_APP_BACKEND_SERVICE is not defined.`);
}

export const AWS_REGION = process.env.REACT_APP_REGION;
if (isEmpty(AWS_REGION)) {
  console.warn(`REACT_APP_REGION is not defined.`);
}

export const CADPART_DIRNAME = process.env.REACT_APP_CADPART_DIRNAME;
if (isEmpty(CADPART_DIRNAME)) {
  console.warn(`REACT_APP_CADPART_DIRNAME is not defined.`);
}

export const S3_BUCKETNAME = process.env.REACT_APP_BUCKETNAME;
if (isEmpty(S3_BUCKETNAME)) {
  console.warn(`REACT_APP_BUCKETNAME is not defined.`);
}

export const CRISP_CHAT_ID = process.env.REACT_APP_CRISP_CHAT_ID;
if (isEmpty(CRISP_CHAT_ID)) {
  console.warn(`CRISP_CHAT_ID is not defined.`);
}

export const PPE_SERVICE_URL = process.env.REACT_APP_PPE_SERVICE;
if (isEmpty(PPE_SERVICE_URL)) {
  console.warn(`REACT_APP_PPE_SERVICE is not defined.`);
}

export const REACT_APP_IMGPART_DIRNAME = process.env.REACT_APP_IMGPART_DIRNAME;
if (isEmpty(PPE_SERVICE_URL)) {
  console.warn(`REACT_APP_IMGPART_DIRNAME is not defined.`);
}

export const BE_API_KEY = process.env.REACT_APP_BE_API_KEY;
if (isEmpty(BE_API_KEY)) {
  console.warn(`REACT_APP_BE_API_KEY is not defined.`);
}

export const TWO_D_IMAGE_URLS_KEY = '2DImageUrls';

export const ROLE_TYPES = {
  ONBOARDING_SUPPLIER: 'onboarding_supplier',
  ADMIN: 'admin',
  SUPER_ADMIN: 'superadmin',
  SUPPLIER: 'supplier',
};

export const ORDER_STATUS = {
  VERIFYING: 'verifying',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  DISABLED: 'disabled',
};

export const CUSTOMER_PO_DIR_NAME = 'CustomerPoFiles';
export const ASSESSMENT_REPORT_DIR_NAME = 'AssessmentReportFiles';
export const PARTNERSHIP_AGREEMENT_DIR_NAME = 'PartnershipAgreementFiles';
export const QUOTATION_FORM_DIR_NAME = 'QuotationFormFiles';
export const S3_ORDER_READY_IMG_DIR_NAME = 'OrderReady/Images';
export const ONBOARDING_CAD_FILE_DIR_NAME = 'OnboardingMaterials/CAD';
export const ONBOARDING_TECH_DRAWING_DIR_NAME =
  'OnboardingMaterials/TechnicalDrawing';
export const ONBOARDING_UPLOADED_IMAGE_FILE_DIR_NAME =
  'OnboardingMaterials/UploadedImages';

export const PPE_DEFAULT_LEAD_TIME = '7 - 16 working days';
export const DEFAULT_LEAD_TIME = 14;
export const DEFAULT_MARKUP_LEAD_TIME = 2;

export const DEFAULT_FACTOREM_GST = 0.08; // 8%
export const DEFAULT_SUPPLIER_GST_PERCENT = 8; // 8%
export const DEFAULT_MARKUP_PERCENT = 0.1; // 10%

export const ACCESS_TOKEN_KEY = 'token';

export const DEFAULT_MYR_EXCHANGE_RATE = 3.3;
export const DEFAULT_USD_EXCHANGE_RATE = 0.75;
export const DEFAULT_AUD_EXCHANGE_RATE = 0.9;

export const CURRENCY = {
  SGD: 'SGD',
  MYR: 'MYR',
  AUD: 'AUD',
};

export const IMG_PLACE_HOLDER_URL =
  'https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder';
