export const getUserName = (name) => {
  let nameStr = name.split(',')[0];
  if (!nameStr) {
    return '';
  }
  return nameStr;
};

export const getDefaultProfilePic = (name) => {
  const formattedName = name.replace(/ /g, '+');
  return `https://ui-avatars.com/api/?name=${formattedName}&background=1272E3&color=fff&size=128`;
};

export const getContactWithCountryCode = (contact, countryCode) => {
  return `(${countryCode}) ${contact}`;
};

/**
 *
 * @param {object} props
 * @param {string} props.address
 * @param {string} props.unitNo
 * @param {string} props.postalCode
 * @example
 * <address>, No. <unit-no>, <city>, <country>. <postal-code>
 * T3 Departure Road, No. 23, New Delhi, Delhi, India. 2422
 */
export const formattedAddress = ({ address, unitNo, postalCode }) => {
  let formatted = address;
  let splitAddress = address.split(', ');
  if (unitNo) {
    splitAddress[0] = `${splitAddress[0]}, No. ${unitNo}`;
    formatted = splitAddress.join(', ');
  }
  if (postalCode) {
    formatted += `. ${postalCode}`;
  }
  return formatted;
};
