import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React from 'react';

import {
  UpdateOutlined,
  RateReviewOutlined,
  LocalShippingOutlined,
  CheckOutlined,
  PlaylistAddCheck,
  PlayCircleFilledWhiteOutlined,
  AssignmentTurnedIn,
} from '@mui/icons-material';

import {
  ORDER_READY_STATUS_MAPPING_NUMBER,
  ORDER_READY_STATUS,
} from '../constants/orderReadyConstants';

const getBubbleColor = (orderReadyStatus, bubbleStatus) => {
  const statusNumber = ORDER_READY_STATUS_MAPPING_NUMBER[orderReadyStatus];
  const bubbleStatusNumber = ORDER_READY_STATUS_MAPPING_NUMBER[bubbleStatus];
  return bubbleStatusNumber <= statusNumber
    ? { background: 'rgb(33, 150, 243)', color: '#fff' }
    : { background: 'white', border: '2px solid black' };
};

//This component is built using react-vertical-timeline-component
//Overwriting CSS properties are in index.css
function OrderReadyTimeline(props) {
  const { projectStatus } = props;

  return (
    <VerticalTimeline lineColor={'black'} layout={'1-column-left'}>
      <VerticalTimelineElement
        iconStyle={getBubbleColor(
          projectStatus,
          ORDER_READY_STATUS.NOT_STARTED
        )}
        icon={<PlayCircleFilledWhiteOutlined />}
      >
        <h3 className='vertical-timeline-element-title'>Start Order Ready</h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={getBubbleColor(projectStatus, ORDER_READY_STATUS.SUBMITTED)}
        icon={<RateReviewOutlined />}
      >
        <h3 className='vertical-timeline-element-title'>Review in Progress</h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={getBubbleColor(
          projectStatus,
          ORDER_READY_STATUS.NEED_UPDATE
        )}
        icon={<UpdateOutlined />}
      >
        <h3 className='vertical-timeline-element-title'>Updates Required</h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={getBubbleColor(projectStatus, ORDER_READY_STATUS.VERIFIED)}
        icon={<PlaylistAddCheck />}
      >
        <h3 className='vertical-timeline-element-title'>Verified</h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={getBubbleColor(
          projectStatus,
          ORDER_READY_STATUS.READY_TO_SHIP
        )}
        icon={<AssignmentTurnedIn />}
      >
        <h3 className='vertical-timeline-element-title'>Ready for Delivery</h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={getBubbleColor(projectStatus, ORDER_READY_STATUS.IN_TRANSIT)}
        icon={<LocalShippingOutlined />}
      >
        <h3 className='vertical-timeline-element-title'>In Transit</h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={getBubbleColor(projectStatus, ORDER_READY_STATUS.DELIVERED)}
        icon={<CheckOutlined />}
      >
        <h3 className='vertical-timeline-element-title'>Delivered</h3>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
}

export default OrderReadyTimeline;
