// Import settings
import React from 'react';
import clsx from 'clsx';

// Import color palette
import { colors } from '../palette';

// Import material UI components
import { makeStyles } from 'tss-react/mui';

// Style components
const useStyles = makeStyles()((theme) => ({
  pageTitle: {
    width: '100%',
    fontWeight: 600,
    fontSize: '2.125rem',
    lineHeight: '2rem',
    textAlign: 'center',
    color: colors.titleBlue,
    padding: '1.5625rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0.375rem 0',
      fontSize: '1.75rem',
    },
  },
  pageTitleIconEnd: {
    width: '100%',
    fontWeight: 600,
    fontSize: '2.125rem',
    lineHeight: '2rem',
    textAlign: 'center',
    color: colors.titleBlue,
    padding: '1.5625rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallText: {
    fontSize: '1.5rem',
  },
}));

function Title(props) {
  const { classes } = useStyles();
  const { contentTitle, iconEnd, size } = props;

  if (iconEnd) {
    return (
      <div className={classes.pageTitleIconEnd}>
        {contentTitle}
        {iconEnd}
      </div>
    );
  }
  return (
    <div
      className={clsx(
        classes.pageTitle,
        size === 'small' ? classes.smallText : null
      )}
    >
      {contentTitle}
    </div>
  );
}

export default Title;
