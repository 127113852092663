export const countDecimalsUtil = (value) => {
  let text = value.toString();
  // verify if number 0.000005 is represented as "5e-6"
  if (text.indexOf('e-') > -1) {
    // eslint-disable-next-line no-unused-vars
    let [base, trail] = text.split('e-');
    let deg = parseInt(trail, 10);
    return deg;
  }
  // count decimals for number in representation like "0.123456"
  if (Math.floor(value) !== value) {
    const valueDec = value.toString().split('.')[1];
    return valueDec ? valueDec.length : 0;
  }
  return 0;
};

/**
 *
 * @param {number} n
 * @returns ex: "1234" - Four digit value
 */
export const fourDigitUtil = (n) => {
  let s = '000' + n;
  return s.substring(s.length - 4);
};

/**
 *
 * @param {Number|String} value number
 * @param {Boolean} isToFixed2 when count Decimals < 2, default true
 * @returns
 * - "0.12345678" -> "0.12345"
 * - "0.1" -> "0.10"
 */
export const handleDigitDecimalUtil = (value, isToFixed2 = true) => {
  if (isToFixed2 === true && countDecimalsUtil(value) < 2) {
    return Number(value).toFixed(2);
  }
  if (countDecimalsUtil(value) >= 5) {
    return Number(value).toFixed(5);
  }
  return value;
};

/**
 * eg: num=0.999998765, places =2
 * return 0.99
 * @param {*} num
 * @param {*} places
 * @returns
 */
export const truncateDecimals = (num, places = 2) => {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
};
