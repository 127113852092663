import React, { useEffect, useState, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  Grid,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { Send as SendIcon, Close as CloseIcon } from '@mui/icons-material';

import * as formActions from '../actions/projectBulkQuotationsForm';

import {
  getRFQOnboardingItemsGroupByProjectID,
  uploadQuotationFormFile,
  getQuotationFormFile,
} from '../apis/itemApi';

import ProjectBulkQuotationsForm from '../components/forms/ProjectBulkQuotationsForm';
import ErrorLabel from '../components/labels/ErrorLabel';
import GreenButton from '../components/buttons/GreenButton';
import ItalicNote from '../components/texts/ItalicNote';
import QcReportsDisplay from '../components/info/QcReportsDisplay';
import SupplierSubmitQuotationPopup from '../components/popups/SupplierSubmitQuotationPopup.jsx';
import { QC_NO_QC_REPORT } from '../constants/projectConstants';
import UploadFileItem from '../components/cards/UploadFileItem';
import { PageTitle } from '../components/PageTitle';
import ProjectImageCollection from '../components/images/ProjectImageCollection';
import KeyValueInfoDisplay from '../components/labels/KeyValueInfoDisplay';
import DownloadAllFilesButton from '../components/buttons/DownloadAllFilesButton';
import InfoTitle from '../components/labels/InfoTitle';

import {
  getQuotationFormFileS3Key,
  uploadFileToS3,
} from '../services/s3Service';
import { notifyError, notifySuccess } from '../services/notificationService';

import { ROLE_TYPES } from '../constants';

import { colors, FACTOREM_BLUE } from '../palette';

import { useQueryParams } from '../hooks/useQueryParamsHook';

import { getLatestCollectionDate, getMaxLeadTime } from '../utils/itemUtils';
import { getDefaultExpiredDate } from '../utils/dateTimeUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { validateBulkQuotations } from '../utils/validators/formValidators';
import { generateDesignFileNameForRfq } from '../utils/fileUtils';
import { getExchangeRatesSelector } from '../selectors/exchangeRatesSelector';

const useStyles = makeStyles()(() => ({
  body: {
    padding: '1.6rem',
    paddingTop: '1.2rem',
  },
  quoteSubmitLabelWrapper: {
    margin: 20,
  },
  quoteNumberBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'white',
      color: 'green',
      border: '1px solid green',
    },
  },
}));

function ProjectQuoteForSupplier(props) {
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const {
    // match,
    userCountry,
    userID,
    // userName,
    userRole,
    currency,
    projectBulkQuotationsForm,
    // exchangeRate
  } = props;

  const params = useParams();
  const { projectID } = params;

  const exchangeRates = useSelector(getExchangeRatesSelector);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [dataLoading, setDataLoading] = useState(false);
  const [technology, setTechnology] = useState(queryParams.get('technology'));
  const [viewQuote] = useState(queryParams.get('viewQuote'));
  const [project, setProject] = useState(null);
  const [leadTime, setLeadTime] = useState(null);
  const [collectionDate, setCollectionDate] = useState(null);
  const [leadTimeError, setLeadTimeError] = useState(null);
  const [dateOfExpiry, setDateOfExpiry] = useState(getDefaultExpiredDate());
  const [partsTotal, setPartsTotal] = useState('S$ 0.00');
  const [items, setItems] = useState([]);
  const [fileUrlList, setFileUrlList] = useState([]);
  const [acknowledge, setAcknowledgement] = useState(false);
  // const [fileUploading, setFileUploading] = useState(false);
  const [qcReports, setQcReports] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null);
  const [allItemsQuoted, setAllItemsQuoted] = useState(false);
  const [acceptJobDialog, setAcceptJobDialog] = useState(false);
  const [showSummaryPanel, setShowSummaryPanel] = useState(false);
  const [quoteSelectedItems, setQuoteSelectedItems] = useState([]);
  const [quotationFormFile, setQuotationFormFile] = useState(null);

  const summaryPanelRef = useRef(null);
  const itemIDs = items.map((item) => item.itemID);

  const loadData = () => {
    const params = {
      technology,
      viewQuote,
      supplierID: userID,
    };
    setDataLoading(true);
    getRFQOnboardingItemsGroupByProjectID({ projectID, params })
      .then((projectResponse) => {
        const { project } = projectResponse;
        if (isEmpty(project.items)) {
          navigate('/');
          return;
        }
        const techs = project.items.reduce((acc, item) => {
          acc.add(item.technology);
          return acc;
        }, new Set());
        // if project has more than one technology, redirect to browse page
        if (techs.size > 1) {
          // should never happen
          navigate('/');
          return;
        }
        setProject(project);
        setItems(project.items);
        const isAllItemsQuoted = project.items.every(
          (item) => item.supplierQuoted
        );
        setAllItemsQuoted(isAllItemsQuoted);
        dispatch(formActions.initFormState(project));
      })
      .catch(() => {
        // navigate('/');
        return;
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    setTechnology(queryParams.get('technology'));
  }, [queryParams]);

  useEffect(() => {
    if (!isEmpty(project)) {
      setQcReports(project.qcReports);
    }
  }, [project]);

  useEffect(() => {
    if (exchangeRates) {
      dispatch(formActions.updateCurrency());
    }
  }, [dispatch, currency, exchangeRates]);

  useEffect(() => {
    loadData();
  }, [projectID, userID, technology]);

  useEffect(() => {
    if (projectBulkQuotationsForm && project) {
      setPartsTotal(projectBulkQuotationsForm.partsTotal);
      const { items } = projectBulkQuotationsForm;
      if (handleSummaryView().hasPPE) {
        const latestCollectionDate = getLatestCollectionDate(
          items.filter(
            (item) => item.ignoreItem !== true && !item.supplierQuoted
          )
        );
        setCollectionDate(latestCollectionDate);
        const minLeadtime = getMaxLeadTime(
          items.filter(
            (item) => item.ignoreItem !== true && !item.supplierQuoted
          )
        );
        setLeadTime(minLeadtime);
      }
    }
  }, [projectBulkQuotationsForm, project, quoteSelectedItems]);

  useEffect(() => {
    if (userID && itemIDs[0]) {
      const params = {
        userID,
        itemID: itemIDs[0],
      };
      getQuotationFormFile(params).then((file) => {
        // there should only be 1 item in file array
        setQuotationFormFile(file[0]);
      });
    }
  }, [userID]);

  // update list all project files download when items or bom files changed
  useEffect(() => {
    let allProjectDownloadFileUrlsList = [];
    if (!isEmpty(items)) {
      const itemFilesList = items.reduce((acc, item) => {
        const urlList = item.cadFile.split(',').map((url) => ({
          url,
          subFolder: `part-${item.itemID}`,
          fileName: generateDesignFileNameForRfq(
            item.itemID,
            '01.',
            url.toString().split('.').pop()
          ),
        }));
        return [...acc, ...urlList];
      }, []);
      allProjectDownloadFileUrlsList = itemFilesList;
    }
    setFileUrlList(allProjectDownloadFileUrlsList);
  }, [items]);

  useEffect(() => {
    setQuoteSelectedItems(projectBulkQuotationsForm.items);
  }, [projectBulkQuotationsForm]);

  const onSubmitQuote = () => {
    const { items } = projectBulkQuotationsForm;
    const valid = validateBulkQuotations(items, qcReports);
    if (!isEmpty(valid)) {
      dispatch(formActions.formError(valid));
      notifyError('Missing required field(s)');
      return;
    }
    if (isEmptyValue(leadTime) && isEmptyValue(collectionDate)) {
      setLeadTimeError(`Required`);
      notifyError('Missing required field(s)');
      return;
    }
    if (isEmptyValue(quotationFormFile)) {
      notifyError('Missing Quotation Form File. Please upload it first.');
      return;
    }

    const supplierID = [
      ROLE_TYPES.ADMIN,
      ROLE_TYPES.SUPER_ADMIN,
      ROLE_TYPES.REVIEWER,
    ].includes(userRole)
      ? assignedTo
      : userID;
    const payload = {
      leadTime,
      dateOfExpiry,
      technology,
      supplierID,
    };
    dispatch(formActions.submitQuotes(payload))
      .then(() => {
        notifySuccess(`Quotations are submitted successfully`);
        setLeadTime(null);
        setAcknowledgement(false);
        setDateOfExpiry(getDefaultExpiredDate());
        dispatch(formActions.initFormState(project));
        setAssignedTo(userID);
        loadData();
        setAssignedTo(userID);
        setAcceptJobDialog(false);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  /**
   * @typedef {Object} HandleSummaryView
   * @property {boolean} hasPPE
   * @property {boolean} isConfirm
   */
  /**
   * To Handle Summary View for lead time editable and Confirm Button.
   * Algorithm:
   * - Filter projectBulkQuotationsForm.items to quoteSelectedItems
   * - If it has hasPPE true
   * - If it has is hasManualItem isConfirm false
   * @return {HandleSummaryView}
   */
  function handleSummaryView() {
    return {
      hasPPE: false, // hasPPE,
      isConfirm: false, // !hasManualItem,
    };
  }

  const handleQuotationForm = (file) => {
    setQuotationFormFile(file);
    let stringOfLinks = [];
    Promise.all(
      Array.from(file).map(async (f) => {
        const s3ObjectKey = getQuotationFormFileS3Key(f, userID);
        return uploadFileToS3(f, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(' ').join('%20'));
          })
          .catch((err) => {
            alert(err);
          });
      })
    )
      .then(async () => {
        const payload = {
          itemID: itemIDs[0],
          userID: userID,
          file: stringOfLinks[0],
        };
        await uploadQuotationFormFile(payload);
        notifySuccess('File uploaded successfully!');
      })
      .catch((err) => {
        console.log(err);
        notifyError('Error uploading Quotation Form. Try again later.');
      });
  };

  const renderQcReports = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginBottom: '0.3rem',
        }}
      >
        <Typography style={{ fontWeight: 'bold' }} variant='body1'>
          QC Requirement(s)
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '0.3rem',
            marginTop: '0.3rem',
          }}
        >
          <QcReportsDisplay qcReports={qcReports} />
        </div>
      </div>
    );
  };

  const renderProjectInfo = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <div>
          <ProjectImageCollection items={items} />
        </div>
        <div
          style={{
            marginLeft: '0.5rem',
            flex: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <KeyValueInfoDisplay
                name='Technology'
                value={items[0] ? items[0].technology : null}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoTitle title='Design/CAD files' />
              <DownloadAllFilesButton
                buttonText='Download All Project Files'
                urlList={fileUrlList}
                folderName={`Factorem_Project_${projectID}`}
              />
            </Grid>
            {!isEmpty(qcReports) &&
              qcReports &&
              qcReports.main !== QC_NO_QC_REPORT && (
                <Grid item xs={6}>
                  {renderQcReports()}
                </Grid>
              )}
          </Grid>
        </div>
      </div>
    );
  };

  const renderPpeCollectionDate = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Delivery Date</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ marginRight: '5px' }}>
            {collectionDate}
          </Typography>
          {/* <InfoIcon toolTipText="The project is required to be completed and delivered by this specified date." /> */}
        </div>
      </div>
    );
  };

  const renderLeadTimeInput = () => {
    return (
      <Tooltip
        title={
          userCountry === 'Singapore'
            ? 'Lead time required upon order acceptance (not including shipping)'
            : 'Lead time required upon order acceptance (including shipping)'
        }
        placement='bottom'
        arrow
      >
        <TextField
          variant='outlined'
          required
          fullWidth
          margin='dense'
          label='Lead Time'
          name='leadTime'
          type='number'
          placeholder='E.g. 7'
          value={leadTime}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setLeadTime(event.target.value)}
          onFocus={() => setLeadTimeError(null)}
          error={leadTimeError}
          helperText={leadTimeError}
          InputProps={{
            inputProps: {
              min: 0,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            },
            endAdornment: (
              <InputAdornment position='end'>working days</InputAdornment>
            ),
          }}
        />
      </Tooltip>
    );
  };

  const renderLeadTimeOrCollectionDate = () => {
    return handleSummaryView().hasPPE
      ? isEmptyValue(collectionDate)
        ? renderPpeLeadTime()
        : renderPpeCollectionDate()
      : renderLeadTimeInput();
  };
  const renderPpeLeadTime = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Lead Time</Typography>
        <Typography>{leadTime} working day(s)</Typography>
      </div>
    );
  };

  const renderSubmitQuoteButton = () => {
    return (
      <Button
        variant='contained'
        color='primary'
        fullWidth
        onClick={(e) => onSubmitQuote(e)}
        disabled={!acknowledge}
        data-cy='submit-quote-btn'
      >
        Submit Quote
      </Button>
    );
  };

  const renderQuoteSummary = () => {
    return (
      <Grid item md={12} lg={3} ref={summaryPanelRef}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            top: '2rem',
            border: `1px solid ${colors.lightGray}`,
            borderRadius: '5px',
            marginLeft: '1rem',
            marginRight: '1rem',
          }}
        >
          <Paper
            style={{
              padding: '1rem',
              marginBottom: '1rem',
              boxShadow: 'none',
            }}
          >
            <Tooltip title='Total for default quantity' placement='left'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '0.5rem',
                  alignItems: 'center',
                }}
              >
                <Typography>Parts Total</Typography>
                <Typography>
                  <Box
                    style={{
                      color: FACTOREM_BLUE,
                      fontSize: '1.5rem',
                    }}
                  >
                    <b>{partsTotal}</b>
                  </Box>
                </Typography>
              </div>
            </Tooltip>
            {renderLeadTimeOrCollectionDate()}
            <Paper
              style={{
                marginTop: '0.5rem',
                backgroundColor: colors.expansionBackgroundColor,
              }}
              variant='outlined'
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  padding: '0.5rem',
                }}
              >
                <Checkbox
                  style={{
                    height: 20,
                  }}
                  size='small'
                  checked={acknowledge}
                  onChange={() => setAcknowledgement(!acknowledge)}
                  data-cy='checkbox-summary-panel'
                />
                <Typography
                  style={{
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    padding: '0.3rem',
                  }}
                  variant='caption'
                >
                  By submitting this quote, I acknowledge that all parts
                  specifications and lead time can be met and that I will
                  fabricate the order with the best of my capability ONLY IF and
                  WHEN Factorem issues a PO to proceed with this order.
                </Typography>
              </div>
            </Paper>
          </Paper>
          <div style={{ padding: '0 1rem 1rem' }}>
            {handleSummaryView().isConfirm ? (
              <GreenButton
                tooltipTitle='Factorem System will issue PO within 24 hours if job is awarded.'
                id='accept-job-btn'
                text={'ACCEPT JOB'}
                onClick={() => setAcceptJobDialog(true)}
                disabled={!acknowledge}
              />
            ) : (
              renderSubmitQuoteButton()
            )}
            <Button
              style={{
                marginTop: '0.5rem',
              }}
              color='primary'
              fullWidth
              onClick={() => navigate(-1)}
            >
              Cancel and Go back
            </Button>
          </div>
        </div>
      </Grid>
    );
  };

  const renderSummarySlide = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 990,
          overflowY: 'visible',
          overflowX: 'scroll',
          height: '100%',
          paddingLeft: 25,
        }}
      >
        <Slide
          direction='left'
          in={showSummaryPanel}
          mountOnEnter
          unmountOnExit
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '0.5rem',
              paddingTop: '1rem',
              position: 'relative',
            }}
          >
            <Fab
              style={{
                position: 'absolute',
                top: 0,
                left: -15,
                zIndex: 1999,
              }}
              aria-label='close'
              onClick={() => setShowSummaryPanel(false)}
              size='small'
            >
              <CloseIcon />
            </Fab>
            <div style={{ width: '300px' }}>{renderQuoteSummary()}</div>
          </div>
        </Slide>
      </div>
    );
  };

  const renderCheckoutFabButton = () => {
    return (
      <Fab
        size={isMobile ? 'small' : 'medium'}
        color='primary'
        aria-label='summary'
        data-cy='checkout-btn'
        onClick={() => {
          if (isMobile) {
            summaryPanelRef.current.scrollIntoView({ behavior: 'smooth' });
          } else {
            setShowSummaryPanel(!showSummaryPanel);
          }
        }}
      >
        <Badge
          className={classes.quoteNumberBadge}
          badgeContent={quoteSelectedItems.length}
        >
          <SendIcon />
        </Badge>
      </Fab>
    );
  };

  const renderSimpleSummaryInfoRow = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 998,
          padding: '0.5rem',
          marginBottom: '0.75rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            borderRadius: '5rem',
            backgroundColor: colors.successGreen,
            margin: 'auto',
          }}
        >
          {renderCheckoutFabButton()}
          <Typography
            style={{
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            {partsTotal}
          </Typography>
        </div>
      </Grid>
    );
  };

  return (
    <div>
      <PageTitle
        title={`Project ${projectID} (${items.length} ${items.length > 1 ? 'Components' : 'Component'})`}
      />
      <div
        style={{
          padding: '1rem',
          paddingTop: 0,
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
          }}
        >
          {dataLoading && <CircularProgress />}
          {!dataLoading && (
            <Grid container>
              <Grid item xs={allItemsQuoted || isTablet ? 12 : 9}>
                {renderProjectInfo()}
                {get(projectBulkQuotationsForm, 'formError.form') && (
                  <ErrorLabel
                    message={get(projectBulkQuotationsForm, 'formError.form')}
                  />
                )}
                <ProjectBulkQuotationsForm />
                {!get(projectBulkQuotationsForm, [
                  'items',
                  0,
                  'supplierQuoted',
                ]) && (
                  <div style={{ paddingBottom: '10px' }}>
                    <ItalicNote
                      text={
                        'Please upload your company’s official quotation form for verification purposes. Do ensure that all item details are reflected clearly on the quotation form.'
                      }
                    />
                    <UploadFileItem
                      itemKey='upload-quotation-form'
                      text='Upload Quotation Form'
                      accept='.pdf'
                      onFileUpload={(file) => {
                        handleQuotationForm(file);
                      }}
                      uploadState={quotationFormFile}
                    />
                  </div>
                )}
              </Grid>
              {!allItemsQuoted && isTablet && renderSimpleSummaryInfoRow()}
              {!allItemsQuoted && !isTablet && renderQuoteSummary()}
            </Grid>
          )}
        </Grid>
      </div>
      {!allItemsQuoted && isTablet && !isMobile && renderSummarySlide()}
      {!allItemsQuoted && isMobile && renderQuoteSummary()}
      {acceptJobDialog && (
        <SupplierSubmitQuotationPopup
          dialog={acceptJobDialog}
          onClose={() => setAcceptJobDialog(false)}
          label="By clicking the 'Accept job' button, I acknowledge that my submission will be subject to a thorough review process by the Factorem Partner Ranking system. I will commence the job only upon award of an official PO issued by the system."
          onConfirmAcceptQuote={onSubmitQuote}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userCountry: state.auth.user.country,
    userID: state.auth.user.userID,
    userName: state.auth.user.name,
    userRole: state.auth.user.role,
    currency: state.auth.location.currency,
    projectBulkQuotationsForm: state.projectBulkQuotationsForm,
    exchangeRate:
      state.auth.rates && state.auth.rates[state.auth.location.currency],
  };
}

function matchDispatchToProps(dispatch, props) {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ProjectQuoteForSupplier);
