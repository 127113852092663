import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Grid, Card, Typography, CardActions, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import SolidBlueButton from '../components/buttons/SolidBlueButton';
import Subheader from '../components/Subheader';
import DownloadFileItem from '../components/cards/DownloadFileItem';
import UploadFileItem from '../components/cards/UploadFileItem';

import {
  getPartnershipAgreementInfo,
  sendOnboardCompleteEmail,
} from '../apis/onboardingApi';
import { uploadCounterSignedPartnershipAgreementFile } from '../apis/itemApi';
import { downloadS3File } from '../utils/fileUtils';
import {
  getPartnershipAgreementFileS3Key,
  uploadFileToS3,
} from '../services/s3Service';

import { notifyError, notifySuccess } from '../services/notificationService';

import {
  PAGE_KEYS,
  BUTTON_KEYS,
} from '../constants/onboardingAdminButtonsClickedConstants';
import { useButtonClicked } from '../hooks/useButtonClicked';
import { colors } from '../palette';

const OnboardProgress = (props) => {
  const { user } = props;
  const { userID } = user || {};

  const navigate = useNavigate();

  const { data: partnershipAgreementInfo = {} } = useQuery(
    ['getPartnershipAgreementInfo', userID],
    () => getPartnershipAgreementInfo({ supplierID: userID })
  );

  const {
    partnershipAgreement,
    partnershipAgreementUploaded,
    counterSignedPartnershipAgreement,
  } = partnershipAgreementInfo;

  const [counterSignedPA, setCounterSignedPA] = useState(null);

  const [buttonsClicked, addButtonsClicked] = useButtonClicked(
    user.userID,
    PAGE_KEYS.ONBOARD
  );

  useEffect(() => {
    if (counterSignedPartnershipAgreement) {
      setCounterSignedPA(counterSignedPartnershipAgreement);
    }
  }, [counterSignedPartnershipAgreement]);

  // sends last email and converts user
  // from supplier to onboarding supplier role
  const onClickSendEmail = () => {
    // Left for future reference
    // if (counterSignedPA === null) {
    //   notifyError("Missing counter signed partnership agreement");
    //   return;
    // }

    const payload = {
      toEmail: user.email,
      supplierID: user.userID,
      name: user.name,
      companyName: user.companyName,
    };
    sendOnboardCompleteEmail(payload)
      .then(() => {
        addButtonsClicked(BUTTON_KEYS.SEND_ONBOARD_EMAIL); // Button only changes green if the email was successfully
        notifySuccess('Email successfully sent!');
        setTimeout(() => navigate('/'), 1000);
      })
      .catch((err) => {
        console.log(err);
        notifyError('Failed to send email.');
      });
  };

  // Functions left for future reference
  const handleDownloadPA = (e) => {
    e.stopPropagation();
    downloadS3File(partnershipAgreement);
  };

  // Function left for future reference
  const handleCounterSignedPAUpload = (file) => {
    setCounterSignedPA(file);
    let stringOfLinks = [];
    Promise.all(
      Array.from(file).map(async (f) => {
        const s3ObjectKey = getPartnershipAgreementFileS3Key(f, userID);
        return uploadFileToS3(f, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(' ').join('%20'));
          })
          .catch((err) => {
            alert(err);
          });
      })
    )
      .then(async () => {
        const payload = {
          userID,
          file: stringOfLinks[0],
        };
        await uploadCounterSignedPartnershipAgreementFile(payload);
        notifySuccess('File uploaded successfully!');
      })
      .catch((err) => {
        console.log(err);
        notifyError('Error uploading Partnership Agreement file');
      });
  };

  return (
    <Fragment>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        spacing={1}
      >
        {/* Code block left for future reference */}
        {/* <Grid item xs={6}>
          <Card
            style={{ padding: "1rem", paddingBottom: "4rem" }}
            variant="outlined"
          >
            <Subheader title="View Signed Partnership Agreement" />
            {partnershipAgreementUploaded === 1
              ? (
                <DownloadFileItem
                  key="download-partnership-agreement"
                  text="Download Signed Partnership Agreement"
                  onClickItem={handleDownloadPA}
                />
              )
              : 'Signed Partnership Agreement has not been uploaded yet.'
            }
          </Card>
        </Grid> */}
        <Grid item xs={6}>
          <Card style={{ padding: '1rem' }} variant='outlined'>
            {/* <Subheader title="Configure Email" /> */}
            <Typography
              variant='caption'
              sx={{
                textAlign: 'right',
                fontSize: '0.8rem',
              }}
            >
              By sending this email, you will convert the user to an actual
              supplier.
            </Typography>
            <CardActions style={{ justifyContent: 'right' }}>
              <SolidBlueButton
                btnContent={'send email'}
                onBtnClick={onClickSendEmail}
                overrideStyle={
                  buttonsClicked.includes(BUTTON_KEYS.SEND_ASSESSMENT_EMAIL)
                    ? { backgroundColor: colors.successGreen }
                    : {}
                }
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OnboardProgress;
