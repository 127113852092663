import { isEmpty } from 'lodash';

export const isEmptyValue = (value) => {
  if (typeof value === 'undefined' || value == null) {
    return true;
  }
  if (typeof value === 'number' || typeof value === 'boolean') {
    return false;
  }
  return isEmpty(value);
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};
